import './defines';
import spinnerGif from '@root/img/spinner.gif';
import ColorConverter from '@sosocio/color-converter';
import type { SVGColorMatch } from '@sosocio/frontend-utils';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import ImageMaskClass from 'classes/mask';
import ColorPicker from 'components/color-picker';
import analytics from 'controllers/analytics';
import {
	ColorPickerPattern,
	ColorPickerPatterns,
	EditorBorderSelectorModel,
	EditorModulePageTemplatePositions,
	EditorModuleProductToolsSupported,
	EditorToolbarActiveMode,
	EditorToolbarLayouts,
	EditorToolbarOfferingFrameModel,
	EditorToolbarPageObjectModel,
	EditorToolbarPageObjectsModel,
	EditorToolbarPhotos,
	EditorToolbarPrintEffect,
	EditorToolbarThemes,
	EditorVectorEditColorsImageVectorColors,
	PhotoFilter,
	PhotoMask,
	TemplateSet,
} from 'interfaces/app';
import {
	PageObjectTextModel,
	ProductModel,
	ThemeModel,
} from 'interfaces/database';
import {
	PageModel,
	PageObjectColorReplacementModel,
	PageObjectColorReplacementModels,
} from 'interfaces/project';
import loadImage from 'services/load-image';
import maskPresets from 'settings/masks';
import {
	COLOR_FULL,
	OFFERING_PRINT_EFFECTS,
} from 'settings/offerings';
import {
	ConfigModule,
	FontModule,
	ProductStateModule,
	ThemeDataModule,
} from 'store';
import resizeObjectText from 'store/modules/productstate/helpers/resize-text';
import {
	logoColors as logoColorsTools,
	mobile as mobileTools,
} from 'tools';
import appendUrlParameter from 'tools/append-url-parameter';
import getColorInverse from 'tools/get-color-inverse';
import EditorBorderSelectorView from 'views/editor-border-selector';
import EditorLayerListView from 'views/editor-layer-list';
import EditorLayoutSelectorView from 'views/editor-layout-selector';
import EditorObjectSelectorView from 'views/editor-object-selector';
import EditorThemeSelectorView from 'views/editor-theme-selector';
import EditorVectorEditColorsView from 'views/editor-vector-edit-colors';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

const warningSvg = require('@root/img/warning.svg');

@Component({
	name: 'EditorToolbarView',
	components: {
		ColorPicker,
		EditorBorderSelectorView,
		EditorLayerListView,
		EditorLayoutSelectorView,
		EditorObjectSelectorView,
		EditorThemeSelectorView,
		EditorVectorEditColorsView,
	},
})
export default class EditorToolbarView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			description: 'Defines the selected photo object in which to perform the toolbar actions',
			schema: 'EditorToolbarPageObjectModel',
			type: Object,
		},
	)
	public readonly photoObject?: EditorToolbarPageObjectModel;

	@Prop({
		default: undefined,
		description: 'Defines the active mode (selected tool) of the toolbar',
		event: 'active-mode-change',
		schema: 'EditorToolbarActiveMode',
		type: String,
	})
	public readonly activeMode?: EditorToolbarActiveMode | null;

	@Prop({
		description: 'Defines the canvas element that is going to be passed to the color-picker (for the background color)',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	@Prop({
		default: false,
		description: 'Indicates if the toolbar is disabled',
		type: Boolean,
	})
	public readonly disabled?: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the active product has a frame selected',
		type: Boolean,
	})
	public readonly hasFrame!: boolean;

	@Prop({
		description: 'Indicates if there is a text object in the page',
		required: true,
		type: Boolean,
	})
	public readonly hasTextObject!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if there preview available for the product',
		type: Boolean,
	})
	public readonly hasPreview?: boolean;

	@Prop({
		description: 'Indicates if there is a photo object in the page',
		required: true,
		type: Boolean,
	})
	public readonly hasPhotoObject!: boolean;

	@Prop({
		description: 'Indicates if there are multiple objects of the same kind (more than 1 photo or more than 1 text) in the page',
		required: true,
		type: Boolean,
	})
	public readonly hasMultipleObjectsOfKind!: boolean;

	@Prop({
		description: 'Indicates if the active product still supports adding more photos to the page',
		required: true,
		type: Boolean,
	})
	public readonly isAddPhotoAvailable!: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the available layouts for the active page model',
		schema: 'EditorToolbarLayouts',
		type: Array,
	})
	public readonly layouts?: EditorToolbarLayouts;

	@Prop({
		description: 'Defines the list of objects in the active page',
		event: 'objects-change',
		required: true,
		schema: 'EditorToolbarPageObjectsModel',
		type: Array,
	})
	public readonly objects!: EditorToolbarPageObjectsModel;

	@Prop({
		default: undefined,
		description: 'Defines the offering frame model for the current offering model (used to get the visual font color when having a `printEffect`)',
		schema: 'EditorToolbarOfferingFrameModel',
		type: Object,
	})
	public readonly offeringFrameModel?: EditorToolbarOfferingFrameModel;

	@Prop({
		description: 'Defines the active page model',
		event: 'page-model-change',
		required: true,
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		default: () => ([]),
		schema: 'EditorModulePageTemplatePositions',
		type: Array,
	})
	public readonly pageTemplatePositionsAvailable!: EditorModulePageTemplatePositions;

	@Prop({
		description: 'Defines the list of photos available, needed to render the filters and shapes thumbnails (by checking against the photo id of the photo object)',
		required: true,
		schema: 'EditorToolbarPhotos',
		type: Array,
	})
	public readonly photos!: EditorToolbarPhotos;

	@Prop({
		description: 'Defines the maximum amount of colors that can be used for the photo vector',
		default: undefined,
		type: Number,
	})
	public readonly photoVectorColorLimit?: number;

	@Prop({
		acceptedValues: OFFERING_PRINT_EFFECTS,
		description: 'Defines the print effect for the current product',
		schema: 'EditorToolbarPrintEffect',
		type: String,
	})
	public readonly printEffect?: EditorToolbarPrintEffect;

	@Prop({
		default: undefined,
		description: 'Indicates the print effect color to use when drawing the objects models (only for SVGs objects)',
		type: String,
	})
	public readonly printEffectColor?: string;

	@Prop({
		default: 'photo',
		description: 'Defines the type of product the user is working on',
		type: String,
	})
	public readonly productType!: string;

	@Prop({
		description: 'Defines the current project model',
		required: true,
		schema: 'ProductModel',
		type: Object,
	})
	public readonly projectModel!: ProductModel;

	@Prop({
		default: undefined,
		description: 'Defines the project theme model',
		event: 'project-theme-change',
		schema: 'ThemeModel',
		type: Object,
	})
	public readonly projectTheme?: ThemeModel;

	@Prop({
		default: undefined,
		description: 'Defines the available themes for the current product',
		schema: 'EditorToolbarThemes',
		type: Array,
	})
	public readonly themes?: EditorToolbarThemes;

	@Prop({
		default: () => {
			const toolsSupported: EditorModuleProductToolsSupported = {
				background: true,
				border: false,
				deletePrintOrPhoto: true,
				deletePage: false,
				editColors: false,
				frame: false,
				layout: false,
				multiplePhoto: false,
				multipleText: false,
				shuffle: false,
				text: true,
				theme: false,
			};

			return toolsSupported;
		},
		description: 'Defines the tools supported by the active product',
		schema: 'EditorModuleProductToolsSupported',
		type: Object,
	})
	public readonly toolsSupported!: EditorModuleProductToolsSupported;

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get colorPickerMagnification(): number {
		const photoFound = this.photos.find((photo) => photo.id === this.internalPhotoObject?.photoid);

		if (!photoFound) {
			return Math.floor(this.pageModel.width / this.computedCanvas.clientWidth);
		}

		return Math.floor(photoFound.full_width / this.computedCanvas.clientWidth);
	}

	protected get colorPickerPatterns(): ColorPickerPatterns {
		return ThemeDataModule.getPageBackgrounds.filter((background) => background.image);
	}

	protected get isBackgroundActive(): boolean {
		return this.internalActiveMode === 'background';
	}

	protected get isBackgroundEnabled(): boolean {
		return (
			this.toolsSupported.background
			&& (
				!this.internalPhotoObject
				|| this.isFittedToSize
				|| (
					this.internalPhotoObject?.mask !== undefined
					&& this.internalPhotoObject?.mask !== null
				)
			)
		);
	}

	protected get isBorderActive(): boolean {
		return this.internalActiveMode === 'border';
	}

	protected get isCropActive(): boolean {
		return this.internalActiveMode === 'crop';
	}

	protected get isEditColorsActive(): boolean {
		return this.internalActiveMode === 'edit-colors';
	}

	protected get isEditPhotoActive(): boolean {
		return this.internalActiveMode === 'edit-photo';
	}

	protected get isEditTextActive(): boolean {
		return this.internalActiveMode === 'edit-text';
	}

	protected get isFilterActive(): boolean {
		return this.internalActiveMode === 'filter';
	}

	protected get isFittedToSize(): boolean {
		if (!this.internalPhotoObject) {
			return false;
		}

		return this.internalPhotoObject.fillMethod === 'contain';
	}

	protected get isLayersActive(): boolean {
		return this.internalActiveMode === 'layers';
	}

	protected get isLayoutActive(): boolean {
		return this.internalActiveMode === 'layout';
	}

	protected get isShapeActive(): boolean {
		return this.internalActiveMode === 'shape';
	}

	protected get isThemeActive(): boolean {
		return this.internalActiveMode === 'theme';
	}

	protected get layoutsScaling(): number {
		return this.subOptionsMaxWidth / this.pageModel.width;
	}

	protected get mainOptionStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

		if (
			!CSS.supports('animation-timeline')
			&& this.mainOptionsElement
			&& this.isMobile
			&& this.mainOptionsElement.clientWidth < this.mainOptionsElement.scrollWidth
		) {
			styles['--can-scroll'] = ' ';
		}

		return styles;
	}

	protected get normalizedPhotoVectorColors(): string[] {
		return Object.keys(this.photoVectorColors);
	}

	protected get pageModelBackground(): string | undefined {
		if (this.internalPageModel.bgpattern) {
			return this.internalPageModel.bgpattern;
		}

		return this.internalPageModel.bgcolor || undefined;
	}

	protected get pageModelTemplateSet(): TemplateSet | undefined {
		const { templateSetId } = this.pageModel;

		if (!templateSetId) {
			return undefined;
		}

		return this.layouts?.find((layout) => layout.id === templateSetId);
	}

	private get photoObjects(): EditorToolbarPageObjectsModel {
		return this.internalObjects.filter((object) => (
			object.type === 'photo'
			&& object.editable
		));
	}

	private get photoVectorColors(): EditorVectorEditColorsImageVectorColors {
		const { internalPhotoObject } = this;

		if (!internalPhotoObject) {
			return {
				foreground: [],
			};
		}

		return logoColorsTools.getColors(internalPhotoObject);
	}

	protected get selectedPhotoForEdition(): EditorToolbarPageObjectModel | undefined {
		return this.photoObjects.find((object) => !!object._selectedForEdition);
	}

	protected get selectedTextObject(): EditorToolbarPageObjectModel | undefined {
		return this.textObjects.find((object) => !!object._selected);
	}

	protected get selectedTextObjectForEdition(): EditorToolbarPageObjectModel | undefined {
		return this.textObjects.find((object) => !!object._selectedForEdition);
	}

	protected get shouldShowSubOptions(): boolean {
		return (
			this.isBackgroundActive
			|| this.isBorderActive
			|| this.isEditColorsActive
			|| this.isEditPhotoActive
			|| this.isEditTextActive
			|| this.isFilterActive
			|| this.isLayersActive
			|| this.isLayoutActive
			|| this.isShapeActive
			|| this.isThemeActive
		);
	}

	protected get subOptionsClasses(): Record<string, boolean> {
		return {
			'is-background': this.isBackgroundActive,
			'is-border': this.isBorderActive,
			'is-edit-colors': this.isEditColorsActive,
			'is-edit-photo': this.isEditPhotoActive,
			'is-edit-text': this.isEditTextActive,
			'is-layers': this.isLayersActive,
			'is-layout': this.isLayoutActive,
			'is-theme': this.isThemeActive,
		};
	}

	protected get subOptionsStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		return {
			'--sub-options-max-width': `${this.subOptionsMaxWidth}px`,
		};
	}

	private get textObjects(): EditorToolbarPageObjectsModel {
		return this.internalObjects.filter((object) => object.type === 'text');
	}

	@Ref('colorPicker')
	private colorPickerComponent?: ColorPicker;

	@Ref('mainOptions')
	private mainOptionsElement!: HTMLDivElement;

	private closeToolbar?: () => void;

	private filters: PhotoFilter[] = [
		'none',
		...ConfigModule['features.imageEffects'],
	];

	private filtersForRender: Record<PhotoFilter, string> = this.filters.reduce(
		(result, filter) => {
			result[filter] = spinnerGif;

			return result;
		},
		{} as Record<PhotoFilter, string>,
	);

	private internalActiveMode: EditorToolbarActiveMode | null = null;

	private internalObjects: EditorToolbarPageObjectsModel = [];

	private internalPhotoObject: EditorToolbarPageObjectModel | null = null;

	private internalPageModel: PageModel = {} as PageModel;

	private internalProjectTheme: ThemeModel | null = null;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private mainOptionsResizeObserver?: ResizeObserver;

	private subOptionsMaxWidth = 288;

	private shapes: PhotoMask[] = [
		'none',
		'squareLocked',
		'circle',
		'circleLocked',
		'heart',
		'diamond',
		'brush',
		'cloud',
		'star',
		'flower',
		'stamp',
		'octagon',
		'clover',
	];

	private shapesForRender: Record<PhotoMask, string> = this.shapes.reduce(
		(result, shape) => {
			result[shape] = spinnerGif;

			return result;
		},
		{} as Record<PhotoMask, string>,
	);

	protected beforeDestroy(): void {
		this.closeToolbar?.();
		this.mainOptionsResizeObserver?.disconnect();
		this.isMobileUnwatch?.();
		window.removeEventListener(
			'click',
			this.onWindowClick,
		);
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected mounted(): void {
		this.$forceCompute('mainOptionStyles');
		this.mainOptionsResizeObserver = new ResizeObserver(() => {
			this.$forceCompute('mainOptionStyles');
		});
		this.mainOptionsResizeObserver.observe(this.mainOptionsElement);
	}

	@Watch(
		'activeMode',
		{
			immediate: true,
		},
	)
	protected onActiveModeChange(): void {
		this.$nextTick(() => {
			this.internalActiveMode = this.activeMode || null;
		});
	}

	@Watch('internalActiveMode')
	protected async onInternalActiveModeChange(): Promise<void> {
		if (this.internalActiveMode) {
			if (this.internalActiveMode === 'edit-colors') {
				const { internalPhotoObject } = this;
				const vectorFound = this.photos.find((photo) => internalPhotoObject?.photoid === photo.id);
				const internalObjectFound = this.internalObjects.find((object) => object.id === internalPhotoObject?.id);

				if (
					internalPhotoObject
					&& vectorFound?.url
					&& internalObjectFound
				) {
					if (!internalPhotoObject._vectorSVG) {
						this.$openLoaderDialog();
						const svgContent = await svgUtils.getContent(vectorFound.url);
						internalPhotoObject._vectorSVG = svgContent;
						this.$emit(
							'change',
							internalPhotoObject,
						);
						const objectDiffereces: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
							[internalPhotoObject.id]: ['_vectorSVG'],
						};
						internalObjectFound._vectorSVG = svgContent;
						this.$emit(
							'objects-change',
							this.internalObjects,
							objectDiffereces,
						);
					}

					if (!internalPhotoObject._vectorColors) {
						this.$openLoaderDialog();
						const svgColors = await svgUtils.getColors(
							internalPhotoObject._vectorSVG,
							ConfigModule['logoColors.mergeSimilarThreshold'],
						);
						internalPhotoObject._vectorColors = svgColors;
						this.$emit(
							'change',
							internalPhotoObject,
						);
						const objectDiffereces: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
							[internalPhotoObject.id]: ['_vectorColors'],
						};
						internalObjectFound._vectorColors = svgColors;
						this.$emit(
							'objects-change',
							this.internalObjects,
							objectDiffereces,
						);
					}

					this.$closeLoaderDialog();
				}
			}

			if (this.isMobile) {
				if (
					this.internalActiveMode === 'edit-photo'
					|| this.internalActiveMode === 'edit-text'
				) {
					const {
						api: apiToolbar,
						close: closeToolbar,
					} = this.$openToolbar({
						body: {
							component: EditorObjectSelectorView,
							props: {
								columns: (
									this.isEditPhotoActive
										? 2
										: 1
								),
								objects: (
									this.isEditPhotoActive
										? this.photoObjects
										: this.textObjects
								),
								objectPhotoSize: (
									this.isEditPhotoActive
										? 100
										: undefined
								),
								photos: (
									this.isEditPhotoActive
										? this.photos
										: undefined
								),
								value: (
									this.isEditPhotoActive
										? (
											this.internalPhotoObject
											|| undefined
										)
										: this.selectedTextObject
								),
								theme: 'light',
							},
							listeners: {
								change: this.onSelectedObjectChange,
								'objects-change': this.onEditorObjectSelectorObjectsChange,
							},
							styles: {
								marginTop: '16px',
							},
						},
						title: (
							this.isEditPhotoActive
								? this.$t(`views.editorToolbar.select.${this.productType}`)
								: this.$t('views.editorToolbar.select.text')
						),
						listeners: {
							close: () => {
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								objectsWatcher?.();
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								selectedObjectWatcher?.();
								this.closeToolbar = undefined;
								this.internalActiveMode = null;
								this.$emit(
									'active-mode-change',
									this.internalActiveMode,
								);
							},
						},
					});
					this.closeToolbar = closeToolbar;
					let objectsWatcher: () => void;
					let selectedObjectWatcher: () => void;

					if (this.isEditPhotoActive) {
						objectsWatcher = this.$watch(
							'photoObjects',
							() => {
								const bodyComponent = apiToolbar.bodyComponent();

								if (bodyComponent) {
									bodyComponent.objects = this.photoObjects;
								}
							},
							{
								deep: true,
							},
						);
						selectedObjectWatcher = this.$watch(
							'photoObject',
							() => {
								const bodyComponent = apiToolbar.bodyComponent();

								if (bodyComponent) {
									bodyComponent.value = this.internalPhotoObject || undefined;
								}
							},
							{
								deep: true,
							},
						);
					} else {
						objectsWatcher = this.$watch(
							'textObjects',
							() => {
								const bodyComponent = apiToolbar.bodyComponent();

								if (bodyComponent) {
									bodyComponent.objects = this.textObjects;
								}
							},
							{
								deep: true,
							},
						);
						selectedObjectWatcher = this.$watch(
							'selectedTextObject',
							() => {
								const bodyComponent = apiToolbar.bodyComponent();

								if (bodyComponent) {
									bodyComponent.value = this.selectedTextObject;
								}
							},
							{
								deep: true,
							},
						);
					}
					return;
				}

				if (this.internalActiveMode === 'layout') {
					const {
						api: apiToolbar,
						close: closeToolbar,
					} = this.$openToolbar({
						body: {
							component: EditorLayoutSelectorView,
							props: {
								columns: 1,
								layouts: this.layouts,
								pageModel: this.pageModel,
								value: this.pageModelTemplateSet,
								width: undefined,
								theme: 'light',
							},
							listeners: {
								change: this.onLayoutChange,
							},
							styles: {
								marginTop: '16px',
							},
						},
						title: this.$t('views.editorToolbar.chooseLayout'),
						listeners: {
							close: () => {
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								layoutsWatcher?.();
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								pageModelWatcher?.();
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								pageModelTemplateSetWatcher?.();
								this.closeToolbar = undefined;
								this.internalActiveMode = null;
								this.$emit(
									'active-mode-change',
									this.internalActiveMode,
								);
							},
						},
					});
					this.closeToolbar = closeToolbar;
					const layoutsWatcher = this.$watch(
						'layouts',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (
								bodyComponent
								&& this.layouts
							) {
								bodyComponent.layouts = this.layouts;
							}
						},
						{
							deep: true,
						},
					);
					const pageModelWatcher = this.$watch(
						'pageModel',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (bodyComponent) {
								bodyComponent.pageModel = this.pageModel;
							}
						},
					);
					const pageModelTemplateSetWatcher = this.$watch(
						'pageModelTemplateSet',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (bodyComponent) {
								bodyComponent.value = this.pageModelTemplateSet;
							}
						},
					);
					return;
				}

				if (this.internalActiveMode === 'layers') {
					const {
						api: apiToolbar,
						close: closeToolbar,
					} = this.$openToolbar({
						body: {
							component: EditorLayerListView,
							props: {
								columns: 1,
								objects: this.internalObjects,
								objectPhotoSize: 100,
								photos: this.photos,
								theme: 'light',
							},
							listeners: {
								change: this.onEditorLayerListChange,
							},
							styles: {
								marginTop: '16px',
							},
						},
						title: this.$t('views.editorToolbar.select.layer'),
						listeners: {
							close: () => {
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								internalObjectsWatcher?.();
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								photosWatcher?.();
								this.closeToolbar = undefined;
								this.internalActiveMode = null;
								this.$emit(
									'active-mode-change',
									this.internalActiveMode,
								);
							},
						},
					});
					this.closeToolbar = closeToolbar;
					const internalObjectsWatcher = this.$watch(
						'internalObjects',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (bodyComponent) {
								bodyComponent.objects = this.internalObjects;
							}
						},
						{
							deep: true,
						},
					);
					const photosWatcher = this.$watch(
						'photos',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (bodyComponent) {
								bodyComponent.photos = this.photos;
							}
						},
					);
					return;
				}

				if (this.internalActiveMode === 'theme') {
					const {
						api: apiToolbar,
						close: closeToolbar,
					} = this.$openToolbar({
						body: {
							component: EditorThemeSelectorView,
							props: {
								columns: 2,
								projectGroupId: this.projectModel.group,
								themes: this.themes,
								themeImageSize: 100,
								value: this.projectTheme,
								theme: 'light',
							},
							listeners: {
								change: this.onThemeChange,
							},
							styles: {
								marginTop: '16px',
							},
						},
						title: this.$t('views.editorToolbar.chooseTheme'),
						listeners: {
							close: () => {
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								projectModelWatcher?.();
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								projectThemeWatcher?.();
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								themesWatcher?.();
								this.closeToolbar = undefined;
								this.internalActiveMode = null;
								this.$emit(
									'active-mode-change',
									this.internalActiveMode,
								);
							},
						},
					});
					this.closeToolbar = closeToolbar;
					const projectModelWatcher = this.$watch(
						'projectModel',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (bodyComponent) {
								bodyComponent.projectGroupId = this.projectModel.group;
							}
						},
					);
					const projectThemeWatcher = this.$watch(
						'projectTheme',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (bodyComponent) {
								bodyComponent.value = this.projectTheme;
							}
						},
					);
					const themesWatcher = this.$watch(
						'themes',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (
								bodyComponent
								&& this.themes
							) {
								bodyComponent.themes = this.themes;
							}
						},
						{
							deep: true,
						},
					);
					return;
				}

				if (this.internalActiveMode === 'edit-colors') {
					const {
						api: apiToolbar,
						close: closeToolbar,
					} = this.$openToolbar({
						body: {
							component: EditorVectorEditColorsView,
							props: {
								colorLimit: this.photoVectorColorLimit,
								value: this.photoVectorColors,
							},
							listeners: {
								change: this.onVectorEditColorsChange,
							},
							styles: {
								marginTop: '24px',
							},
						},
						isModal: false,
						listeners: {
							close: () => {
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								photoVectorColorsWatcher?.();
								this.closeToolbar = undefined;
								this.internalActiveMode = null;
								this.$emit(
									'active-mode-change',
									this.internalActiveMode,
								);
							},
						},
						toolbarStyles: {
							'--toolbar-component-background-color': 'var(--background-secondary)',
							'--toolbar-component-padding-bottom': '16px',
						},
					});
					this.closeToolbar = closeToolbar;
					const photoVectorColorsWatcher = this.$watch(
						'photoVectorColors',
						() => {
							const bodyComponent = apiToolbar.bodyComponent();

							if (bodyComponent) {
								bodyComponent.value = this.photoVectorColors;
							}
						},
						{
							deep: true,
						},
					);
					return;
				}
			}

			window.addEventListener(
				'click',
				this.onWindowClick,
			);
		} else {
			window.removeEventListener(
				'click',
				this.onWindowClick,
			);
		}
	}

	@Watch('internalPhotoObject')
	protected onInternalPhotoObjectChange(): void {
		if (
			!this.internalPhotoObject
			&& this.internalActiveMode
		) {
			this.internalActiveMode = null;
			this.$emit(
				'active-mode-change',
				this.internalActiveMode,
			);
		}
	}

	@Watch('isBackgroundEnabled')
	protected onIsBackgroundEnabledChange(): void {
		if (
			!this.isBackgroundEnabled
			&& this.isBackgroundActive
		) {
			this.internalActiveMode = null;
			this.$emit(
				'active-mode-change',
				this.internalActiveMode,
			);
		}
	}

	@Watch(
		'objects',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onObjectsChange(): void {
		this.internalObjects = this.objects.map((object) => ({
			...object,
		}));
	}

	@Watch(
		'pageModel',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onPageModelChange(): void {
		this.internalPageModel = {
			...this.pageModel,
			objectList: (
				this.pageModel.objectList
					? [...this.pageModel.objectList]
					: undefined
			),
		};
	}

	@Watch(
		'photoObject',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onPageObjectsChange(): void {
		if (this.photoObject) {
			this.internalPhotoObject = {
				...this.photoObject,
			};
		} else {
			this.internalPhotoObject = null;
		}
	}

	@Watch('projectTheme')
	protected onProjectThemeChange(): void {
		this.internalProjectTheme = this.projectTheme || null;
	}

	private addFilterImage(
		filter: PhotoFilter,
		image: string,
	): void {
		if (filter === 'none') {
			this.filtersForRender[filter] = (
				image.substring(
					0,
					4,
				) == 'http'
					? appendUrlParameter(
						image,
						'noCorsHeader',
					)
					: image
			);
		} else {
			loadImage(
				image,
				{
					effect: filter,
				},
			)
				.then(({ image: loadedImage }) => {
					this.filtersForRender[filter] = loadedImage.src;
				})
				.catch(() => {
					this.filtersForRender[filter] = warningSvg;
				});
		}
	}

	private async addTextObject(fromTemplate = false): Promise<void> {
		if (this.toolsSupported.text) {
			if (fromTemplate) {
				const templatePosition = this.pageTemplatePositionsAvailable.find((position) => position.type === 'text');

				if (templatePosition) {
					this.$emit(
						'add-text',
						templatePosition,
					);
					return;
				}
			}

			const fontModel = FontModule.getDefault;
			let maxz = 0;

			// eslint-disable-next-line no-restricted-syntax
			for (const objectModel of this.internalObjects) {
				if (objectModel.editable) {
					maxz = Math.max(
						maxz,
						objectModel.z_axis,
					);
				}
			}

			const xAxis = this.internalPageModel.width / 2;
			const yAxis = this.internalPageModel.height / 2;
			const textObjectWidth = this.internalPageModel.width / 2;
			const textObjectHeight = Math.min(
				this.internalPageModel.width / 4,
				this.internalPageModel.height / 4,
			);

			let fontcolor = getColorInverse(this.internalPageModel.bgcolor || '#FFFFFF');
			let fontcolorVisual: PageObjectTextModel['fontcolor_visual'] = null;
			const [photoObject] = this.photoObjects;

			if (
				this.photoVectorColorLimit
				&& this.photoVectorColorLimit < COLOR_FULL
			) {
				if (
					photoObject?._vectorSVG
					&& photoObject._vectorColors
				) {
					const logoColors = logoColorsTools.getColors(photoObject);
					const limitedForegroundColors = logoColors.foreground.reduce(
						(foregroundMap, color) => {
							let finalColor = color.color;

							if (color.replace) {
								finalColor = color.replace.real;
							}

							if (
								!foregroundMap.has(finalColor)
								&& finalColor !== 'transparent'
							) {
								foregroundMap.set(
									finalColor,
									{
										color: finalColor,
										matches: [finalColor],
									},
								);
							}

							return foregroundMap;
						},
						new Map<string, SVGColorMatch>(),
					);
					const vectorColorsWithPercentage = await svgUtils.limitColorsToTopN(
						photoObject._vectorSVG,
						{
							background: logoColors.background,
							foreground: limitedForegroundColors,
						},
						{
							topN: limitedForegroundColors.size,
							threshold: 0,
						},
					);
					/**
					 * Pick the more prominent color from the vector colors
					 */
					fontcolor = vectorColorsWithPercentage.foreground[0].color;
				}

				try {
					const colorConverter = new ColorConverter();
					colorConverter.hex6 = {
						value: fontcolor.slice(1),
					};
					colorConverter.pantone = {
						name: colorConverter.pantone.name,
					};
					fontcolor = `#${colorConverter.hex6.value}`;

					if (colorConverter.hex6.showAs) {
						fontcolorVisual = `#${colorConverter.hex6.showAs}`;
					}
				} catch {
					// Swallow error: no action required
				}
			}

			let textObject: OptionalExceptFor<EditorToolbarPageObjectModel, 'height' | 'type' | 'width'> = {
				fontcolor,
				fontcolor_visual: fontcolorVisual,
				fontface: fontModel.id,
				height: textObjectHeight,
				pointsize: Math.max(
					16,
					Math.min(
						200,
						Math.round(this.internalPageModel.height / 20),
					),
				),
				type: 'text',
				width: textObjectWidth,
				x_axis: xAxis - textObjectWidth / 2,
				y_axis: yAxis - textObjectHeight / 2,
				z_axis: maxz + 1,
			};
			textObject = {
				...textObject,
				...resizeObjectText(
					{
						...textObject,
						text: 'T',
						text_formatted: 'T',
						text_formatted_for_canvas: 'T',
					},
					fontModel,
					{
						resizeObject: {
							up: true,
							down: true,
						},
					},
				),
			};
			textObject = {
				...textObject,
				...resizeObjectText(
					{
						...textObject,
						text: '',
						text_formatted: '',
						text_formatted_for_canvas: '',
					},
					fontModel,
					{
						resizeObject: {
							up: true,
							down: true,
						},
					},
				),
			};

			if (this.isMobile) {
				textObject.width = this.internalPageModel.width;
				textObject.x_axis = 0;
			}

			ProductStateModule
				.addPageObject({
					data: textObject,
					noStore: true,
					pageModel: this.internalPageModel,
					pageObjects: this.internalObjects,
				})
				.then(async (textObjectAdded) => {
					if (!this.internalPageModel) {
						throw new Error('Required page model to select new text object from is missing');
					}

					let objectDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {};

					if (this.selectedTextObject) {
						objectDifferences[this.selectedTextObject.id] = ['_selected'];
						this.selectedTextObject._selected = false;
					}

					if (
						this.toolsSupported.multiplePhoto
						&& this.internalPhotoObject
					) {
						objectDifferences[this.internalPhotoObject.id] = ['_selected'];
						this.internalPhotoObject._selected = false;
					}

					if (Object.keys(objectDifferences).length > 0) {
						this.$emit(
							'objects-change',
							this.internalObjects,
							objectDifferences,
						);
					}

					this.$emit(
						'add-object',
						textObjectAdded,
					);
					objectDifferences = {};
					objectDifferences[textObjectAdded.id] = ['_selected'];
					textObjectAdded._selected = true;
					this.$emit(
						'objects-change',
						this.internalObjects,
						objectDifferences,
					);
					this.$emit(
						'page-model-change',
						this.internalPageModel,
					);

					analytics.trackEvent(
						'Add text',
						{
							category: 'Product page',
							label: 'Click',
						},
					);
					this.$emit('push-changes');
				})
				.catch(() => {
					// Swallow error: no action required
				});
		}
	}

	private addShapeImage(
		shape: PhotoMask,
		image: string,
	): void {
		if (shape === 'none') {
			this.shapesForRender[shape] = image.substring(
				0,
				4,
			) == 'http'
				? appendUrlParameter(
					image,
					'noCorsHeader',
				)
				: image;
		} else {
			loadImage(image)
				.then(({ image: loadedImage }) => ImageMaskClass(
					loadedImage,
					maskPresets[shape],
					{ correctRatio: true },
				))
				.then(([res]) => res.genImage())
				.then((genImage) => {
					this.shapesForRender[shape] = genImage.src;
				})
				.catch(() => {
					this.shapesForRender[shape] = warningSvg;
				});
		}
	}

	private editTextObject(textObjectModel: EditorToolbarPageObjectModel): void {
		const objectDiffereces: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {};

		if (this.selectedTextObject) {
			objectDiffereces[this.selectedTextObject.id] = ['_selected'];
			this.selectedTextObject._selected = false;
		}
		if (
			this.toolsSupported.multiplePhoto
			&& this.internalPhotoObject
		) {
			objectDiffereces[this.internalPhotoObject.id] = ['_selected'];
			this.internalPhotoObject._selected = false;
		}

		objectDiffereces[textObjectModel.id] = ['_selected'];
		textObjectModel._selected = true;

		if (textObjectModel._selectedForEdition) {
			objectDiffereces[textObjectModel.id].push('_selectedForEdition');
			textObjectModel._selectedForEdition = false;
		}

		this.$emit(
			'objects-change',
			this.internalObjects,
			objectDiffereces,
		);
	}

	protected isFilterSelected(filter: PhotoFilter): boolean {
		return (
			this.internalPhotoObject?.effect === filter
			|| (
				filter === 'none'
				&& this.internalPhotoObject?.effect === null
			)
		);
	}

	protected isShapeSelected(shape: PhotoMask): boolean {
		return (
			this.internalPhotoObject?.mask === shape
			|| (
				shape === 'none'
				&& this.internalPhotoObject?.mask === null
			)
		);
	}

	protected onAddEditTextClick(): void {
		if (!this.hasTextObject) {
			this.addTextObject(true);
		} else {
			this.editTextObject(this.textObjects[0]);
		}

		if (this.internalActiveMode !== null) {
			this.internalActiveMode = null;
			this.$emit(
				'active-mode-change',
				this.internalActiveMode,
			);
		}
	}

	protected onAddPhotoClick(): void {
		this.$emit('add-photo');
	}

	protected onAddTextClick(): void {
		this.addTextObject();
	}

	protected onBackgroundClick(): void {
		if (this.internalActiveMode === 'background') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'background';
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onBackgroundColorChange(
		color: string,
		type: 'color' | 'pattern',
	): void {
		if (type === 'pattern') {
			const patternFound = this.colorPickerPatterns.find((pattern) => pattern.image === color) as ColorPickerPattern;
			this.internalPageModel.bgcolor = patternFound.color;
			this.internalPageModel.bgpattern = color;
		} else {
			this.internalPageModel.bgpattern = null;
			this.internalPageModel.bgcolor = color;
		}

		this.$emit(
			'page-model-change',
			this.internalPageModel,
		);
	}

	protected onBorderChange(photoObject: EditorBorderSelectorModel): void {
		const { internalPhotoObject } = this;

		if (internalPhotoObject) {
			if (photoObject.bordercolor !== internalPhotoObject.bordercolor) {
				internalPhotoObject.bordercolor = photoObject.bordercolor;
			}
			if (photoObject.borderimage !== internalPhotoObject.borderimage) {
				internalPhotoObject.borderimage = photoObject.borderimage;
			}
			if (photoObject.borderwidth !== internalPhotoObject.borderwidth) {
				internalPhotoObject.borderwidth = photoObject.borderwidth;
			}

			this.$emit(
				'change',
				internalPhotoObject,
			);
		}
	}

	protected onBorderClick(): void {
		if (this.internalActiveMode === 'border') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'border';
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onCropClick(): void {
		if (this.internalActiveMode === 'crop') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'crop';
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onDeleteClick(): void {
		this.$emit('delete');
	}

	protected onDeletePageClick(): void {
		this.$emit('delete-page');
	}

	protected async onEditColorsClick(): Promise<void> {
		if (this.internalActiveMode === 'edit-colors') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'edit-colors';
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onEditorLayerListChange(
		objects: EditorToolbarPageObjectsModel,
		objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>>,
	): void {
		this.$emit(
			'objects-change',
			objects,
			objectsDifferences,
		);
	}

	protected onEditorObjectSelectorObjectsChange(
		objects: EditorToolbarPageObjectsModel,
		objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>>,
	): void {
		this.$emit(
			'objects-change',
			objects,
			objectsDifferences,
		);
	}

	protected onEditPhotoClick(event: MouseEvent | TouchEvent): void {
		if (
			!this.toolsSupported.multiplePhoto
			|| this.photoObjects.length === 1
			|| this.selectedPhotoForEdition
		) {
			const objectDiffereces: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {};

			if (this.selectedTextObject) {
				objectDiffereces[this.selectedTextObject.id] = ['_selected'];
				this.selectedTextObject._selected = false;
			}
			if (this.internalPhotoObject) {
				objectDiffereces[this.internalPhotoObject.id] = ['_selected'];
				this.internalPhotoObject._selected = false;
			}

			if (!this.selectedPhotoForEdition) {
				objectDiffereces[this.photoObjects[0].id] = ['_selected'];
				this.photoObjects[0]._selected = true;
			} else {
				event.preventDefault();
				objectDiffereces[this.selectedPhotoForEdition.id] = [
					'_selected',
					'_selectedForEdition',
				];
				this.selectedPhotoForEdition._selected = true;
				this.selectedPhotoForEdition._selectedForEdition = false;
			}

			this.$emit(
				'objects-change',
				this.internalObjects,
				objectDiffereces,
			);
		} else if (this.toolsSupported.multiplePhoto) {
			if (this.internalActiveMode === 'edit-photo') {
				this.internalActiveMode = null;
			} else {
				this.internalActiveMode = 'edit-photo';
			}

			this.$emit(
				'active-mode-change',
				this.internalActiveMode,
			);
		}
	}

	protected onEditTextClick(event: MouseEvent | TouchEvent): void {
		if (
			!this.toolsSupported.multipleText
			|| this.textObjects.length === 1
		) {
			this.editTextObject(this.textObjects[0]);
		} else if (this.selectedTextObjectForEdition) {
			event.preventDefault();
			this.editTextObject(this.selectedTextObjectForEdition);
		} else if (this.toolsSupported.multipleText) {
			if (this.internalActiveMode === 'edit-text') {
				this.internalActiveMode = null;
			} else {
				this.internalActiveMode = 'edit-text';
			}

			this.$emit(
				'active-mode-change',
				this.internalActiveMode,
			);
		}
	}

	protected onEyeDropperEnd(): void {
		this.$emit('eye-dropper-end');
	}

	protected onEyeDropperStart(): void {
		this.$emit('eye-dropper-start');
	}

	protected onFilterClick(): void {
		if (this.internalActiveMode === 'filter') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'filter';

			// eslint-disable-next-line no-restricted-syntax
			for (const filter of this.filters) {
				this.filtersForRender[filter] = spinnerGif;
			}

			const photoFound = this.photos.find((photo) => this.internalPhotoObject?.photoid === photo.id);

			if (photoFound?.thumb_url) {
				// eslint-disable-next-line no-restricted-syntax
				for (const filter of this.filters) {
					this.addFilterImage(
						filter,
						photoFound.thumb_url,
					);
				}
			}
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onFilterForRenderClick(filter: PhotoFilter): void {
		if (!this.internalPhotoObject) {
			return;
		}

		this.internalPhotoObject.effect = (
			filter === 'none'
				? null
				: filter
		);

		this.$emit(
			'change',
			this.internalPhotoObject,
		);
	}

	protected onFitOrFillToSizeClick(): void {
		if (!this.internalPhotoObject) {
			return;
		}

		if (this.internalPhotoObject.fillMethod === 'contain') {
			this.internalPhotoObject.fillMethod = 'cover';
		} else {
			this.internalPhotoObject.fillMethod = 'contain';
		}

		this.$emit(
			'change',
			this.internalPhotoObject,
		);
	}

	protected onAddFrameClick(): void {
		this.$emit('add-frame');
	}

	protected onLayersClick(): void {
		if (this.internalActiveMode === 'layers') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'layers';
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onLayoutClick(): void {
		if (this.internalActiveMode === 'layout') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'layout';
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onLayoutChange(layout: TemplateSet): void {
		this.$emit(
			'layout-change',
			layout,
		);
	}

	protected onPreviewClick(): void {
		this.$emit('preview');
	}

	protected onRotateClick(): void {
		if (!this.internalPhotoObject) {
			return;
		}

		this.internalPhotoObject.rotate = (
			this.internalPhotoObject.rotate == 270
				? 0
				: this.internalPhotoObject.rotate + 90
		);

		this.$emit(
			'change',
			this.internalPhotoObject,
		);
	}

	protected onSelectedObjectChange(
		object: EditorToolbarPageObjectModel,
		objectsDifferences: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>>,
	): void {
		const internalObject = this.internalObjects.find((internalObjectFound) => internalObjectFound.id === object.id) as EditorToolbarPageObjectModel;

		// eslint-disable-next-line no-restricted-syntax
		for (const objectDifference of objectsDifferences[object.id]) {
			(internalObject as any)[objectDifference] = object[objectDifference];
		}

		this.$emit(
			'objects-change',
			this.internalObjects,
			objectsDifferences,
		);
		this.$emit(
			'edit-photo',
			internalObject,
		);
		this.internalActiveMode = null;
		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
		this.closeToolbar?.();
	}

	protected onShapeClick(): void {
		if (this.internalActiveMode === 'shape') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'shape';

			// eslint-disable-next-line no-restricted-syntax
			for (const shape of this.shapes) {
				this.shapesForRender[shape] = spinnerGif;
			}

			const photoFound = this.photos.find((photo) => this.internalPhotoObject?.photoid === photo.id);

			if (photoFound?.thumb_url) {
				// eslint-disable-next-line no-restricted-syntax
				for (const shape of this.shapes) {
					this.addShapeImage(
						shape,
						photoFound.thumb_url,
					);
				}
			}
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onShapeForRenderClick(shape: PhotoMask): void {
		if (!this.internalPhotoObject) {
			return;
		}

		this.internalPhotoObject.mask = (
			shape === 'none'
				? null
				: shape
		);
		this.internalPhotoObject._mask = undefined;

		this.$emit(
			'change',
			this.internalPhotoObject,
		);
	}

	protected onShuffleClick(): void {
		this.$emit('shuffle');
	}

	protected onSubOptionsCloseClick(): void {
		this.internalActiveMode = null;

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onThemeChange(themeModel: ThemeModel): void {
		this.internalProjectTheme = themeModel;
		this.$emit(
			'project-theme-change',
			this.internalProjectTheme,
		);
	}

	protected onThemesClick(): void {
		if (this.internalActiveMode === 'theme') {
			this.internalActiveMode = null;
		} else {
			this.internalActiveMode = 'theme';
		}

		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	protected onVectorEditColorsChange(photoVectorColors: EditorVectorEditColorsImageVectorColors): void {
		const internalObjectFound = this.internalObjects.find((object) => object.id === this.internalPhotoObject?.id);
		const { internalPhotoObject } = this;

		if (
			internalPhotoObject
			&& internalObjectFound
		) {
			const objectDiffereces: Record<EditorToolbarPageObjectModel['id'], Array<keyof EditorToolbarPageObjectModel>> = {
				[internalPhotoObject.id]: ['_resetImage'],
			};
			internalObjectFound._resetImage = true;
			internalPhotoObject.colorReplacement = photoVectorColors.foreground.reduce(
				(
					colorReplacement,
					photoVectorColor,
				) => {
					if (photoVectorColor.replace) {
						colorReplacement.push({
							color: photoVectorColor.color,
							replace: photoVectorColor.replace,
						});

						if (this.textObjects.length) {
							let currentColorReplacementColor: PageObjectColorReplacementModel | undefined;

							if (internalPhotoObject.colorReplacement) {
								currentColorReplacementColor = internalPhotoObject.colorReplacement.find((colorReplacementFound) => colorReplacementFound.color === photoVectorColor.color);
							}

							const matchingTextObjectsWithSameColor = this.textObjects.filter((textObject) => {
								if (currentColorReplacementColor) {
									return (
										textObject.fontcolor === currentColorReplacementColor.color
										|| textObject.fontcolor === currentColorReplacementColor.replace?.real
									);
								}

								return textObject.fontcolor === photoVectorColor.color;
							});

							// eslint-disable-next-line no-restricted-syntax
							for (const textObject of matchingTextObjectsWithSameColor) {
								objectDiffereces[textObject.id] = ['fontcolor'];
								textObject.fontcolor = photoVectorColor.replace.real;

								if (
									textObject.fontcolor_visual
									|| photoVectorColor.replace.visual
								) {
									objectDiffereces[textObject.id].push('fontcolor_visual');
								}

								textObject.fontcolor_visual = (
									photoVectorColor.replace.visual
									?? null
								);
							}
						}
					}

					return colorReplacement;
				},
				[] as PageObjectColorReplacementModels,
			);

			if (photoVectorColors.background?.replace) {
				internalPhotoObject.colorReplacement.push({
					color: photoVectorColors.background.color,
					replace: photoVectorColors.background.replace,
				});
			}

			this.$emit(
				'change',
				internalPhotoObject,
			);
			this.$emit(
				'objects-change',
				this.internalObjects,
				objectDiffereces,
			);
		}
	}

	private onWindowClick(event: MouseEvent): void {
		if (
			!this.$el.contains(event.target as HTMLElement)
			&& this.$el !== event.target
			&& (
				!this.colorPickerComponent?.isEyeDropperActive()
				|| (
					this.colorPickerComponent.isEyeDropperActive()
					&& this.computedCanvas !== event.target
				)
			)
			&& this.internalActiveMode !== 'crop'
			&& !event.defaultPrevented
		) {
			this.internalActiveMode = null;
			this.$emit(
				'active-mode-change',
				this.internalActiveMode,
			);
		}
	}
}
