import './defines';
import transparentSVG from '@root/img/colors/transparent.svg';
import ColorConverter from '@sosocio/color-converter';
import ButtonComponent from 'components/button';
import HorizontalArrowScrollComponent from 'components/horizontal-arrow-scroll';
import InputComponent from 'components/input';
import PantoneMatchingSystemHeaderComponent from 'components/pantone-matching-system-header';
import SelectComponent from 'components/select';
import {
	PmsColorPickerColor,
	PmsColorPickerMode,
	PmsColorPickerSelectFromSource,
	PmsColorPickerSelectFromSources,
} from 'interfaces/app';
import { ConfigModule } from 'store';
import { mobile as mobileTools } from 'tools';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'PmsColorPickerComponent',
	components: {
		ButtonComponent,
		HorizontalArrowScrollComponent,
		InputComponent,
		PantoneMatchingSystemHeaderComponent,
		SelectComponent,
	},
})
export default class PmsColorPickerComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the current value of the color',
			required: true,
			type: String,
		},
	)
	public readonly value!: string;

	@Prop({
		default: () => ([]),
		description: 'Defines the list of colors to quickly select from',
		type: Array,
	})
	public readonly fromColors!: string[];

	@Prop({
		acceptedValues: [
			'full',
			'pms',
		],
		default: 'pms',
		description: 'Defines the mode of the color picker, this is used to determine if the selected color from the gradient picker is going to be converted to PMS or not',
		schema: 'PmsColorPickerMode',
		type: String,
	})
	public readonly pickerMode!: PmsColorPickerMode;

	@Prop({
		description: 'Defines the original value of the color, this is used to reset the color to the original value',
		required: true,
		type: String,
	})
	public readonly originalColor!: string;

	@Prop({
		default: 'logo',
		description: 'Defines the source of the colors to select from (just as a reference)',
		type: String,
	})
	public readonly selectFromType!: string;

	@Prop({
		default: true,
		description: 'Indicates if the color picker should show the transparent toggle button',
		type: Boolean,
	})
	public readonly transparentToggle!: boolean;

	protected get colorCanvasThumbStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};
		const thumbRect = this.colorCanvasThumbElement?.getBoundingClientRect();
		const height = thumbRect?.height;
		const width = thumbRect?.width;

		if (
			height
			&& width
			&& this.colorCanvasElement
		) {
			const left = ((this.colorConverter.hsv.s / 100) * this.colorCanvasElement.width) - (width / 2);
			const top = (((100 - this.colorConverter.hsv.v) / 100) * this.colorCanvasElement.height) - (height / 2);

			styles.left = `${left}px`;
			styles.top = `${top}px`;
		}

		return styles;
	}

	protected get colorPaletteCanvasThumbStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};
		const thumbRect = this.colorPaletteCanvasThumbElement?.getBoundingClientRect();
		const height = thumbRect?.height;
		const width = thumbRect?.width;

		if (
			this.colorPaletteCanvasThumbElement
			&& height
			&& width
		) {
			const thumbComputedStyles = getComputedStyle(this.colorPaletteCanvasThumbElement);
			const borderWidth = parseInt(
				thumbComputedStyles.borderWidth || '0',
				10,
			);
			const left = ((this.colorConverter.hsv.h / 360) * this.colorPaletteCanvasElement.width) - ((width + borderWidth) / 2);
			const top = ((this.colorPaletteCanvasElement.height - height) / 2) + borderWidth;
			styles.left = `${left}px`;
			styles.top = `${top}px`;
		}

		return styles;
	}

	protected get convertFromColorsSourceValuesInputPadding(): number[] | null {
		if (
			this.selectedFromSource === 'CMYK'
			|| this.selectedFromSource === 'RGB'
		) {
			return null;
		}

		return [0, 12, 0, 6];
	}

	protected get convertFromColorsSourceValuesInputType(): string {
		if (
			this.selectedFromSource === 'CMYK'
			|| this.selectedFromSource === 'RGB'
		) {
			return 'number';
		}

		return 'text';
	}

	protected get convertFromColorsSourceValuesInputWidth(): number | string {
		if (this.isMobile) {
			return '100%';
		}

		if (
			this.selectedFromSource === 'CMYK'
			|| this.selectedFromSource === 'RGB'
		) {
			return 31;
		}

		return 82;
	}

	protected get isColorDifferentFromOriginal(): boolean {
		let normalizedOriginalColor = this.originalColor.toLowerCase();

		if (normalizedOriginalColor.at(0) === '#') {
			normalizedOriginalColor = normalizedOriginalColor.slice(1);
		}

		return this.internalValue !== normalizedOriginalColor;
	}

	protected get isColorTransparent(): boolean {
		return this.internalValue === 'transparent';
	}

	private get isPMSMode(): boolean {
		return this.pickerMode === 'pms';
	}

	protected get selectFromColorsColorClasses(): (color: string) => Record<string, boolean> {
		return (color) => {
			let hexColor = color.toLowerCase();

			if (hexColor.at(0) !== '#') {
				hexColor = `#${hexColor}`;
			}

			let visualInternalValue = this.internalValue;

			if (this.isPMSMode) {
				visualInternalValue = (
					this.colorConverterForPantone.hex6.showAs
					?? this.colorConverterForPantone.hex6.value
				);
			}

			return {
				'pms-color-picker-component-select-from-colors-color-selected': (
					this.isMobile
					&& `#${visualInternalValue}` === hexColor
				),
			};
		};
	}

	protected get selectFromColorsColorStyles(): (color: string) => Partial<CSSStyleDeclaration> & Record<string, string> {
		return (color) => {
			const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};
			let hexColor = color.toLowerCase();

			if (hexColor.at(0) !== '#') {
				hexColor = `#${hexColor}`;
			}

			styles.backgroundColor = hexColor;
			let visualInternalValue = this.internalValue;

			if (this.isPMSMode) {
				visualInternalValue = (
					this.colorConverterForPantone.hex6.showAs
					?? this.colorConverterForPantone.hex6.value
				);
			}

			if (
				this.isMobile
				&& `#${visualInternalValue}` === hexColor
			) {
				styles['--pms-color-picker-component-select-from-colors-color-box-shadow-color'] = hexColor;
			}

			return styles;
		};
	}

	protected get selectedFromSourceValues(): PmsColorPickerColor {
		try {
			if (
				this.selectedFromSource === 'CMYK'
				&& typeof this.colorConverter.cmyk === 'object'
			) {
				const cmykKeys = Object.keys(this.colorConverter.cmyk) as Array<keyof ColorConverter['cmyk']>;

				return cmykKeys.reduce(
					(
						cmyk,
						key,
					) => {
						cmyk[key] = String(this.colorConverter.cmyk[key] ?? 0);

						return cmyk;
					},
					{} as NumbersToString<ColorConverter['cmyk']>,
				);
			}
			if (
				this.selectedFromSource === 'RGB'
				&& typeof this.colorConverter.rgb === 'object'
			) {
				const rgbKeys = Object.keys(this.colorConverter.rgb) as Array<keyof ColorConverter['rgb']>;

				return rgbKeys.reduce(
					(
						rgb,
						key,
					) => {
						if (key !== 'showAs') {
							if (!rgbKeys.includes('showAs')) {
								rgb[key] = String(this.colorConverter.rgb[key] ?? 0);
							} else if (
								this.colorConverter.rgb.showAs
								&& key in this.colorConverter.rgb.showAs
							) {
								rgb[key] = String(this.colorConverter.rgb.showAs[key]);
							}
						}

						return rgb;
					},
					{} as NumbersToString<ColorConverter['rgb']>,
				);
			}
			if (
				this.selectedFromSource === 'Hex'
				&& typeof this.colorConverter.hex6 === 'object'
			) {
				if (this.colorConverter.hex6.showAs) {
					return {
						hex: this.colorConverter.hex6.showAs,
					};
				}

				return {
					hex: this.colorConverter.hex6.value,
				};
			}
		} catch {
			// Shallow error: no action required
		}

		return {} as PmsColorPickerColor;
	}

	protected get selectedPantoneColorStyles(): Partial<CSSStyleDeclaration> {
		if (this.internalValue !== 'transparent') {
			let hexColor = 'unset';

			try {
				if (this.colorConverterForPantone.hex6.showAs) {
					hexColor = `#${this.colorConverterForPantone.hex6.showAs}`;
				} else {
					hexColor = `#${this.colorConverterForPantone.hex6.value}`;
				}
			} catch {
				// Shallow error: no action required
			}

			return {
				backgroundColor: hexColor,
			};
		}

		return {
			backgroundImage: `url("${transparentSVG}")`,
			backgroundSize: '6px',
		};
	}

	protected get showPantoneRegisteredMark(): boolean {
		return ConfigModule['features.pms'];
	}

	private colorConverter = new ColorConverter();

	private colorConverterForPantone = new ColorConverter();

	private internalValue = '';

	private isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private resizeObserver?: ResizeObserver;

	@Ref('colorCanvas')
	private colorCanvasElement?: HTMLCanvasElement;

	@Ref('colorCanvasThumb')
	private colorCanvasThumbElement!: HTMLCanvasElement;

	@Ref('colorPaletteCanvas')
	private colorPaletteCanvasElement!: HTMLCanvasElement;

	@Ref('colorPaletteCanvasThumb')
	private colorPaletteCanvasThumbElement!: HTMLCanvasElement;

	protected selectFromSources: PmsColorPickerSelectFromSources = [
		'CMYK',
		'RGB',
		'Hex',
	];

	protected selectedFromSource: PmsColorPickerSelectFromSource = 'CMYK';

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
		this.isMobileUnwatch = undefined;
		this.resizeObserver?.disconnect();
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;

			if (!this.isMobile) {
				this.onInternalValueChange();
			}
		});
	}

	protected mounted(): void {
		this.setCanvasesSize();
		this.resizeObserver = new ResizeObserver(this.setCanvasesSize);
		this.resizeObserver.observe(this.$el);
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		let normalizedValue = this.internalValue;

		if (this.value.toUpperCase() === this.value) {
			normalizedValue = normalizedValue.toUpperCase();
		}

		if (this.internalValue !== 'transparent') {
			this.drawColorCanvas();

			if (
				this.value.at(0) === '#'
				|| this.value.toLowerCase() === 'transparent'
			) {
				normalizedValue = `#${normalizedValue}`;
			}
		}

		if (!this.isMobile) {
			this.$emit(
				'change',
				normalizedValue,
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		let normalizedValue = this.value.toLowerCase();

		if (normalizedValue.at(0) === '#') {
			normalizedValue = normalizedValue.slice(1);
		}

		if (this.internalValue !== normalizedValue) {
			if (normalizedValue !== 'transparent') {
				this.colorConverter.hex6 = {
					value: normalizedValue,
				};
				this.onColorConverterChange();
			} else {
				this.internalValue = normalizedValue;
			}
		}
	}

	protected convertFromSourcesSelectOptionStyles(): Partial<CSSStyleDeclaration> {
		return {
			padding: '4px 8px',
		};
	}

	private drawColorCanvas(): void {
		if (!this.colorCanvasElement) {
			return;
		}

		const pmsColorPickerColorCanvasContext = this.colorCanvasElement.getContext('2d');

		if (pmsColorPickerColorCanvasContext) {
			pmsColorPickerColorCanvasContext.imageSmoothingEnabled = false;
			pmsColorPickerColorCanvasContext.clearRect(
				0,
				0,
				this.colorCanvasElement.width,
				this.colorCanvasElement.height,
			);
			pmsColorPickerColorCanvasContext.fillStyle = `hsl(${this.colorConverter.hsv.h}deg, 100%, 50%)`;
			pmsColorPickerColorCanvasContext.fillRect(
				0,
				0,
				this.colorCanvasElement.width,
				this.colorCanvasElement.height,
			);
			let gradient = pmsColorPickerColorCanvasContext.createLinearGradient(
				0,
				0,
				this.colorCanvasElement.width,
				0,
			);
			gradient.addColorStop(
				0,
				'rgba(255, 255, 255, 1)',
			);
			gradient.addColorStop(
				1,
				'rgba(255, 255, 255, 0)',
			);
			pmsColorPickerColorCanvasContext.fillStyle = gradient;
			pmsColorPickerColorCanvasContext.fillRect(
				0,
				0,
				this.colorCanvasElement.width,
				this.colorCanvasElement.height,
			);
			gradient = pmsColorPickerColorCanvasContext.createLinearGradient(
				0,
				0,
				0,
				this.colorCanvasElement.height,
			);
			gradient.addColorStop(
				0,
				'rgba(0, 0, 0, 0)',
			);
			gradient.addColorStop(
				1,
				'rgba(0, 0, 0, 1)',
			);
			pmsColorPickerColorCanvasContext.fillStyle = gradient;
			pmsColorPickerColorCanvasContext.fillRect(
				0,
				0,
				this.colorCanvasElement.width,
				this.colorCanvasElement.height,
			);
		}
	}

	private drawColorPaletteCanvas(): void {
		const pmsColorPickerColorPaletteCanvasContext = this.colorPaletteCanvasElement.getContext('2d');

		if (pmsColorPickerColorPaletteCanvasContext) {
			pmsColorPickerColorPaletteCanvasContext.imageSmoothingEnabled = false;
			pmsColorPickerColorPaletteCanvasContext.clearRect(
				0,
				0,
				this.colorPaletteCanvasElement.width,
				this.colorPaletteCanvasElement.height,
			);
			const gradient = pmsColorPickerColorPaletteCanvasContext.createLinearGradient(
				0,
				0,
				this.colorPaletteCanvasElement.width,
				0,
			);
			gradient.addColorStop(
				0,
				'rgb(255, 0, 0)',
			);
			gradient.addColorStop(
				1 / 6,
				'rgb(255, 255, 0)',
			);
			gradient.addColorStop(
				2 / 6,
				'rgb(0, 255, 0)',
			);
			gradient.addColorStop(
				3 / 6,
				'rgb(0, 255, 255)',
			);
			gradient.addColorStop(
				4 / 6,
				'rgb(0, 0, 255)',
			);
			gradient.addColorStop(
				5 / 6,
				'rgb(255, 0, 255)',
			);
			gradient.addColorStop(
				1,
				'rgb(255, 0, 0)',
			);
			pmsColorPickerColorPaletteCanvasContext.fillStyle = gradient;
			pmsColorPickerColorPaletteCanvasContext.fillRect(
				0,
				0,
				this.colorPaletteCanvasElement.width,
				this.colorPaletteCanvasElement.height,
			);
		}
	}

	protected onChooseColorClick(): void {
		let normalizedValue = this.internalValue;

		if (this.value.toUpperCase() === this.value) {
			normalizedValue = normalizedValue.toUpperCase();
		}
		if (
			(
				this.value.at(0) === '#'
				|| this.value.toLowerCase() === 'transparent'
			)
			&& this.internalValue !== 'transparent'
		) {
			normalizedValue = `#${normalizedValue}`;
		}

		this.$emit(
			'change',
			normalizedValue,
		);
	}

	protected onColorCanvasMouseDown(event: MouseEvent | TouchEvent): void {
		if ('preventDefault' in event) {
			event.preventDefault();
		}

		if (!this.colorCanvasElement) {
			return;
		}

		let finalEvent: MouseEvent | Touch;

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		if ('touches' in event) {
			const touchEvent = new TouchEvent(
				'touchmove',
				{
					touches: [
						new Touch({
							clientX: finalEvent.clientX,
							clientY: finalEvent.clientY,
							identifier: 0,
							target: this.colorCanvasElement,
						}),
					],
				},
			);
			this.onColorCanvasMouseMove(touchEvent);
		} else {
			this.onColorCanvasMouseMove({
				clientX: finalEvent.clientX,
				clientY: finalEvent.clientY,
			} as MouseEvent);
		}

		window.addEventListener(
			'mousemove',
			this.onColorCanvasMouseMove,
		);
		window.addEventListener(
			'touchmove',
			this.onColorCanvasMouseMove,
		);
		window.addEventListener(
			'mouseup',
			this.onColorCanvasMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onColorCanvasMouseUp,
		);
	}

	protected onColorCanvasMouseMove(event: MouseEvent | TouchEvent): void {
		if ('preventDefault' in event) {
			event.preventDefault();
		}

		if (!this.colorCanvasElement) {
			return;
		}

		let finalEvent: MouseEvent | Touch;

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		const canvasRect = this.colorCanvasElement.getBoundingClientRect();
		let canvasClientX = finalEvent.clientX - canvasRect.left;
		let canvasClientY = finalEvent.clientY - canvasRect.top;

		if (canvasClientX < 0) {
			canvasClientX = 0;
		} else if (canvasClientX > this.colorCanvasElement.width) {
			canvasClientX = this.colorCanvasElement.width;
		}

		if (canvasClientY < 0) {
			canvasClientY = 0;
		} else if (canvasClientY > this.colorCanvasElement.height) {
			canvasClientY = this.colorCanvasElement.height;
		}

		/**
		 * Calculate the saturation value based on X position
		 */
		let saturation = (canvasClientX / this.colorCanvasElement.width) * 100;

		if (saturation < 0) {
			saturation = 0;
		} else if (saturation > 100) {
			saturation = 100;
		}

		/**
		 * Calculate the value based on Y position
		 */
		let value = 100 - ((canvasClientY / this.colorCanvasElement.height) * 100);

		if (value < 0) {
			value = 0;
		} else if (value > 100) {
			value = 100;
		}

		this.colorConverter.hsv = {
			h: this.colorConverter.hsv.h,
			s: Math.round(saturation),
			v: Math.round(value),
		};
		this.onColorConverterChange();
	}

	protected onColorCanvasMouseUp(): void {
		window.removeEventListener(
			'mousemove',
			this.onColorCanvasMouseMove,
		);
		window.removeEventListener(
			'touchmove',
			this.onColorCanvasMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onColorCanvasMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onColorCanvasMouseUp,
		);
	}

	private onColorConverterChange(): void {
		if (this.isPMSMode) {
			this.colorConverterForPantone.hex6 = {
				value: this.colorConverter.hex6.value,
			};
			this.colorConverterForPantone.pantone = {
				name: this.colorConverterForPantone.pantone.name,
			};
			this.internalValue = (
				this.colorConverterForPantone.hex6.forceAs
				?? this.colorConverterForPantone.hex6.value
			);
		} else {
			this.internalValue = this.colorConverter.hex6.value;
		}
	}

	protected onColorPaletteCanvasMouseDown(event: MouseEvent | TouchEvent): void {
		if ('preventDefault' in event) {
			event.preventDefault();
		}

		let finalEvent: MouseEvent | Touch;

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		if ('touches' in event) {
			const touchEvent = new TouchEvent(
				'touchmove',
				{
					touches: [
						new Touch({
							clientX: finalEvent.clientX,
							clientY: finalEvent.clientY,
							identifier: 0,
							target: this.colorPaletteCanvasElement,
						}),
					],
				},
			);
			this.onColorPaletteCanvasMouseMove(touchEvent);
		} else {
			this.onColorPaletteCanvasMouseMove({
				clientX: finalEvent.clientX,
				clientY: finalEvent.clientY,
			} as MouseEvent);
		}

		window.addEventListener(
			'mousemove',
			this.onColorPaletteCanvasMouseMove,
		);
		window.addEventListener(
			'touchmove',
			this.onColorPaletteCanvasMouseMove,
		);
		window.addEventListener(
			'mouseup',
			this.onColorPaletteCanvasMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onColorPaletteCanvasMouseUp,
		);
	}

	protected onColorPaletteCanvasMouseMove(event: MouseEvent | TouchEvent): void {
		if ('preventDefault' in event) {
			event.preventDefault();
		}

		let finalEvent: MouseEvent | Touch;

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		const canvasRect = this.colorPaletteCanvasElement.getBoundingClientRect();
		let canvasClientX = finalEvent.clientX - canvasRect.left;

		if (canvasClientX < 0) {
			canvasClientX = 0;
		} else if (canvasClientX >= this.colorPaletteCanvasElement.width) {
			canvasClientX = this.colorPaletteCanvasElement.width;
		}

		let hue = (canvasClientX / this.colorPaletteCanvasElement.width) * 360;

		if (hue < 0) {
			hue = 0;
		} else if (hue > 360) {
			hue = 360;
		}

		this.colorConverter.hsv = {
			h: Math.round(hue),
			s: this.colorConverter.hsv.s,
			v: this.colorConverter.hsv.v,
		};
		this.onColorConverterChange();
	}

	protected onColorPaletteCanvasMouseUp(): void {
		window.removeEventListener(
			'mousemove',
			this.onColorPaletteCanvasMouseMove,
		);
		window.removeEventListener(
			'touchmove',
			this.onColorPaletteCanvasMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onColorPaletteCanvasMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onColorPaletteCanvasMouseUp,
		);
	}

	protected onColorTransparentClick(): void {
		if (this.internalValue !== 'transparent') {
			this.internalValue = 'transparent';
		} else {
			this.resetColor();
		}
	}

	protected onFromColorClick(color: string): void {
		let normalizedColor = color.toLowerCase();

		if (normalizedColor.at(0) === '#') {
			normalizedColor = normalizedColor.slice(1);
		}

		if (this.internalValue !== normalizedColor) {
			this.colorConverter.hex6 = {
				value: normalizedColor,
			};
			this.onColorConverterChange();
		}
	}

	protected onSelectedFromSourceValueInput<
		Color extends PmsColorPickerColor,
		ColorKey extends keyof Color,
	>(
		selectedFromSourceValueKey: ColorKey,
		value: string,
	): void {
		if (this.selectedFromSource === 'CMYK') {
			const typedKey = selectedFromSourceValueKey as keyof ColorConverter['cmyk'];
			this.colorConverter.cmyk = {
				...this.colorConverter.cmyk,
				[typedKey]: Number(value),
			};
		} else if (this.selectedFromSource === 'RGB') {
			const typedKey = selectedFromSourceValueKey as Exclude<keyof ColorConverter['rgb'], 'showAs'>;
			this.colorConverter.rgb = {
				...this.colorConverter.rgb,
				[typedKey]: Number(value),
			};
		} else {
			this.colorConverter.hex6 = {
				value,
			};
		}

		this.onColorConverterChange();
	}

	protected resetColor(): void {
		let normalizedOriginalColor = this.originalColor.toLowerCase();

		if (normalizedOriginalColor.at(0) === '#') {
			normalizedOriginalColor = normalizedOriginalColor.slice(1);
		}

		if (this.internalValue !== normalizedOriginalColor) {
			this.colorConverter.hex6 = {
				value: normalizedOriginalColor,
			};
			this.onColorConverterChange();
		}
	}

	private setCanvasesSize(): void {
		if (this.colorCanvasElement) {
			this.colorCanvasElement.height = this.colorCanvasElement.clientHeight;
			this.colorCanvasElement.width = this.colorCanvasElement.clientWidth;
			this.colorPaletteCanvasElement.height = this.colorPaletteCanvasElement.clientHeight;
			this.colorPaletteCanvasElement.width = this.colorPaletteCanvasElement.clientWidth;
			this.drawColorCanvas();
			this.drawColorPaletteCanvas();
			this.$forceCompute('colorCanvasThumbStyles');
			this.$forceCompute('colorPaletteCanvasThumbStyles');
		}
	}
}
