import './defines';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import type { SVGColorReplacement } from '@sosocio/frontend-utils/types/svg';
import type {
	LogoBackgroundDetectedOfferingFrameModel,
	LogoBackgroundDetectedPageObjectModel,
	LogoBackgroundPrintEffect,
} from 'interfaces/app';
import { OFFERING_PRINT_EFFECTS } from 'settings/offerings';
import { project as projectTools } from 'tools';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'LogoBackgroundDetectedView',
})
export default class LogoBackgroundDetectedView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the object model in which the logo background was detected and could be removed',
		required: true,
		schema: 'LogoBackgroundDetectedPageObjectModel',
		type: Object,
	})
	public readonly objectModel!: LogoBackgroundDetectedPageObjectModel;

	@Prop({
		description: 'Defines the offering frame model to render as background of the SVGs (used when having a `printEffect` in the offering models)',
		schema: 'LogoBackgroundDetectedOfferingFrameModel',
		type: Object,
	})
	public readonly offeringFrameModel?: LogoBackgroundDetectedOfferingFrameModel;

	@Prop({
		acceptedValues: OFFERING_PRINT_EFFECTS,
		description: 'Defines the print effect to apply to the SVGs',
		schema: 'LogoBackgroundPrintEffect',
		type: String,
	})
	public readonly printEffect?: LogoBackgroundPrintEffect;

	protected get svgContentAttributes(): Record<string, string> {
		if (!this.objectModel._vectorSVG) {
			return {};
		}

		const temporaryDivElement = document.createElement('div');
		temporaryDivElement.innerHTML = this.objectModel._vectorSVG;
		const svgElement = temporaryDivElement.firstChild as SVGElement;
		const svgElementAttributes: Record<string, string> = {};

		if (
			this.printEffect === 'embossing'
			|| this.printEffect === 'engraving'
			|| this.printEffect === 'laser engraving'
		) {
			svgElementAttributes.filter = 'url(#embossing)';
		}

		return Object
			.keys(svgElement.attributes)
			.reduce(
				(attributes, key) => {
					const attribute = svgElement.attributes.item(Number(key));

					if (attribute) {
						attributes[attribute.name] = attribute.value;
					}

					return attributes;
				},
				svgElementAttributes,
			);
	}

	protected get svgContentWithBackgroundHTML(): string {
		if (
			!this.objectModel._vectorSVG
			|| !this.objectModel._vectorColors
		) {
			return '';
		}

		const temporaryDivElement = document.createElement('div');
		temporaryDivElement.innerHTML = svgUtils.replaceColors(
			this.objectModel._vectorSVG,
			this.objectModel._vectorColors,
			(this.objectModel.colorReplacement || []).reduce(
				(
					colorReplacements,
					colorReplacement,
				) => {
					colorReplacements.push({
						color: colorReplacement.color,
						replace: (
							colorReplacement.replace.visual
							|| colorReplacement.replace.real
						),
					});

					return colorReplacements;
				},
				[] as SVGColorReplacement[],
			),
		);
		const svgElement = temporaryDivElement.firstChild as SVGElement;
		projectTools.svgAddPrintEffect(
			svgElement,
			this.printEffect,
		);

		return svgElement.innerHTML;
	}

	protected get svgContentWithoutBackgroundHTML(): string {
		if (
			!this.objectModel._vectorSVG
			|| !this.objectModel._vectorColors
		) {
			return '';
		}

		const { _vectorColors } = this.objectModel;
		const temporaryDivElement = document.createElement('div');

		if (!_vectorColors.background) {
			temporaryDivElement.innerHTML = this.objectModel._vectorSVG;
		} else {
			const { background } = _vectorColors;
			let colorReplacementForReplaceColors: SVGColorReplacement[] | SVGColorReplacement;

			if (this.objectModel.colorReplacement?.length) {
				colorReplacementForReplaceColors = this.objectModel.colorReplacement.reduce(
					(
						colorReplacements,
						colorReplacement,
					) => {
						if (colorReplacement.color === background.color) {
							colorReplacements.push({
								color: colorReplacement.color,
								replace: 'transparent',
							});
						} else {
							colorReplacements.push({
								color: colorReplacement.color,
								replace: (
									colorReplacement.replace.visual
									|| colorReplacement.replace.real
								),
							});
						}

						return colorReplacements;
					},
					[] as SVGColorReplacement[],
				);
			} else {
				colorReplacementForReplaceColors = {
					color: _vectorColors.background.color,
					replace: 'transparent',
				};
			}

			temporaryDivElement.innerHTML = svgUtils.replaceColors(
				this.objectModel._vectorSVG,
				_vectorColors,
				colorReplacementForReplaceColors,
			);
		}

		const svgElement = temporaryDivElement.firstChild as SVGElement;
		projectTools.svgAddPrintEffect(
			svgElement,
			this.printEffect,
		);

		return svgElement.innerHTML;
	}

	protected styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

	@Watch(
		'offeringFrameModel',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onOfferingFrameModelChange(): void {
		if (!this.offeringFrameModel) {
			this.styles['--svg-background-color'] = (
				this.objectModel._vectorColors?.background?.color
				|| '#ffffff'
			);
			delete this.styles['--svg-background-image'];
		} else {
			const { offeringFrameModel } = this;
			const image = new Image();
			image.crossOrigin = 'Anonymous';
			image.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.height = offeringFrameModel.templateModel.height;
				canvas.width = offeringFrameModel.templateModel.width;
				const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
				canvasContext.drawImage(
					image,
					offeringFrameModel.templateModel.x,
					offeringFrameModel.templateModel.y,
					offeringFrameModel.templateModel.width,
					offeringFrameModel.templateModel.height,
					0,
					0,
					canvas.width,
					canvas.height,
				);
				const base64Data = canvas.toDataURL('image/png');
				this.styles['--svg-background-image'] = `url(${base64Data})`;
				delete this.styles['--svg-background-color'];
			};
			image.src = `${offeringFrameModel.imageModel.url}?noCorsHeader`;
		}
	}
}
