import './defines';
import ColorConverter from '@sosocio/color-converter';
import * as svgUtils from '@sosocio/frontend-utils/svg';
import ProductState from 'classes/productstate';
import TemplateClass, { PhotoData } from 'classes/template';
import TemplatePosition from 'classes/templateposition';
import Theme from 'classes/theme';
import ButtonComponent from 'components/button';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import InputSliderComponent from 'components/input-slider';
import TooltipComponent from 'components/tooltip';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import navigate from 'controllers/navigate';
import {
	ChannelModel,
	EditorCanvasPageObjectModel,
	EditorCanvasPageObjectsModel,
	EditorInteractivePageObjectModel,
	EditorModuleCropMode,
	EditorModulePageTemplatePositions,
	EditorModuleProductToolsSupported,
	EditorModuleWindowClickHandlerType,
	EditorModuleWindowClickHandlers,
	EditorProductSettingsModel,
	EditorToolbarActiveMode,
	EditorTooltipKeys,
	EditorTooltipType,
	FullOfferingModel,
	FullOfferingModels,
	OfferingFrameModel,
	OfferingOptionsSummaryToolbarFilterTags,
	ProjectProductSideData,
	TemplatePhotoPosition,
	TemplateSet,
	TemplateTextPosition,
} from 'interfaces/app';
import {
	CountryModel,
	CurrencyModel,
	Model2DModel,
	Model3DModel,
	OfferingModel,
	PhotoModel,
	ProductModel,
	TemplateModel,
	ThemeModel,
} from 'interfaces/database';
import {
	PageModel,
	PageObjectColorReplacementModel,
	PageObjectColorReplacementModels,
	PageObjectModel,
	ProductDataModel,
	ProductSettings,
	PhotoModel as ProjectPhotoModel,
} from 'interfaces/project';
import applyMask from 'mutations/pageobject/apply-mask';
import changeObjectPhoto from 'mutations/pageobject/change-photo';
import deleteObject from 'mutations/pageobject/delete';
import checkConnection from 'services/check-connection';
import { ERRORS_LOAD_FONT } from 'settings/errors';
import {
	COLOR_FULL,
	OfferingGroups,
	PRINT_COLOR_SPECTRUM_FULL,
} from 'settings/offerings';
import {
	AppDataModule,
	AppStateModule,
	ChannelsModule,
	ConfigModule,
	PhotosModule,
	ProductStateModule,
	ProductsModule,
	ThemeDataModule,
	ThemeStateModule,
	UserModule,
} from 'store';
import {
	logoColors as logoColorsTools,
	mobile as mobileTools,
	project as projectTools,
	viewport as viewportTools,
} from 'tools';
import maxCanvasSize from 'tools/max-canvas-size';
import {
	dom as domUtils,
	object as objectUtils,
	orientation as orientationUtils,
} from 'utils';
import EditorCanvasView from 'views/editor-canvas';
import EditorDrawView from 'views/editor-draw';
import EditorFrameSelectorView from 'views/editor-frame-selector';
import EditorPaginationView from 'views/editor-pagination';
import EditorPreviewView from 'views/editor-preview';
import EditorTextCTAButtonsView from 'views/editor-text-cta-buttons';
import EditorToolbarView from 'views/editor-toolbar';
import EditorTooltipView from 'views/editor-tooltip';
import EditorTopToolbarView from 'views/editor-top-toolbar';
import ExceededColorSelectionView from 'views/exceeded-color-selection';
import ImportLightboxView from 'views/import-lightbox';
import LogoBackgroundDetectedView from 'views/logo-background-detected';
import LogoColorLimitExceededHeaderView from 'views/logo-color-limit-exceeded-header';
import LogoColorLimitExceededBodyView from 'views/logo-color-limit-exceeded-body';
import OfferingOptionsSummaryToolbarView from 'views/offering-options-summary-toolbar';
import PriceView from 'views/price';
import ToolbarPhotoView from 'views/toolbar-photo';
import {
	Component,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import { Route } from 'vue-router';
import Template from './template.vue';

@Component({
	name: 'NewEditorModule',
	components: {
		ButtonComponent,
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
		EditorCanvasView,
		EditorDrawView,
		EditorPaginationView,
		EditorPreviewView,
		EditorTextCTAButtonsView,
		EditorToolbarView,
		EditorTopToolbarView,
		InputSliderComponent,
		OfferingOptionsSummaryToolbarView,
		PriceView,
	},
})
export default class NewEditorModule extends Vue.extend(Template) {
	private get areToolbarsVisible(): boolean {
		if (!this.isMobile) {
			return true;
		}

		return !this.textObjectSelected;
	}

	protected get bleedMargin(): number {
		if (
			this.fullOfferingModel
			&& this.fullOfferingModel.showbleed > 0
			&& this.pageModel?.offset
		) {
			return this.pageModel.offset;
		}

		return 0;
	}

	private get canvasCropBoxHeight(): number {
		if (!this.editorCanvasCropContainerElement) {
			return 0;
		}

		const canvasCropContainerElementRect = this.editorCanvasCropContainerElement.getBoundingClientRect();
		const canvasCropContainerElementComputedStyle = getComputedStyle(this.editorCanvasCropContainerElement);
		let boxHeight = canvasCropContainerElementRect.height;

		if (canvasCropContainerElementComputedStyle.paddingTop) {
			boxHeight -= parseInt(
				canvasCropContainerElementComputedStyle.paddingTop,
				10,
			);
		}
		if (canvasCropContainerElementComputedStyle.paddingBottom) {
			boxHeight -= parseInt(
				canvasCropContainerElementComputedStyle.paddingBottom,
				10,
			);
		}

		return boxHeight;
	}

	private get canvasCropBoxWidth(): number {
		if (!this.editorCanvasCropContainerElement) {
			return 0;
		}

		const canvasCropContainerElementRect = this.editorCanvasCropContainerElement.getBoundingClientRect();
		const canvasCropContainerElementComputedStyle = getComputedStyle(this.editorCanvasCropContainerElement);
		let boxWidth = canvasCropContainerElementRect.width;

		if (canvasCropContainerElementComputedStyle.paddingLeft) {
			boxWidth -= parseInt(
				canvasCropContainerElementComputedStyle.paddingLeft,
				10,
			);
		}
		if (canvasCropContainerElementComputedStyle.paddingRight) {
			boxWidth -= parseInt(
				canvasCropContainerElementComputedStyle.paddingRight,
				10,
			);
		}

		return boxWidth;
	}

	protected get canvasCropHeight(): number {
		if (!this.virtualPageObjects.length) {
			return 0;
		}

		const objectModel = this.virtualPageObjects[0];
		const heightRatio = objectModel.height / objectModel.cropheight;
		const objectRatio = objectModel.maxwidth / objectModel.maxheight;
		let canvasCropHeight = objectModel.maxheight * heightRatio;
		const canvasCropWidth = canvasCropHeight * objectRatio;

		if (objectModel.rotate) {
			const rotatedBoundingBox = this.calculateRotatedBoundingBox({
				...objectModel,
				height: canvasCropHeight,
				width: canvasCropWidth,
			});
			canvasCropHeight = rotatedBoundingBox.height;
		}

		return canvasCropHeight;
	}

	protected get canvasCropWidth(): number {
		if (!this.virtualPageObjects.length) {
			return 0;
		}

		const objectModel = this.virtualPageObjects[0];
		const widthRatio = objectModel.width / objectModel.cropwidth;
		const objectRatio = objectModel.maxwidth / objectModel.maxheight;
		let canvasCropWidth = objectModel.maxwidth * widthRatio;
		const canvasCropHeight = canvasCropWidth / objectRatio;

		if (objectModel.rotate) {
			const rotatedBoundingBox = this.calculateRotatedBoundingBox({
				...objectModel,
				height: canvasCropHeight,
				width: canvasCropWidth,
			});
			canvasCropWidth = rotatedBoundingBox.width;
		}

		return canvasCropWidth;
	}

	private get canvasSize(): number {
		if (this.pageModel) {
			return (this.pageModel.width + (this.bleedMargin * 2)) * (this.pageModel.height + (this.bleedMargin * 2));
		}

		return 0;
	}

	protected get computedClasses(): Record<string, boolean> {
		return {
			'editor-module-preview-active': this.isPreviewModeActive,
			'editor-module-logo-product': this.isLogoProduct,
			'editor-module-no-photo-objects': this.photoObjects.length === 0,
			'editor-module-photo-selected': (
				!!this.photoObjectSelected
				&& (
					this.isProjectSinglePage
					|| this.pageMultiplePhotoSupport
				)
			),
		};
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};
		styles.height = `${this.viewportSize.height}px`;

		/**
		 * If the zoom value is not 100%, apply scaling
		 */
		if (this.zoomSliderValue !== 100) {
			const scale = this.zoomSliderValue / 100;
			styles['--editor-canvas-view-scale'] = String(scale);

			if (
				this.canvasContainerElement
				&& domUtils.isRectScrollable(this.canvasContainerElement)
			) {
				styles['--editor-canvas-container-overflow'] = 'scroll';
			}

			/**
			 * TODO: This is not working well since when the translation is negative
			 * the user cannot scroll to the top or left of the canvas.
			 * This needs to be changed to manually scroll instead of using the CSS.
			 */
			// if (this.canvasContainerElement) {
			// 	if (domUtils.isRectScrollable(this.canvasContainerElement)) {
			// 		styles['--editor-canvas-container-overflow'] = 'scroll';
			// 	}

			// 	if (this.editorCanvasComponent?.$el) {
			// 		const editorCanvasElement = this.editorCanvasComponent.$el;
			// 		const editorCanvasViewDrawViewsContainerElement = editorCanvasElement.querySelector('.editor-canvas-view-draw-views-container');

			// 		if (editorCanvasViewDrawViewsContainerElement) {
			// 			const editorCanvasViewDrawViewsContainerStyles = getComputedStyle(editorCanvasViewDrawViewsContainerElement);
			// 			/**
			// 			 * Get the position and size of the inner element
			// 			 */
			// 			const editorCanvasViewDrawViewsContainerLeft = parseInt(
			// 				editorCanvasViewDrawViewsContainerStyles.left,
			// 				10,
			// 			);
			// 			const editorCanvasViewDrawViewsContainerTop = parseInt(
			// 				editorCanvasViewDrawViewsContainerStyles.top,
			// 				10,
			// 			);
			// 			const editorCanvasViewDrawViewsContainerWidth = editorCanvasViewDrawViewsContainerElement.clientWidth;
			// 			const editorCanvasViewDrawViewsContainerHeight = editorCanvasViewDrawViewsContainerElement.clientHeight;

			// 			/**
			// 			 * Calculate the center position of the outer element
			// 			 */
			// 			const editorCanvasCenterX = editorCanvasElement.clientWidth / 2;
			// 			const editorCanvasCenterY = editorCanvasElement.clientHeight / 2;

			// 			/**
			// 			 * Calculate the center position of the inner element
			// 			 */
			// 			const editorCanvasViewDrawViewsContainerCenterX = editorCanvasViewDrawViewsContainerLeft + (editorCanvasViewDrawViewsContainerWidth / 2);
			// 			const editorCanvasViewDrawViewsContainerCenterY = editorCanvasViewDrawViewsContainerTop + (editorCanvasViewDrawViewsContainerHeight / 2);

			// 			/**
			// 			 * Calculate the scaled size of the inner element
			// 			 */
			// 			const editorCanvasViewDrawViewsContainerScaledWidth = editorCanvasViewDrawViewsContainerWidth * scale;
			// 			const editorCanvasViewDrawViewsContainerScaledHeight = editorCanvasViewDrawViewsContainerHeight * scale;

			// 			/**
			// 			 * Calculate the displacement offset based on the scale.
			// 			 * @param {number} outerCenter - The center of the outer element.
			// 			 * @param {number} innerCenter - The center of the inner element.
			// 			 * @param {number} innerSize - The size of the inner element.
			// 			 * @param {number} innerScaledSize - The scaled size of the inner element.
			// 			 * @returns {number} The calculated displacement offset.
			// 			 */
			// 			const calculateDisplacementOffset = (
			// 				outerCenter: number,
			// 				innerCenter: number,
			// 				innerSize: number,
			// 				innerScaledSize: number,
			// 			): number => (
			// 				((outerCenter - innerCenter) * (scale - 1)) + (Math.sign(outerCenter - innerCenter) * (innerSize - innerScaledSize) / 2)
			// 			);

			// 			/**
			// 			 * Calculate the displacement offsets for X and Y axes
			// 			 */
			// 			const editorCanvasViewDrawViewsContainerDisplacementOffsetX = calculateDisplacementOffset(
			// 				editorCanvasCenterX,
			// 				editorCanvasViewDrawViewsContainerCenterX,
			// 				editorCanvasViewDrawViewsContainerWidth,
			// 				editorCanvasViewDrawViewsContainerScaledWidth,
			// 			);
			// 			const editorCanvasViewDrawViewsContainerDisplacementOffsetY = calculateDisplacementOffset(
			// 				editorCanvasCenterY,
			// 				editorCanvasViewDrawViewsContainerCenterY,
			// 				editorCanvasViewDrawViewsContainerHeight,
			// 				editorCanvasViewDrawViewsContainerScaledHeight,
			// 			);

			// 			/**
			// 			 * Calculate the centered offsets for X and Y axes
			// 			 */
			// 			const editorCanvasViewDrawViewsContainerCenteredOffsetX = editorCanvasCenterX - editorCanvasViewDrawViewsContainerCenterX;
			// 			const editorCanvasViewDrawViewsContainerCenteredOffsetY = editorCanvasCenterY - editorCanvasViewDrawViewsContainerCenterY;

			// 			/**
			// 			 * Calculate the blend factor for smooth transition
			// 			 * between the displacement and centered offsets.
			// 			 * The `-5` can be adjusted to change the curve steepness.
			// 			 */
			// 			const blendFactor = 1 - Math.exp(-5 * (scale - 1));

			// 			/**
			// 			 * Calculate the final offsets for X and Y axes using the blend factor
			// 			 */
			// 			const editorCanvasViewDrawViewsContainerOffsetX = (editorCanvasViewDrawViewsContainerDisplacementOffsetX * (1 - blendFactor)) + (editorCanvasViewDrawViewsContainerCenteredOffsetX * blendFactor);
			// 			const editorCanvasViewDrawViewsContainerOffsetY = (editorCanvasViewDrawViewsContainerDisplacementOffsetY * (1 - blendFactor)) + (editorCanvasViewDrawViewsContainerCenteredOffsetY * blendFactor);

			// 			/**
			// 			 * Get the position and size of the canvas element
			// 			 */
			// 			const editorCanvasStyles = getComputedStyle(editorCanvasElement);
			// 			const editorCanvasLeft = parseInt(
			// 				editorCanvasStyles.left,
			// 				10,
			// 			);
			// 			const editorCanvasTop = parseInt(
			// 				editorCanvasStyles.top,
			// 				10,
			// 			);
			// 			const editorCanvasWidth = editorCanvasElement.clientWidth;
			// 			const editorCanvasHeight = editorCanvasElement.clientHeight;

			// 			/**
			// 			 * Calculate the final center positions of the canvas element
			// 			 */
			// 			const editorCanvasFinalCenterX = editorCanvasLeft + (editorCanvasWidth / 2);
			// 			const editorCanvasFinalCenterY = editorCanvasTop + (editorCanvasHeight / 2);

			// 			/**
			// 			 * Calculate the offsets for the canvas element based on the scale
			// 			 */
			// 			const editorCanvasOffsetX = (editorCanvasFinalCenterX - editorCanvasViewDrawViewsContainerCenterX) * (scale - 1);
			// 			const editorCanvasOffsetY = (editorCanvasFinalCenterY - editorCanvasViewDrawViewsContainerCenterY) * (scale - 1);

			// 			/**
			// 			 * Calculate the final offsets for the canvas element based
			// 			 * on the scale and the final offsets of the inner element
			// 			 * which is the one that is meant to be centered.
			// 			 */
			// 			const editorCanvasFinalOffsetX = editorCanvasViewDrawViewsContainerOffsetX + editorCanvasOffsetX;
			// 			const editorCanvasFinalOffsetY = editorCanvasViewDrawViewsContainerOffsetY + editorCanvasOffsetY;

			// 			styles['--editor-canvas-view-offset-x'] = `${editorCanvasFinalOffsetX}px`;
			// 			styles['--editor-canvas-view-offset-y'] = `${editorCanvasFinalOffsetY}px`;
			// 		}
			// 	}
			// }
		}

		return styles;
	}

	private get countryModel(): CountryModel | undefined {
		if (UserModule.countryid) {
			return AppDataModule.getCountry(UserModule.countryid);
		}

		return undefined;
	}

	protected get cropGridWrapperStyles(): Partial<CSSStyleDeclaration> {
		let height: number;
		let width: number;

		if (
			!this.offeringFrameModel?.required
			|| this.photoObjectSelected
		) {
			let bleedMargin = 0;

			if (this.showBleed) {
				bleedMargin = this.bleedMargin;
			}

			height = (this.virtualPageModel?.height || 0) + (bleedMargin * 2);
			width = (this.virtualPageModel?.width || 0) + (bleedMargin * 2);
		} else {
			height = this.offeringFrameModel.imageModel?.height ?? 0;
			width = this.offeringFrameModel.imageModel?.width ?? 0;
		}

		return {
			height: `${Math.round(height * this.scaling)}px`,
			pointerEvents: (
				this.isEyeDropperShown
					? 'none'
					: ''
			),
			width: `${Math.round(width * this.scaling)}px`,
		};
	}

	protected get cropPhotoObjectStyles(): Partial<CSSStyleDeclaration> {
		const {
			photoObjectSelected,
			cropPhotoObject,
			virtualPageModel,
		} = this;
		const styles: Partial<CSSStyleDeclaration> = {};

		if (
			photoObjectSelected
			&& cropPhotoObject
			&& virtualPageModel
		) {
			const originalObject = this.virtualPageObjects[0];
			const {
				canvasCropBoxHeight,
				canvasCropBoxWidth,
			} = this;

			const originalObjectHeightRatio = originalObject.height / originalObject.cropheight;
			const originalObjectWidthRatio = originalObject.width / originalObject.cropwidth;

			let originalObjectCenterX = (originalObject.cropx + (originalObject.cropwidth / 2)) * originalObjectWidthRatio;
			let originalObjectCenterY = (originalObject.cropy + (originalObject.cropheight / 2)) * originalObjectHeightRatio;

			let left = (canvasCropBoxWidth / 2) - (originalObjectCenterX * this.offeringFrameScaling);
			let top = (canvasCropBoxHeight / 2) - (originalObjectCenterY * this.offeringFrameScaling);

			if (originalObject.rotate) {
				const newObjectModel: EditorCanvasPageObjectModel = {
					...originalObject,
				};
				const heightRatio = newObjectModel.height / newObjectModel.cropheight;
				const widthRatio = newObjectModel.width / newObjectModel.cropwidth;
				newObjectModel.height = newObjectModel.maxheight * heightRatio;
				newObjectModel.width = newObjectModel.maxwidth * widthRatio;

				const originalObjectRotatedBoundingBox = this.calculateRotatedBoundingBox({
					...originalObject,
					rotate: -originalObject.rotate,
				});
				const {
					x: rotatedCenterX,
					y: rotatedCenterY,
				} = this.rotatePointInRectangle(
					originalObjectCenterX,
					originalObjectCenterY,
					{
						...cropPhotoObject,
						height: newObjectModel.height,
						width: newObjectModel.width,
						x_axis: originalObjectRotatedBoundingBox.x,
						y_axis: originalObjectRotatedBoundingBox.y,
					},
				);

				originalObjectCenterX = rotatedCenterX;
				originalObjectCenterY = rotatedCenterY;
				left = (canvasCropBoxWidth / 2) - (originalObjectCenterX * this.offeringFrameScaling);
				top = (canvasCropBoxHeight / 2) - (originalObjectCenterY * this.offeringFrameScaling);
			}

			styles.left = `${left}px`;
			styles.top = `${top}px`;
		}

		return styles;
	}

	protected get cropPhotoObject(): EditorCanvasPageObjectModel | undefined {
		const { virtualPageObjects } = this;

		if (
			!this.photoObjectSelected
			|| virtualPageObjects.length < 0
		) {
			return undefined;
		}

		const [virtualPhotoObjectSelected] = virtualPageObjects;
		const newObjectModel: EditorCanvasPageObjectModel = {
			...virtualPhotoObjectSelected,
		};

		const heightRatio = newObjectModel.height / newObjectModel.cropheight;
		const widthRatio = newObjectModel.width / newObjectModel.cropwidth;

		newObjectModel.height = newObjectModel.maxheight * heightRatio;
		newObjectModel.width = newObjectModel.maxwidth * widthRatio;
		newObjectModel.cropheight = newObjectModel.maxheight;
		newObjectModel.cropwidth = newObjectModel.maxwidth;
		newObjectModel.cropx = 0;
		newObjectModel.cropy = 0;

		const rotatedBoundingBox = this.calculateRotatedBoundingBox({
			...newObjectModel,
			x_axis: 0,
			y_axis: 0,
		});

		newObjectModel.x_axis = -rotatedBoundingBox.x;
		newObjectModel.y_axis = -rotatedBoundingBox.y;

		return newObjectModel;
	}

	private get cropPhotoZoomValue(): number {
		const { photoObjectSelected } = this;

		if (!photoObjectSelected) {
			return 0;
		}

		let inverseZoom: number;
		const cropRatioX = photoObjectSelected.cropwidth / photoObjectSelected.maxwidth;
		const cropRatioY = photoObjectSelected.cropheight / photoObjectSelected.maxheight;

		if (cropRatioX < cropRatioY) {
			inverseZoom = (photoObjectSelected.cropheight * 100) / photoObjectSelected.maxheight;
		} else {
			inverseZoom = (photoObjectSelected.cropwidth * 100) / photoObjectSelected.maxwidth;
		}

		const zoomDifference = 100 - inverseZoom;

		if (zoomDifference < 1) {
			return 1;
		}
		if (zoomDifference > 100) {
			return 100;
		}

		return zoomDifference;
	}

	private get currencyModel(): CurrencyModel | undefined {
		if (UserModule.currency) {
			return AppDataModule.getCurrency(UserModule.currency);
		}

		return undefined;
	}

	private get currentOfferingModel(): OfferingModel | null {
		return ProductStateModule.getOffering;
	}

	private get currentOfferingModelColor(): number {
		if (!this.currentOfferingModel) {
			return COLOR_FULL;
		}

		return projectTools.getOfferingModelColorQuantity(this.currentOfferingModel);
	}

	private get editorBoxMaxHeight(): number {
		if (
			!this.canvasContainerElement
			|| !this.editorMainSectionElement
			|| !this.editorPaginationContainerElement
			|| !this.editorPaginationComponent?.$el
		) {
			return 0;
		}

		const doneAdjustingButtonElementRect = this.doneAdjustingButtonComponent?.$el.getBoundingClientRect();
		const editorTextOptionsElementRect = this.editorTextOptionsElement?.getBoundingClientRect();
		const mainSectionElementRect = this.editorMainSectionElement.getBoundingClientRect();
		const paginationElementRect = this.editorPaginationContainerElement.getBoundingClientRect();
		const paginationElementComputedStyle = getComputedStyle(this.editorPaginationContainerElement);
		const doneAdjustingButtonElementComputedStyle = (
			this.doneAdjustingButtonComponent?.$el
				? getComputedStyle(this.doneAdjustingButtonComponent?.$el)
				: undefined
		);
		const editorTextOptionsElementComputedStyle = (
			this.editorTextOptionsElement
				? getComputedStyle(this.editorTextOptionsElement)
				: undefined
		);
		const canvasContainerElementComputedStyle = getComputedStyle(this.canvasContainerElement);
		let boxHeight = (
			mainSectionElementRect.height
			- paginationElementRect.height
			- (
				editorTextOptionsElementRect?.height
				|| 0
			)
			- (
				(!editorTextOptionsElementRect && doneAdjustingButtonElementRect?.height)
				|| 0
			)
		);

		if (canvasContainerElementComputedStyle.paddingTop) {
			boxHeight -= parseInt(
				canvasContainerElementComputedStyle.paddingTop,
				10,
			);
		}
		if (canvasContainerElementComputedStyle.paddingBottom) {
			boxHeight -= parseInt(
				canvasContainerElementComputedStyle.paddingBottom,
				10,
			);
		}
		if (
			this.editorPaginationContainerElement.offsetParent
			&& paginationElementComputedStyle.marginTop
		) {
			boxHeight -= parseInt(
				paginationElementComputedStyle.marginTop,
				10,
			);
		}
		if (
			this.editorPaginationContainerElement.offsetParent
			&& paginationElementComputedStyle.marginBottom
		) {
			boxHeight -= parseInt(
				paginationElementComputedStyle.marginBottom,
				10,
			);
		}
		if (editorTextOptionsElementComputedStyle?.marginTop) {
			boxHeight -= parseInt(
				editorTextOptionsElementComputedStyle.marginTop,
				10,
			);
		}
		if (editorTextOptionsElementComputedStyle?.marginBottom) {
			boxHeight -= parseInt(
				editorTextOptionsElementComputedStyle.marginBottom,
				10,
			);
		}
		if (
			!editorTextOptionsElementRect
			&& doneAdjustingButtonElementComputedStyle?.marginTop
		) {
			boxHeight -= parseInt(
				doneAdjustingButtonElementComputedStyle.marginTop,
				10,
			);
		}
		if (
			!editorTextOptionsElementRect
			&& doneAdjustingButtonElementComputedStyle?.marginBottom
		) {
			boxHeight -= parseInt(
				doneAdjustingButtonElementComputedStyle.marginBottom,
				10,
			);
		}

		return boxHeight;
	}

	private get editorBoxMaxWidth(): number {
		if (
			!this.canvasContainerElement
			|| !this.editorMainSectionElement
		) {
			return 0;
		}

		const editorMainSectionElementRect = this.editorMainSectionElement.getBoundingClientRect();
		let boxWidth = editorMainSectionElementRect.width;
		const canvasContainerElementComputedStyle = getComputedStyle(this.canvasContainerElement);

		if (canvasContainerElementComputedStyle.paddingLeft) {
			boxWidth -= parseInt(
				canvasContainerElementComputedStyle.paddingLeft,
				10,
			);
		}
		if (canvasContainerElementComputedStyle.paddingRight) {
			boxWidth -= parseInt(
				canvasContainerElementComputedStyle.paddingRight,
				10,
			);
		}

		return boxWidth;
	}

	protected get editorCanvasAddPrintEffect(): boolean {
		return (
			!this.photoObjectSelected
			&& (
				this.fullOfferingModel?.printEffect === 'embossing'
				|| this.fullOfferingModel?.printEffect === 'metalic engraving'
				|| this.fullOfferingModel?.printEffect === 'engraving'
				|| this.fullOfferingModel?.printEffect === 'laser engraving'
			)
		);
	}

	protected get editorCanvasClasses(): Record<string, boolean> {
		const vectorColorBackground = this.photoObjectSelected?._vectorColors?.background;

		return {
			'editor-canvas-view-is-transparent-vector': (
				this.logoColorsCanBeEdited
				&& !!(
					vectorColorBackground?.color === 'transparent'
					|| (
						vectorColorBackground
						&& this.photoObjectSelected?.colorReplacement?.find((replacement) => (
							replacement.color === vectorColorBackground.color
							&& replacement.replace.real === 'transparent'
						))
					)
				)
			),
		};
	}

	protected get editorCanvasHideOverlay(): boolean {
		if (
			!this.fullOfferingModel?.overlay
			&& !this.offeringFrameModel
		) {
			return false;
		}

		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'SingleLayer',
			)
		) {
			return false;
		}

		if (this.photoObjectSelected) {
			return true;
		}

		return false;
	}

	protected get editorPaginationPageList(): EditorPaginationView['pageList'] {
		return ProductStateModule.getEditablePages.reduce(
			(pageList, page) => {
				pageList.set(
					page.id,
					ProductStateModule.getPageIndex(page),
				);

				return pageList;
			},
			new Map<string, number>(),
		);
	}

	private get editorPaginationTotalPages(): EditorPaginationView['totalPages'] {
		return ProductStateModule.getPageCount || 0;
	}

	protected get editorPaginationValue(): EditorPaginationView['value'] {
		return this.pageModel?.id || '';
	}

	protected get editorProductSettingsModel(): EditorProductSettingsModel {
		const editorProductSettings: EditorProductSettingsModel = {};

		if (
			this.pageModel?.offset
			&& (
				this.currentOfferingModel?.showbleed === 1
				|| this.currentOfferingModel?.showbleed === 3
			)
		) {
			editorProductSettings.showBleedMargin = AppStateModule.showBleed;
		}
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'BasicProducts',
			)
			&& (
				this.currentOfferingModel.applyEnhancement
				|| this.currentOfferingModel.applyUpscaling
			)
		) {
			editorProductSettings.applyEnhancement = ProductStateModule.getProductSettings.applyEnhancement;
		}

		return editorProductSettings;
	}

	protected get editorModuleToolbarClasses(): Record<string, boolean> {
		return {
			'editor-module-toolbar-photo-selected': (
				!this.isMobile
				&& !!this.photoObjectSelected
				&& (
					this.isProjectSinglePage
					|| this.pageMultiplePhotoSupport
				)
			),
		};
	}

	protected get editorModuleTopToolbarClasses(): Record<string, boolean> {
		return {
			'editor-module-top-toolbar-floating': (
				!this.isMobile
				&& !!this.photoObjectSelected
				&& (
					this.isProjectSinglePage
					|| this.pageMultiplePhotoSupport
				)
			),
		};
	}

	private get fullOfferingModel(): FullOfferingModel | undefined {
		if (this.currentOfferingModel) {
			if (!this.isVirtualOffering) {
				return AppDataModule.getFullOfferingOptionModels({
					offeringModels: [this.currentOfferingModel],
				})[0];
			}

			if (this.pageModel?.offeringId) {
				return AppDataModule.getFullOfferingOptionModels({
					offeringIds: [this.pageModel.offeringId],
					virtual: true,
				})[0];
			}
		}

		return undefined;
	}

	protected get fullOfferingModelPrice(): number {
		if (
			!this.currencyModel
			|| !this.fullOfferingModel
		) {
			return 0;
		}

		const pricingModel = AppDataModule.findPricingWhere({
			offeringid: this.fullOfferingModel.id,
			currency: this.currencyModel.id,
		});

		return pricingModel?.price_base || 0;
	}

	protected get fullOfferingModels(): FullOfferingModels {
		const { fullOfferingModel } = this;

		if (fullOfferingModel) {
			let offeringModels: OfferingModel[];

			if (this.isVirtualOffering) {
				offeringModels = AppDataModule.findOffering({
					flexgroupid: fullOfferingModel.flexgroupid,
					groupid: fullOfferingModel.groupid,
					instock: 1,
					virtual: 0,
				});
			} else if (fullOfferingModel.flexgroupid) {
				offeringModels = AppDataModule.findOffering({
					flexgroupid: fullOfferingModel.flexgroupid,
					instock: 1,
				});
			} else {
				offeringModels = AppDataModule.findOffering({
					groupid: fullOfferingModel.groupid,
					typeid: fullOfferingModel.typeid,
					instock: 1,
				});
			}

			if (this.currencyModel) {
				const { currencyModel } = this;
				offeringModels = offeringModels.sort((a, b) => {
					const priceDataA = AppDataModule.findPricingWhere({
						offeringid: a.id,
						currency: currencyModel.id,
					});
					const priceDataB = AppDataModule.findPricingWhere({
						offeringid: b.id,
						currency: currencyModel.id,
					});

					if (!priceDataA) {
						return 1;
					}
					if (!priceDataB) {
						return -1;
					}

					return priceDataA.price_base - priceDataB.price_base;
				});
			}

			return AppDataModule.getFullOfferingOptionModels({
				offeringModels,
				// regionid: this.countryModel?.regionid,
				virtual: this.isVirtualOffering,
			});
		}

		return [];
	}

	private get fullPageHeight(): number {
		if (
			this.virtualPageModel
			&& (this.virtualPageModel.width / this.editorBoxMaxWidth) > (this.virtualPageModel.height / this.editorBoxMaxHeight)
		) {
			return (this.virtualPageModel.height + (this.bleedMargin * 2)) / (this.virtualPageModel.width + (this.bleedMargin * 2)) * this.fullPageWidth;
		}

		const fullPageHeight = Math.min(
			this.maxCanvasHeight,
			this.editorBoxMaxHeight,
			(this.virtualPageModel?.height || 0) + (this.bleedMargin * 2),
		);

		return fullPageHeight;
	}

	private get fullPageWidth(): number {
		const fullPageWidth = Math.min(
			this.maxCanvasWidth,
			this.editorBoxMaxWidth,
			(this.virtualPageModel?.width || 0) + (this.bleedMargin * 2),
		);

		return fullPageWidth;
	}

	private get hasFitToSizePhotoObject(): boolean {
		if (this.pageObjects.filter((object) => object.type === 'photo').length > 1) {
			return false;
		}

		return this.photoObjectSelected?.fillMethod === 'contain';
	}

	protected get hasSelectedOrSelectedForEditionObject(): boolean {
		return !!this.pageObjects.find((object) => object._selected || object._selectedForEdition);
	}

	protected get importChannels(): ChannelModel[] {
		return ChannelsModule.photoImportChannels;
	}

	protected get isAddPhotoAvailable(): boolean {
		if (
			!this.pageModel
			|| !this.currentOfferingModel
		) {
			return false;
		}

		if (this.isLogoProduct) {
			return false;
		}

		if (
			OfferingGroups(
				this.currentOfferingModel.groupid,
				'SingleLayer',
			)
		) {
			// These offerings consists of one print per photo
			// so users cannot add more photos to the page
			return false;
		}

		if (
			this.themeModel
			&& this.themeModel.maxphotos === 0
		) {
			return false;
		}

		if (!this.pageTemplateModel?.transformable) {
			return false;
		}

		const photoPosition = this.pageTemplatePositions.find((pageTemplatePosition) => pageTemplatePosition.type === 'photo');

		if (
			!photoPosition
			&& !this.photoObjects.length
		) {
			return false;
		}

		return true;
	}

	protected get isCropModeActive(): boolean {
		return this.editorToolbarActiveMode === 'crop';
	}

	protected get isEditorToolbarDisabled(): boolean {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'PhotoPrints',
			)
		) {
			return (
				!!this.textObjectSelected
				|| !!this.textObjectSelectedForEdition
			);
		}

		return false;
	}

	protected get isLogoProduct(): boolean {
		return this.currentOfferingModel?.type === 'logo';
	}

	protected get isPageTemplateTransformable(): boolean {
		return !!this.pageTemplateModel?.transformable;
	}

	protected get isPaginationDisabled(): boolean {
		if (
			(
				this.currentOfferingModel
				&& OfferingGroups(
					this.currentOfferingModel.groupid,
					'PhotoPrints',
				)
			)
			&& (
				this.isCropModeActive
				|| !!this.textObjectSelected
				|| !!this.textObjectSelectedForEdition
			)
		) {
			return true;
		}

		return false;
	}

	protected get isPaginationHidden(): boolean {
		if (
			!this.areToolbarsVisible
			|| this.hasProjectOnePage
			|| this.isPreviewModeActive
			|| (
				this.currentOfferingModel
				&& !OfferingGroups(
					this.currentOfferingModel.groupid,
					'SingleLayer',
				)
				&& this.photoObjectSelected
			)
		) {
			return true;
		}

		if (this.isMobile) {
			return (
				this.editorToolbarActiveMode === 'background'
				|| this.editorToolbarActiveMode === 'filter'
				|| this.editorToolbarActiveMode === 'shape'
			);
		}

		return false;
	}

	private get isProjectSinglePage(): boolean {
		if (
			this.projectModel
			&& OfferingGroups(
				this.projectModel.group,
				'BasicProducts',
			)
		) {
			return true;
		}

		return false;
	}

	protected get isRedoEnabled(): boolean {
		return ProductStateModule.hasFuture;
	}

	protected get isTapToContinueEditingVisible(): boolean {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'PhotoPrints',
			)
		) {
			return !!this.textObjectSelectedForEdition;
		}

		return false;
	}

	protected get isTextObjectEmpty(): boolean {
		return !this.textObjectSelected?.text;
	}

	protected get isUndoEnabled(): boolean {
		return ProductStateModule.hasHistory;
	}

	private get isVirtualOffering(): boolean {
		return this.currentOfferingModel?.virtual === 1;
	}

	protected get isZoomFeatureAvailable(): boolean {
		return (
			!this.isMobile
			&& ConfigModule['features.editor.zoom']
		);
	}

	private get hasProjectOnePage(): boolean {
		if (
			this.isProjectSinglePage
			|| this.editorPaginationTotalPages < 2
		) {
			return true;
		}

		return false;
	}

	protected get isMultiSidedProduct(): boolean {
		return ProductStateModule.isMultiSidedProduct;
	}

	private get hasTemplatePositionMultiplePhotos(): boolean {
		return !!this.templateSets.find((templateSet) => templateSet.positions.filter((position) => position.type === 'photo').length > 1);
	}

	private get maxCanvasHeight(): number {
		const maxCanvasHeight = Math.max(
			1,
			this.maxCanvasSize / this.canvasSize,
		);

		return maxCanvasHeight * ((this.pageModel?.height || 0) + 2 * this.bleedMargin);
	}

	private get maxCanvasSize(): number {
		return maxCanvasSize();
	}

	private get maxCanvasWidth(): number {
		const maxCanvasWidth = Math.max(
			1,
			this.maxCanvasSize / this.canvasSize,
		);

		return maxCanvasWidth * ((this.pageModel?.width || 0) + (this.bleedMargin * 2));
	}

	protected get offeringFrameModel(): OfferingFrameModel | undefined {
		if (!this.fullOfferingModel) {
			return undefined;
		}

		return AppDataModule.getOfferingFrame(
			this.fullOfferingModel.id,
			this.pageIndex,
		);
	}

	private get offeringFrameScaling(): number {
		if (
			this.pageModel
			&& this.showEditorOfferingFrame
			&& this.offeringFrameModel?.required
			&& !this.isCropModeActive
		) {
			const scaledFrameHeight = this.offeringFrameModel.templateModel.height * this.scaling;
			const scaledFrameWidth = this.offeringFrameModel.templateModel.width * this.scaling;

			return Math.min(
				scaledFrameWidth / this.pageModel.width,
				scaledFrameHeight / this.pageModel.height,
			);
		}

		return this.scaling;
	}

	private get offeringModelHasCube(): boolean {
		return !!(
			!this.offeringModelModel3D
			&& !this.offeringFrameModel
			&& this.fullOfferingModel?.depth
		);
	}

	private get offeringModelModel2D(): Model2DModel | undefined {
		const { fullOfferingModel } = this;

		if (
			!fullOfferingModel
			|| !fullOfferingModel.model2did
		) {
			return undefined;
		}

		return AppDataModule.models2d.find((model3d) => model3d.id === fullOfferingModel.model2did);
	}

	private get offeringModelModel3D(): Model3DModel | undefined {
		const { fullOfferingModel } = this;

		if (
			!fullOfferingModel
			|| !fullOfferingModel.model3did
		) {
			return undefined;
		}

		return AppDataModule.models3d.find((model3d) => model3d.id === fullOfferingModel.model3did);
	}

	protected get offeringModelShowPricing(): boolean {
		return Boolean(this.fullOfferingModel?.showPricing);
	}

	protected get offeringModelThemeModels(): ThemeModel[] {
		if (
			this.currentOfferingModel
			&& this.projectModel?.themeid
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				[
					'Cards',
					'Agendas',
				],
			)
		) {
			return ThemeDataModule.getThemesRelated(this.projectModel.themeid);
		}

		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'BasicProducts',
			)
		) {
			return ThemeDataModule.getThemesByOfferingId(this.currentOfferingModel.id);
		}

		return [];
	}

	protected get offeringOptionsSummaryToolbarOnlyOfferingName(): boolean {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'BookTypes',
			)
		) {
			return true;
		}

		return false;
	}

	protected get offeringOptionsSummaryToolbarOnlySummary(): boolean {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'BookTypes',
			)
		) {
			return true;
		}

		return false;
	}

	protected get offeringOptionsSummaryToolbarSlotName(): string {
		if (this.isMobile) {
			return 'bottom';
		}

		return 'center';
	}

	private get pageIndex(): number {
		if (ProductStateModule.getActivePage) {
			return ProductStateModule.getPageIndex(ProductStateModule.getActivePage);
		}

		return 0;
	}

	private get pageModel(): PageModel | undefined {
		return ProductStateModule.getPageByNumber(this.pageIndex);
	}

	protected get productToolsSupported(): EditorModuleProductToolsSupported {
		return {
			background: this.productHasBackground,
			border: this.photoSupportsBorder,
			deletePage: this.productDeletePageSupport,
			deletePrintOrPhoto: this.printOrPhotoCanBeDeleted,
			editColors: this.logoColorsCanBeEdited,
			frame: this.productFrameSupport,
			layout: this.templateSets.length > 1,
			multiplePhoto: this.pageMultiplePhotoSupport,
			multipleText: this.productMultipleTextSupport,
			shuffle: this.productShuffleSupport,
			text: this.pageTextSupport,
			theme: this.offeringModelThemeModels.length > 1,
		};
	}

	protected get pageMultiplePhotoSupport(): boolean {
		if (
			!this.pageModel
			|| !this.currentOfferingModel
		) {
			return false;
		}

		if (this.isLogoProduct) {
			return false;
		}

		if (
			OfferingGroups(
				this.currentOfferingModel.groupid,
				'SingleLayer',
			)
		) {
			// These offerings consists of one print per photo
			// so users cannot add more photos to the page
			return false;
		}

		if (
			this.hasTemplatePositionMultiplePhotos
			|| this.photoObjects.length > 1
		) {
			return true;
		}

		if (!this.pageTemplateModel?.transformable) {
			return false;
		}

		if (this.themeModel?.maxphotos === 0) {
			return false;
		}

		return true;
	}

	private get pageObjects(): EditorCanvasPageObjectsModel {
		if (!this.pageModel) {
			return [];
		}

		return ProductStateModule.getPageObjects(this.pageModel);
	}

	private get pageTemplateModel(): TemplateModel | undefined {
		if (!this.pageModel) {
			return undefined;
		}

		return ProductStateModule.getPageTemplate(this.pageModel) || undefined;
	}

	private get pageTemplatePositions(): EditorModulePageTemplatePositions {
		if (!this.pageModel) {
			return [];
		}

		return ProductStateModule.getPageTemplatePositions(this.pageModel);
	}

	private get pageTemplatePositionsAvailable(): EditorModulePageTemplatePositions {
		if (
			!this.pageModel?.editable
			|| !this.currentOfferingModel
			|| (
				this.photoObjectSelected
				&& !OfferingGroups(
					this.currentOfferingModel.groupid,
					'SingleLayer',
				)
			)
		) {
			return [];
		}

		return ProductStateModule.getPageTemplatePositionsAvailable(this.pageModel);
	}

	private get pageTextSupport(): boolean {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'SingleLayer',
			)
		) {
			return !!this.pageTemplateModel?.transformable;
		}

		if (!this.photoObjectSelected) {
			return !!this.pageTemplateModel?.transformable;
		}

		return false;
	}

	private get photoObjects(): PageObjectModel[] {
		return this.pageObjects.filter((object) => (
			object.type === 'photo'
			&& object.editable
		));
	}

	private get photoObjectSelected(): PageObjectModel | undefined {
		return this.photoObjects.find((object) => !!object._selected);
	}

	protected get photos(): PhotoModel[] {
		return this.photoObjects.reduce(
			(photos, object) => {
				if (object.photoid) {
					const photoFound = PhotosModule.getById(object.photoid);

					if (photoFound) {
						photos.push(photoFound);
					}
				}

				return photos;
			},
			[] as PhotoModel[],
		);
	}

	protected get printEffectColor(): string | undefined {
		if (!this.fullOfferingModel) {
			return undefined;
		}

		return projectTools.getOfferingModelPrintEffectColor(this.fullOfferingModel);
	}

	private get printOrPhotoCanBeDeleted(): boolean {
		if (
			!this.photoObjectSelected
			|| !this.currentOfferingModel
		) {
			return false;
		}

		if (
			OfferingGroups(
				this.currentOfferingModel.groupid,
				'PrintTypes',
			)
		) {
			return !!this.currentOfferingModel.pagequantity;
		}

		return true;
	}

	private get logoColorsCanBeEdited(): boolean {
		return !!(
			this.isLogoProduct
			&& this.photoObjectSelected?.ext === 'svg'
		);
	}

	private get photoSupportsBorder(): boolean {
		return !!(
			this.photoObjectSelected?.photoid
			&& this.projectModel
			&& OfferingGroups(
				this.projectModel.group,
				[
					'BookTypes',
					'WallDecoration',
				],
			)
		);
	}

	private get productDeletePageSupport(): boolean {
		if (
			this.virtualPageModel?.editable
			&& this.projectModel?.group
			&& OfferingGroups(
				this.projectModel.group,
				'BookTypes',
			)
			&& !this.fullOfferingModel?.spread
			&& (
				!this.fullOfferingModel?.maxpages
				|| !this.fullOfferingModel.minprintpages
				|| this.fullOfferingModel.maxpages > this.fullOfferingModel.minprintpages
			)
		) {
			const pageNumber = this.editorPaginationPageList.get(this.virtualPageModel.id);

			if (
				pageNumber
				&& pageNumber > 1
			) {
				return true;
			}
		}

		return false;
	}

	private get productFrameSupport(): boolean {
		return !!this.fullOfferingModel?.options.find((option) => option.tag === 'frame');
	}

	private get productHasBackground(): boolean {
		if (
			!this.pageModel
			|| !this.currentOfferingModel
		) {
			return false;
		}

		return !!(
			this.currentOfferingModel.groupid !== 102
			&& this.pageModel.bgcolor !== 'transparent'
			&& (
				!this.pageModel.bgimage
				|| this.pageModel.bgimage.length === 0
			)
			&& (
				!OfferingGroups(
					this.currentOfferingModel.groupid,
					'BasicProducts',
				)
				|| (
					this.themeModel
					&& this.themeModel.color
				)
			)
		);
	}

	protected get productHasFrame(): boolean {
		if (!this.fullOfferingModel) {
			return false;
		}

		return this.fullOfferingModel.options.some((option) => (
			option.tag === 'frame'
			&& option.value.value !== 'none'
		));
	}

	protected get productHasPreview(): boolean {
		return !!(
			this.offeringModelModel2D
			|| this.offeringModelModel3D
			|| this.offeringModelHasCube
		);
	}

	protected get productModel(): ProductDataModel {
		return ProductStateModule.getData;
	}

	private get productMultipleTextSupport(): boolean {
		if (!this.currentOfferingModel) {
			return false;
		}

		if (
			OfferingGroups(
				this.currentOfferingModel.groupid,
				'PhotoPrints',
			)
		) {
			// These offerings consists of one print per photo
			// so users cannot add more photos to the page
			return false;
		}

		return true;
	}

	private get productShuffleSupport(): boolean {
		if (
			this.pageModel
			&& this.currentOfferingModel
			&& this.currentOfferingModel.maxpages === 1
			&& this.projectPhotosSelected.length > 1
		) {
			const photoTemplatePositions = this.pageTemplatePositions.filter((position) => position.type === 'photo');

			if (photoTemplatePositions.length > 1) {
				return true;
			}
		}

		return false;
	}

	protected get projectModel(): ProductModel | null {
		return ProductStateModule.getProduct;
	}

	protected get projectModelHasOverview(): boolean {
		if (
			this.projectModel?.group
			&& OfferingGroups(
				this.projectModel.group,
				[
					'BookTypes',
					'PageSets',
				],
			)
		) {
			return true;
		}

		return false;
	}

	protected get projectPhotosQueued(): ProjectPhotoModel[] {
		return ProductStateModule.getPhotosQueued;
	}

	protected get projectPhotosSelected(): ProjectPhotoModel[] {
		return ProductStateModule.getPhotosSelected;
	}

	protected get projectProductSideData(): ProjectProductSideData[] | undefined {
		if (
			!this.isMultiSidedProduct
			|| !this.fullOfferingModel
		) {
			return [];
		}

		const { fullOfferingModel } = this;

		return ProductStateModule.getEditablePages.map((pageModel) => {
			let heightForScaling: number;
			let widthForScaling: number;
			const pageIndex = ProductStateModule.getPageIndex(pageModel);
			const pageOfferingFrameModel = AppDataModule.getOfferingFrame(
				fullOfferingModel.id,
				pageIndex,
			);
			const styles: ProjectProductSideData['styles'] = {};

			if (pageOfferingFrameModel?.required) {
				heightForScaling = pageOfferingFrameModel.imageModel.height;
				widthForScaling = pageOfferingFrameModel.imageModel.width;
				styles.filter = 'unset';
			} else {
				heightForScaling = pageModel.height;
				widthForScaling = pageModel.width;
			}

			const scaling = Math.min(
				73 / widthForScaling,
				64 / heightForScaling,
			);

			return {
				id: pageModel.id,
				label: ProductStateModule.getPageLabel(pageModel)?.toString() || '',
				offeringFrameModel: pageOfferingFrameModel,
				pageIndex,
				pageModel,
				pageObjects: ProductStateModule.getPageObjects(pageModel),
				scaling,
				styles,
			};
		}).sort((a, b) => b.pageIndex - a.pageIndex);
	}

	protected get projectThemeModel(): ThemeModel | undefined {
		if (this.projectModel?.themeid) {
			return ThemeDataModule.getTheme(this.projectModel.themeid);
		}

		return undefined;
	}

	protected get scaling(): number {
		if (this.forceScalingToZero) {
			return 0;
		}

		if (this.virtualPageModel) {
			if (
				this.offeringFrameModel
				&& this.showEditorOfferingFrame
				&& !this.editorCanvasHideOverlay
			) {
				const offeringFrameModelHeight = this.offeringFrameModel.imageModel.height;
				const offeringFrameModelWidth = this.offeringFrameModel.imageModel.width;

				const fullOfferingFrameWidth = Math.min(
					this.maxCanvasWidth,
					this.editorBoxMaxWidth,
					offeringFrameModelWidth,
				);
				let fullOfferingFrameHeight: number;

				if ((offeringFrameModelWidth / this.editorBoxMaxWidth) > (offeringFrameModelHeight / this.editorBoxMaxHeight)) {
					fullOfferingFrameHeight = offeringFrameModelHeight / offeringFrameModelWidth * fullOfferingFrameWidth;
				} else {
					fullOfferingFrameHeight = Math.min(
						this.maxCanvasHeight,
						this.editorBoxMaxHeight,
						offeringFrameModelHeight,
					);
				}

				return Math.min(
					fullOfferingFrameWidth / offeringFrameModelWidth,
					fullOfferingFrameHeight / offeringFrameModelHeight,
				);
			}

			let virtualPageModelHeight = this.virtualPageModel.height;
			let virtualPageModelWidth = this.virtualPageModel.width;

			if (this.photoObjectSelected?.borderwidth) {
				virtualPageModelHeight -= this.photoObjectSelected.borderwidth * 2;
				virtualPageModelWidth -= this.photoObjectSelected.borderwidth * 2;
			}

			return Math.min(
				this.fullPageWidth / (virtualPageModelWidth + (this.bleedMargin * 2)),
				this.fullPageHeight / (virtualPageModelHeight + (this.bleedMargin * 2)),
			);
		}

		return 1;
	}

	protected get showBleed(): boolean {
		return (
			AppStateModule.showBleed
			&& !this.photoObjectSelected
		);
	}

	protected get showDepth(): boolean {
		return !this.photoObjectSelected;
	}

	protected get showEditorOfferingFrame(): boolean {
		return !!this.offeringFrameModel?.required;
	}

	protected get showEditorTopToolbarAdditionalMenu(): boolean {
		return Object.keys(this.editorProductSettingsModel).length > 0;
	}

	protected get showOfferingOptionsSummaryToolbar(): boolean {
		if (
			this.areToolbarsVisible
			&& !this.isPreviewModeActive
		) {
			if (!this.photoObjectSelected) {
				return true;
			}

			if (
				!!this.photoObjectSelected
				&& !this.pageMultiplePhotoSupport
				&& !this.isLogoProduct
			) {
				return true;
			}
		}

		return false;
	}

	protected get templateSets(): TemplateSet[] {
		if (
			!this.pageTemplateModel
			|| !this.pageModel?.template
		) {
			return [];
		}

		return ThemeStateModule.getTemplateSets(
			this.pageModel.template,
			{
				marginAroundEdge: this.pageModel.templateMarginAroundEdge,
				marginBetweenPositions: this.pageModel.templateMarginBetweenPositions,
			},
		);
	}

	protected get textObjects(): PageObjectModel[] {
		return this.pageObjects.filter((object) => object.type === 'text');
	}

	private get textObjectSelected(): PageObjectModel | undefined {
		return this.textObjects.find((object) => !!object._selected);
	}

	private get textObjectSelectedForEdition(): PageObjectModel | undefined {
		return this.textObjects.find((object) => !!object._selectedForEdition);
	}

	private get themeModel(): ThemeModel | null {
		return ThemeStateModule.themeModel;
	}

	private get toolbarPhotoMaxPhotoSelection(): number {
		if (window.glPlatform === 'native') {
			return 1;
		}

		return Math.max(
			1,
			(this.themeModel?.maxphotos || 1) - this.projectPhotosSelected.length,
		);
	}

	private get viewportSize(): viewportTools.Viewport {
		if (
			this.isMobile
			&& this.textObjectSelected
		) {
			return this.visibleViewportSize;
		}
		if (
			this.isMobileStrict
			&& this.editorTooltips?.['unsupported-landscape']
		) {
			return this.visibleViewportSize;
		}

		return this.fullViewportSize;
	}

	protected get virtualPageModel(): PageModel | undefined {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'SingleLayer',
			)
		) {
			return this.pageModel;
		}

		if (
			this.photoObjectSelected
			&& this.pageModel
		) {
			const photoObjectSelected: PageObjectModel = {
				...this.photoObjectSelected,
			};

			if (photoObjectSelected.borderwidth) {
				photoObjectSelected.height += photoObjectSelected.borderwidth * 2;
				photoObjectSelected.width += photoObjectSelected.borderwidth * 2;
			}

			if (photoObjectSelected.rotate) {
				const rotatedBoundingBox = this.calculateRotatedBoundingBox(photoObjectSelected);

				return {
					...this.pageModel,
					height: rotatedBoundingBox.height,
					width: rotatedBoundingBox.width,
				};
			}

			return {
				...this.pageModel,
				height: photoObjectSelected.height,
				width: photoObjectSelected.width,
			};
		}

		return this.pageModel;
	}

	protected get virtualPageObjects(): EditorCanvasPageObjectsModel {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'SingleLayer',
			)
		) {
			return this.pageObjects;
		}

		if (this.photoObjectSelected) {
			let xAxis = 0;
			let yAxis = 0;

			if (this.photoObjectSelected.borderwidth) {
				xAxis += this.photoObjectSelected.borderwidth;
				yAxis += this.photoObjectSelected.borderwidth;
			}

			if (this.photoObjectSelected.rotate) {
				const rotatedBoundingBox = this.calculateRotatedBoundingBox({
					...this.photoObjectSelected,
					x_axis: xAxis,
					y_axis: yAxis,
				});

				return [
					{
						...this.photoObjectSelected,
						x_axis: -rotatedBoundingBox.x,
						y_axis: -rotatedBoundingBox.y,
					},
				];
			}

			return [
				{
					...this.photoObjectSelected,
					x_axis: xAxis,
					y_axis: yAxis,
				},
			];
		}

		return this.pageObjects;
	}

	@Ref('canvasContainer')
	private readonly canvasContainerElement!: HTMLDivElement;

	@Ref('doneAdjustingButton')
	private readonly doneAdjustingButtonComponent?: ButtonComponent;

	@Ref('editorCanvas')
	private readonly editorCanvasComponent!: EditorCanvasView;

	@Ref('editorCanvasCropContainer')
	private readonly editorCanvasCropContainerElement!: HTMLDivElement;

	@Ref('editorMainSection')
	private readonly editorMainSectionElement!: HTMLDivElement;

	@Ref('editorModuleCropGrid')
	private readonly editorModuleCropGridElement!: HTMLDivElement;

	@Ref('editorModuleCropGridWrapper')
	private readonly editorModuleCropGridWrapperElement!: HTMLDivElement;

	@Ref('editorModuleTopToolbar')
	private readonly editorModuleTopToolbarElement!: HTMLDivElement;

	@Ref('editorPagination')
	private readonly editorPaginationComponent!: EditorPaginationView;

	@Ref('editorPaginationContainer')
	private readonly editorPaginationContainerElement!: HTMLDivElement;

	@Ref('editorToolbar')
	private readonly editorToolbarComponent?: EditorToolbarView;

	protected canvasElement: HTMLCanvasElement = document.createElement('canvas');

	private closeExceededColorSelectionDialog?: () => void;

	private closeFrameDialog?: () => void;

	private closeFrameToolbar?: () => void;

	private closeLightboxDialog?: () => void;

	private closeLogoBackgroundDetectedDialog?: () => void;

	private closeLogoColorExceededDialog?: () => void;

	private closeToolbarPhotoToolbar?: () => void;

	private cropGridCroppingMouseDown = false;

	private cropGridMovingMouseDown = false;

	private cropGridMode?: EditorModuleCropMode;

	private cropGridX!: number;

	private cropGridY!: number;

	private cropPendingChanges?: boolean;

	private editorTextOptionsElement?: HTMLDivElement;

	private editorTextOptionsElementResizeObserver?: ResizeObserver;

	private editorToolbarActiveMode: EditorToolbarActiveMode | null = null;

	private editorToolbarResizeObserver?: ResizeObserver;

	private editorTooltipFillToSizeManuallyClosed?: boolean;

	private editorTooltips: Partial<Record<EditorTooltipKeys, ServiceOpenReturn<TooltipComponent<typeof EditorTooltipView>>>> = {};

	private forceScalingToZero = false;

	private fullViewportSize = viewportTools.fullViewport;

	private fullViewportUnwatch?: () => void;

	private isEyeDropperShown = false;

	private isMobile = mobileTools.isMobile;

	private isMobileStrict = mobileTools.isMobileStrict;

	private isMobileChanged?: boolean;

	private isMobileUnwatch?: () => void;

	private isMobileStrictUnwatch?: () => void;

	private isPreviewModeActive = false;

	private lightboxPhotoModel: ProjectPhotoModel | null = null;

	private lightboxTemplatePhotoPosition?: TemplatePhotoPosition;

	protected offeringOptionsSummaryToolbarFilterTags: OfferingOptionsSummaryToolbarFilterTags = [
		{
			tag: 'product-color',
			show: true,
		},
		{
			tag: 'print-color',
			show: false,
		},
		{
			tag: 'print-position',
			show: false,
		},
		{
			tag: 'finish',
			show: true,
		},
		{
			tag: 'orientation',
			show: true,
		},
		{
			tag: 'size',
			show: true,
		},
		{
			tag: 'frame',
			show: false,
		},
	];

	private previousPhotoObjectState?: PageObjectModel;

	private previousEditorToolbarWidth!: number;

	private screenOrientation = orientationUtils.orientation;

	private screenOrientationUnwatch?: () => void;

	private tapToContinueEditingTooltipTimeout?: NodeJS.Timeout;

	private visibleViewportSize = viewportTools.visibleViewport;

	private visibleViewportUnwatch?: () => void;

	private windowClickHandlers?: Partial<EditorModuleWindowClickHandlers>;

	protected zoomSliderMaxValue = 300;

	private zoomSliderValue = 100;

	private zoomSliderValueTooltipClose?: () => void;

	private zoomTooltip?: ServiceOpenReturn<TooltipComponent<typeof InputSliderComponent>>;

	protected zoomTooltipMouseDown = false;

	protected beforeDestroy(): void {
		ProductStateModule.deselectPageObjects();
		ProductStateModule.resetActivePage();
		AppStateModule.changeSupportVisibility(true);
		this.closeExceededColorSelectionDialog?.();
		this.closeFrameDialog?.();
		this.closeFrameToolbar?.();
		this.closeLightboxDialog?.();
		this.closeLogoBackgroundDetectedDialog?.();
		this.closeLogoColorExceededDialog?.();
		this.closeToolbarPhotoToolbar?.();
		this.fullViewportUnwatch?.();
		this.fullViewportUnwatch = undefined;
		this.isMobileUnwatch?.();
		this.isMobileUnwatch = undefined;
		this.isMobileStrictUnwatch?.();
		this.isMobileStrictUnwatch = undefined;
		this.editorTextOptionsElementResizeObserver?.disconnect();
		this.editorTextOptionsElementResizeObserver = undefined;
		this.editorToolbarResizeObserver?.disconnect();
		this.editorToolbarResizeObserver = undefined;
		this.screenOrientationUnwatch?.();
		this.screenOrientationUnwatch = undefined;
		this.visibleViewportUnwatch?.();
		this.visibleViewportUnwatch = undefined;
		this.zoomSliderValue = 100;
		this.zoomSliderValueTooltipClose?.();
		this.zoomSliderValueTooltipClose = undefined;
		this.zoomTooltip?.destroy();
		this.zoomTooltip = undefined;

		const editorTooltipKeys = Object.keys(this.editorTooltips) as EditorTooltipKeys[];

		// eslint-disable-next-line no-restricted-syntax
		for (const editorTooltipKey of editorTooltipKeys) {
			this.editorTooltips[editorTooltipKey]?.destroy();
			delete this.editorTooltips[editorTooltipKey];
		}

		viewportTools.preventScrollOnVirtualKeyboard(false);
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.removeWindowClickHandler();
	}

	protected created(): void {
		if (
			!ProductStateModule.getActivePage
			&& this.$route?.params.pagenr
		) {
			this.setActivePageByRoute();
		}
		if (
			this.$route?.query
			&& 'edit-colors' in this.$route.query
		) {
			this.editorToolbarActiveMode = 'edit-colors';
			this.$router.replace({
				...this.$route,
				name: (
					this.$route.name
					?? undefined
				),
				query: undefined,
			});
		}

		this.fullViewportUnwatch = viewportTools.watch(() => {
			this.fullViewportSize = viewportTools.fullViewport;
		});
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;

			if (
				this.isMobile
				&& this.isCropModeActive
			) {
				this.zoomTooltip?.close();
				this.zoomTooltip = undefined;
			} else if (this.isCropModeActive) {
				this.onIsCropModeActiveChange();
			}
		});
		this.isMobileStrictUnwatch = mobileTools.watch(
			() => {
				this.isMobileStrict = mobileTools.isMobileStrict;
			},
			'strict',
		);
		this.screenOrientationUnwatch = orientationUtils.watch(() => {
			this.screenOrientation = orientationUtils.orientation;
		});
		this.visibleViewportUnwatch = viewportTools.watch(
			() => {
				this.visibleViewportSize = viewportTools.visibleViewport;
			},
			'visible',
		);
		// Make sure theme data is loaded so we can show theme button when required
		Theme.fetchData(false);
	}

	protected mounted(): void {
		analytics.trackPageView(
			'editor',
			'Page editor',
		);
		analytics.trackEvent(
			'Edit page',
			{},
			{
				amplitude: true,
				gtm: false,
				moengage: true,
				segment: false,
			},
		);

		this.waitForCanvasContainerSize();

		if (this.editorToolbarComponent) {
			const editorToolbarElementRect = this.editorToolbarComponent.$el.getBoundingClientRect();
			this.previousEditorToolbarWidth = editorToolbarElementRect.width;
			this.editorToolbarResizeObserver = new ResizeObserver(this.onEditorToolbarResize);
			this.editorToolbarResizeObserver.observe(this.editorToolbarComponent.$el);
		}
	}

	@Watch('canvasElement')
	protected onCanvasElementChange(
		newCanvasElement: HTMLCanvasElement,
		oldCanvasElement: HTMLCanvasElement,
	): void {
		if (!oldCanvasElement.isSameNode(newCanvasElement)) {
			oldCanvasElement.remove();
		}
	}

	@Watch('cropPhotoZoomValue')
	protected onCropPhotoZoomValueChange(): void {
		if (this.zoomTooltip?.api) {
			const bodyComponent = this.zoomTooltip.api.bodyComponent();

			if (bodyComponent) {
				bodyComponent.value = this.cropPhotoZoomValue;
			}
		}
	}

	@Watch('isCropModeActive')
	protected onIsCropModeActiveChange(): void {
		if (this.isCropModeActive) {
			this.$nextTick(() => this.$forceCompute('canvasCropBoxHeight'));
			this.$nextTick(() => this.$forceCompute('canvasCropBoxWidth'));
			this.$nextTick(() => this.$forceCompute('cropPhotoObject'));
			this.$nextTick(() => this.$forceCompute('cropPhotoObjectStyles'));

			if (!this.isMobile) {
				this.$nextTick(() => {
					this.zoomTooltip?.destroy();
					this.zoomTooltip = this.$openTooltip({
						anchor: this.editorModuleCropGridWrapperElement,
						beforeClose: (event: ServiceEvent<any>) => {
							if (
								!this.isMobile
								&& this.isCropModeActive
							) {
								event.preventDefault();
							}
						},
						body: {
							component: InputSliderComponent,
							props: {
								min: 1,
								max: 100,
								showIncreaseDecreaseButtons: true,
								showValue: false,
								thumbColor: '--accent1',
								value: this.cropPhotoZoomValue,
							},
							listeners: {
								input: (value: number) => {
									const { photoObjectSelected } = this;

									if (photoObjectSelected) {
										const {
											cropheight: newCropHeight,
											cropwidth: newCropWidth,
											cropx: newCropX,
											cropy: newCropY,
										} = projectTools.zoomObject(
											photoObjectSelected,
											value,
										);

										if (
											newCropX + newCropWidth <= photoObjectSelected.maxwidth
											&& newCropY + newCropHeight <= photoObjectSelected.maxheight
											&& newCropX >= 0
											&& newCropY >= 0
											&& newCropWidth >= 0
											&& newCropHeight >= 0
										) {
											ProductStateModule.changePageObject({
												id: photoObjectSelected.id,
												cropheight: newCropHeight,
												cropwidth: newCropWidth,
												cropx: newCropX,
												cropy: newCropY,
											});
										}
									}
								},
								mousepress: this.onZoomSliderMouseDown,
								'slider-mousedown': this.onZoomSliderMouseDown,
								'slider-touchstart': this.onZoomSliderMouseDown,
							},
						},
						bodyStyles: {
							padding: '16px',
						},
						distance: 26,
						hasCloseButton: false,
						isModal: false,
						listeners: {
							close: () => {
								this.zoomTooltip = undefined;
							},
						},
						theme: 'light',
					});
				});
			}
		} else {
			this.zoomTooltip?.close();
		}
	}

	@Watch(
		'isMobile',
		{
			immediate: true,
		},
	)
	protected onIsMobileChange(): void {
		if (this.isMobile) {
			AppStateModule.changeSupportVisibility(false);
		} else {
			AppStateModule.changeSupportVisibility(true);
		}
	}

	@Watch(
		'isMobileStrict',
		{
			immediate: true,
		},
	)
	protected onIsMobileStrictChange(): void {
		if (this.isMobileStrict) {
			viewportTools.preventScrollOnVirtualKeyboard(true);
		} else {
			viewportTools.preventScrollOnVirtualKeyboard(false);
		}

		this.checkOrientation();
	}

	@Watch('isTapToContinueEditingVisible')
	protected onIsTapToContinueEditingVisibleChange(newValue?: boolean): void {
		if (this.isTapToContinueEditingVisible) {
			if (this.tapToContinueEditingTooltipTimeout) {
				clearTimeout(this.tapToContinueEditingTooltipTimeout);
				this.tapToContinueEditingTooltipTimeout = undefined;
			}

			this.editorTooltips['tap-to-continue']?.destroy();
			this.editorTooltips['tap-to-continue'] = this.$openTooltip({
				anchor: this.editorCanvasComponent.$el as HTMLDivElement,
				beforeClose: (event: ServiceEvent<any>): void => {
					if (this.isTapToContinueEditingVisible) {
						event.preventDefault();
					}
				},
				body: {
					component: EditorTooltipView,
					props: {
						content: this.$t('modules.editor.tapToContinueEditing'),
						hasCloseButton: false,
						type: EditorTooltipType.INFO,
					},
				},
				distance: 24,
				hasCloseButton: false,
				isModal: false,
				noArrow: true,
				noStyles: true,
				theme: 'light',
				tooltipStyles: {
					marginLeft: '20px',
					marginRight: '20px',
				},
			});

			if (typeof newValue !== 'undefined') {
				this.tapToContinueEditingTooltipTimeout = setTimeout(
					() => {
						this.editorTooltips['tap-to-continue']?.destroy();
						delete this.editorTooltips['tap-to-continue'];
						this.tapToContinueEditingTooltipTimeout = undefined;
					},
					3000,
				);
			}
		} else {
			this.editorTooltips['tap-to-continue']?.destroy();
			delete this.editorTooltips['tap-to-continue'];

			if (this.tapToContinueEditingTooltipTimeout) {
				clearTimeout(this.tapToContinueEditingTooltipTimeout);
				this.tapToContinueEditingTooltipTimeout = undefined;
			}

			this.onPageObjectsChange();
		}
	}

	@Watch('lightboxPhotoModel')
	protected onLightboxPhotoModelChange(): void {
		const { lightboxPhotoModel } = this;

		if (lightboxPhotoModel) {
			this.closeLightboxDialog?.();
			this.closeLightboxDialog = this.$openDialogNew({
				body: {
					component: ImportLightboxView,
					props: {
						showCloseButton: false,
						isModal: false,
						photoModel: this.lightboxPhotoModel || undefined,
						showToolsButtons: false,
					},
					styles: {
						height: '100%',
					},
				},
				footer: {
					buttons: [
						{
							color: 'secondary',
							icon: 'close',
							id: 'close',
							text: this.$t('views.importLightBox.closeButtonText'),
							click: () => {
								this.closeLightboxDialog?.();
							},
						},
						{
							icon: 'check',
							id: 'select',
							text: this.$t('views.importLightBox.selectButtonText'),
							click: () => {
								this
									.addPhotoToPage(
										lightboxPhotoModel,
										this.lightboxTemplatePhotoPosition,
									)
									.then(() => this.closeLightboxDialog?.());
							},
						},
					],
					classes: 'dialog-component-footer-buttons-small',
				},
				borderRadius: 0,
				listeners: {
					close: () => {
						this.lightboxTemplatePhotoPosition = undefined;
						this.lightboxPhotoModel = null;
						this.closeLightboxDialog = undefined;
					},
				},
				styles: {
					backgroundColor: '#000000',
					height: '100vh',
				},
				theme: 'dark',
				width: '100vw',
			}).close;
		} else {
			this.closeLightboxDialog?.();
			this.closeToolbarPhotoToolbar?.();
		}
	}

	@Watch(
		'pageObjects',
		{
			deep: true,
		},
	)
	@Watch(
		'pageModel',
		{
			deep: true,
		},
	)
	protected onPageObjectsChange(): void {
		if (
			this.hasFitToSizePhotoObject
			&& !this.textObjectSelected
			&& !this.textObjectSelectedForEdition
			&& this.pageModel?.bgcolor?.toLowerCase() === '#ffffff'
			&& !this.pageModel.bgpattern
			&& !this.editorTooltipFillToSizeManuallyClosed
		) {
			this.editorTooltips['fill-to-size']?.destroy();
			this.editorTooltips['fill-to-size'] = this.$openTooltip({
				anchor: this.editorCanvasComponent.$el as HTMLDivElement,
				beforeClose: (event: ServiceEvent<any>): void => {
					if (
						this.hasFitToSizePhotoObject
						&& this.pageModel?.bgcolor?.toLowerCase() === '#ffffff'
						&& !this.pageModel.bgpattern
						&& !this.isTapToContinueEditingVisible
					) {
						event.preventDefault();
					}
				},
				body: {
					component: EditorTooltipView,
					props: {
						content: this.$t('modules.editor.fillToSizeOrAddBackgroundWhiteEdges'),
						type: EditorTooltipType.WARNING,
					},
					listeners: {
						close: () => {
							this.editorTooltipFillToSizeManuallyClosed = true;
							this.editorTooltips['fill-to-size']?.destroy();
							delete this.editorTooltips['fill-to-size'];
						},
					},
				},
				distance: 24,
				hasCloseButton: false,
				isModal: false,
				tooltipStyles: {
					marginLeft: '20px',
					marginRight: '20px',
				},
			});
		} else if (this.editorTooltips['fill-to-size']) {
			this.editorTooltipFillToSizeManuallyClosed = false;
			this.editorTooltips['fill-to-size'].destroy();
			delete this.editorTooltips['fill-to-size'];
		}
	}

	@Watch(
		'photoObjects',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onPhotoObjectsChange(): void {
		if (
			this.pageModel
			&& this.photoObjects?.length
			&& this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'SingleLayer',
			)
		) {
			if (
				!ProductStateModule.getActivePage
				&& this.$route?.params.pagenr
			) {
				this.setActivePageByRoute();
			}

			ProductStateModule.selectPageObject({
				pageModel: this.pageModel,
				objectModelId: this.photoObjects[0].id,
			});
		}
	}

	@Watch('photoObjectSelected')
	protected onPhotoObjectSelectedChange(): void {
		if (
			this.isProjectSinglePage
			|| this.pageMultiplePhotoSupport
		) {
			if (this.photoObjectSelected) {
				this.zoomSliderValue = 100;

				if (
					!this.isMobile
					&& !this.editorModuleTopToolbarElement.parentElement?.isSameNode(this.editorMainSectionElement)
				) {
					this.editorMainSectionElement.prepend(this.editorModuleTopToolbarElement);
				}

				this.previousPhotoObjectState = {
					...this.photoObjectSelected,
				};
			} else {
				if (
					!this.isMobile
					&& !this.editorModuleTopToolbarElement.parentElement?.isSameNode(this.$el)
				) {
					this.$el.prepend(this.editorModuleTopToolbarElement);
				}

				this.previousPhotoObjectState = undefined;
			}
		}
	}

	@Watch('$route')
	protected onRouteChange(
		to: Route,
		from: Route,
	): void {
		if (
			to.matched
			&& from.matched
			&& to.matched[0].path === from.matched[0].path
			&& to.params.pagenr
			&& from.params.pagenr
			&& to.params.pagenr !== from.params.pagenr
		) {
			this.setActivePageByRoute();
		}
	}

	@Watch('screenOrientation')
	protected onScreenOrientationChange(): void {
		this.checkOrientation();
	}

	@Watch('textObjectSelected')
	protected onTextObjectSelectedChange(): void {
		if (this.isMobile) {
			this.$nextTick(() => {
				this.editorTextOptionsElement = this.editorPaginationContainerElement.parentElement?.querySelector<HTMLDivElement>(':scope > .editor-text-options-view') || undefined;

				if (
					this.textObjectSelected
					&& !this.editorTextOptionsElement
				) {
					requestAnimationFrame(() => this.onTextObjectSelectedChange());
					return;
				}

				if (this.editorTextOptionsElement) {
					this.editorTextOptionsElementResizeObserver?.disconnect();
					this.editorTextOptionsElementResizeObserver = new ResizeObserver(() => requestAnimationFrame(() => this.forceComputeScaling()));
					this.editorTextOptionsElementResizeObserver.observe(this.editorTextOptionsElement);
					this.forceComputeScaling();
				} else {
					this.editorTextOptionsElementResizeObserver?.disconnect();
					this.editorTextOptionsElementResizeObserver = undefined;
					this.forceComputeScaling();
				}
			});
		}
	}

	@Watch('viewportSize')
	protected onViewportSizeChange(): void {
		requestAnimationFrame(() => this.forceComputeScaling());
	}

	@Watch('zoomSliderValue')
	protected onZoomSliderValueChange(): void {
		requestAnimationFrame(() => this.$forceCompute('computedStyles'));
	}

	private addWindowClickHandler(type: EditorModuleWindowClickHandlerType): void {
		if (!this.windowClickHandlers) {
			this.windowClickHandlers = {};
		}

		if (type === 'toolbar') {
			this.windowClickHandlers.toolbar = this.onToolbarWindowClick;
			window.addEventListener(
				'click',
				this.windowClickHandlers.toolbar,
			);
		}
	}

	/**
	 * TODO: move to a helper to be able to reuse it on several places,
	 * since right now this is a copy from the `EditorInteractiveView`
	 * component logic
	 */
	private calculateRotatedBoundingBox(objectModel: EditorInteractivePageObjectModel): {
		height: number;
		width: number;
		x: number;
		y: number;
	} {
		const {
			height,
			x_axis: left,
			rotate,
			y_axis: top,
			width,
		} = objectModel;

		// Convert the rotation angle to radians
		const angle = rotate * (Math.PI / 180);

		// Calculate the center of the rectangle
		const cx = left + width / 2;
		const cy = top + height / 2;

		// Calculate the coordinates of the corners before rotation
		const corners = [
			{
				x: left,
				y: top,
			}, // top-left
			{
				x: left + width,
				y: top,
			}, // top-right
			{
				x: left,
				y: top + height,
			}, // bottom-left
			{
				x: left + width,
				y: top + height,
			}, // bottom-right
		];

		// Rotate each corner around the center
		const rotatedCorners = corners.map(({ x, y }) => {
			const dx = x - cx;
			const dy = y - cy;

			return {
				x: cx + (dx * Math.cos(angle) - dy * Math.sin(angle)),
				y: cy + (dx * Math.sin(angle) + dy * Math.cos(angle)),
			};
		});

		// Find the new bounding box
		const xs = rotatedCorners.map((corner) => corner.x);
		const ys = rotatedCorners.map((corner) => corner.y);

		const minX = Math.min(...xs);
		const maxX = Math.max(...xs);
		const minY = Math.min(...ys);
		const maxY = Math.max(...ys);

		return {
			height: maxY - minY,
			width: maxX - minX,
			x: minX,
			y: minY,
		};
	}

	private checkOrientation(): void {
		if (
			this.isMobileStrict
			&& this.screenOrientation === 'landscape'
		) {
			if (!this.editorTooltips) {
				this.editorTooltips = {};
			}

			if (!this.$el) {
				this.$nextTick(() => {
					requestAnimationFrame(this.checkOrientation);
				});
				return;
			}

			this.editorTooltips['unsupported-landscape']?.destroy();
			this.editorTooltips['unsupported-landscape'] = this.$openTooltip({
				anchor: this.$el as HTMLDivElement,
				body: {
					component: EditorTooltipView,
					props: {
						content: this.$t('modules.editor.unsupportedOrientation.landscape'),
						hasCloseButton: false,
						type: EditorTooltipType.INFO,
					},
				},
				distance: '50%',
				hasCloseButton: false,
				noArrow: true,
				noStyles: true,
				theme: 'light',
				tooltipStyles: {
					marginLeft: '20px',
					marginRight: '20px',
				},
			});
		} else {
			this.editorTooltips?.['unsupported-landscape']?.destroy();
		}
	}

	private closeEditor(): void {
		ProductStateModule.deselectPageObjects();
		ProductStateModule.resetHistory();

		if (this.$router) {
			navigate.back(
				{},
				() => {
					window.App.router.openProduct(
						ProductStateModule.productId as number,
						true,
					);
				},
			);
		}
	}

	private forceComputeScaling(): void {
		if (!this.forceScalingToZero) {
			this.forceScalingToZero = true;
		}

		this.$nextTick(() => {
			/**
			 * Workaround to perform the force scaling three times to ensure
			 * the correct scaling is applied.
			 */
			for (let times = 0; times < 3; times += 1) {
				this.$nextTick(() => this.$forceCompute('editorBoxMaxWidth'));
				this.$nextTick(() => this.$forceCompute('editorBoxMaxHeight'));
				this.$nextTick(() => this.$forceCompute('fullPageHeight'));
				this.$nextTick(() => this.$forceCompute('fullPageWidth'));
				this.$nextTick(() => this.$forceCompute('scaling'));
				this.$nextTick(() => this.$forceCompute('offeringFrameScaling'));
			}

			this.forceScalingToZero = false;
		});
	}

	protected onAddPhoto(templatePosition?: TemplatePhotoPosition): void {
		const propertyUnwatchers: (() => void)[] = [];

		const {
			api: apiToolbar,
			close: closeToolbar,
		} = this.$openToolbar({
			body: {
				component: ToolbarPhotoView,
				props: {
					importChannels: this.importChannels,
					offeringType: this.currentOfferingModel?.type || 'photo',
					pageObjects: this.pageObjects,
					photosQueued: this.projectPhotosQueued,
					productPhotos: this.projectPhotosSelected,
					pickerMax: this.toolbarPhotoMaxPhotoSelection,
					pickerRemoveUnselected: false,
					pickerShowSelection: false,
					isVisible: true,
					showLightBox: (
						window.glPlatform !== 'native'
						&& !this.isLogoProduct
					),
					vectorOnly: !!(
						this.currentOfferingModel?.previewColorSpectrum
						&& !PRINT_COLOR_SPECTRUM_FULL.includes(this.currentOfferingModel.previewColorSpectrum)
					),
				},
				listeners: {
					close: () => {
						closeToolbar();
					},
					externalUploadCompleted: (photoIds: ProjectPhotoModel['id'][]) => {
						if (photoIds.length === 1) {
							const photoModel = PhotosModule.getById(photoIds[0]);

							if (photoModel) {
								this.addPhotoToPage(
									photoModel,
									templatePosition,
								);
								closeToolbar();
							}
						}
					},
					openLightBox: (photoModel: ProjectPhotoModel) => {
						if (
							(
								window.glPlatform === 'native'
								&& typeof photoModel.id === 'string'
							)
							|| this.isLogoProduct
						) {
							// Photo's selected in the native app picker do not have to be shown in the lightbox anymore
							this.addPhotoToPage(
								photoModel,
								templatePosition,
							);
							closeToolbar();
						} else {
							this.lightboxTemplatePhotoPosition = templatePosition;
							this.lightboxPhotoModel = photoModel;
						}
					},
					selectPhoto: (
						photoModel: ProjectPhotoModel,
						skipLightBox: boolean,
					) => {
						if (skipLightBox) {
							this.addPhotoToPage(
								photoModel,
								templatePosition,
							);
							closeToolbar();
						} else {
							this.lightboxTemplatePhotoPosition = templatePosition;
							this.lightboxPhotoModel = photoModel;
						}
					},
				},
			},
			listeners: {
				close: () => {
					// eslint-disable-next-line no-restricted-syntax
					for (const propertyUnwatcher of propertyUnwatchers) {
						propertyUnwatcher();
					}

					this.closeToolbarPhotoToolbar = undefined;
				},
			},
			theme: this.internalTheme,
		});
		this.closeToolbarPhotoToolbar = closeToolbar;
		const propertyUpdator = (
			bodyComponentProperty: keyof PublicOptionalNonFunctionProps<ToolbarPhotoView>,
			editorProperty: string,
		) => {
			const bodyComponent = apiToolbar.bodyComponent();

			if (
				bodyComponent
				&& bodyComponentProperty in bodyComponent
				&& editorProperty in this
			) {
				// @ts-ignore
				bodyComponent[bodyComponentProperty] = this[editorProperty];
			}
		};
		propertyUnwatchers.push(
			this.$watch(
				'pageObjects',
				() => {
					propertyUpdator(
						'pageObjects',
						'pageObjects',
					);
				},
				{
					deep: true,
				},
			),
		);
		propertyUnwatchers.push(
			this.$watch(
				'projectPhotosQueued',
				() => {
					propertyUpdator(
						'photosQueued',
						'projectPhotosQueued',
					);
				},
				{
					deep: true,
				},
			),
		);
		propertyUnwatchers.push(
			this.$watch(
				'projectPhotosSelected',
				() => {
					propertyUpdator(
						'productPhotos',
						'projectPhotosSelected',
					);
				},
				{
					deep: true,
				},
			),
		);
		propertyUnwatchers.push(
			this.$watch(
				'toolbarPhotoMaxPhotoSelection',
				() => {
					propertyUpdator(
						'pickerMax',
						'toolbarPhotoMaxPhotoSelection',
					);
				},
				{
					deep: true,
				},
			),
		);
	}

	protected onAddText(templatePosition: TemplateTextPosition): void {
		if (this.pageModel) {
			const { pageModel } = this;
			TemplatePosition
				.fillTextPosition(
					pageModel,
					templatePosition,
					'',
					{
						force: true,
					},
				)
				.then((newObjectModel) => {
					ProductStateModule.changePageObject({
						id: newObjectModel.id,
						_selected: true,
						_selectedForEdition: false,
					});
				})
				.catch((err: Error) => {
					if (err.message === ERRORS_LOAD_FONT) {
						const { close: closeError } = this.$openDialogNew({
							header: {
								title: this.$t('dialogHeaderError'),
							},
							body: {
								content: this.$t('dialogTextLoadError'),
							},
							footer: {
								buttons: [
									{
										id: 'accept',
										text: this.$t('dialogButtonOk'),
										click: () => {
											closeError();
										},
									},
								],
							},
						});
					}

					// No further action required
				});

			analytics.trackEvent(
				'Add text',
				{
					category: 'Template',
				},
			);
		}
	}

	private addPhotoToPage(
		photoModelToAdd: ProjectPhotoModel,
		templatePhotoPosition?: TemplatePhotoPosition,
	): Promise<void> {
		this.$openLoaderDialog();
		AppStateModule.setHeavyLoad();

		return new Promise((resolve) => {
			requestAnimationFrame(() => {
				ProductStateModule
					.selectPhoto(photoModelToAdd)
					.then((photoModel) => {
						if (!this.pageModel) {
							throw new Error('Missing required page model');
						}

						if (!templatePhotoPosition) {
							const photoPositions = this.pageTemplatePositionsAvailable.filter((templatePosition) => templatePosition.type == 'photo') as TemplatePhotoPosition[];

							if (photoPositions.length) {
								[templatePhotoPosition] = photoPositions;
							}
						}

						if (
							!templatePhotoPosition
							&& this.pageTemplateModel?.dynamic
							&& this.photos.length <= 3
							&& !this.pageModel.customLayout
						) {
							const { templateSet } = ProductStateModule.getPageTemplateSet(
								this.pageModel,
								undefined,
								[
									...this.photos as ProjectPhotoModel[],
									photoModelToAdd,
								],
							);

							if (templateSet) {
								return ProductState.changeTemplate(
									this.pageModel,
									templateSet.id,
									{
										objectTypes: ['photo'],
										photoModels: [
											photoModelToAdd,
										],
									},
								);
							}
						}

						const pageObjectModel = ProductStateModule.getSelectedPageObject;

						if (
							pageObjectModel
							&& pageObjectModel.type == 'photo'
						) {
							const swap = Boolean(
								this.projectModel
								&& !OfferingGroups(
									this.projectModel.group,
									'BookTypes',
								),
							);

							return changeObjectPhoto(
								pageObjectModel,
								photoModelToAdd,
								templatePhotoPosition,
								swap,
							)
								.then(() => {
									analytics.trackEvent(
										'Swap photo',
										{
											category: 'Page object',
											label: 'Button',
										},
									);
									ProductStateModule.pushHistory();
								})
								.catch((err) => {
									this.$openErrorDialog({
										body: {
											content: err.message,
										},
									});
								});
						}

						const trackImageAddition = () => {
							// Save user action to analytics
							const eventProperties: {
								colorsAvailable?: number;
								colorsInLogo?: number;
								colorsExceeded?: boolean;
								type: OfferingModel['type'];
							} = {
								type: photoModel.type,
							};

							if (
								this.isLogoProduct
								&& this.currentOfferingModel
								&& this.photoObjects.length
							) {
								const { currentOfferingModel } = this;
								const logoColorsCheckResult = logoColorsTools.check(
									currentOfferingModel,
									this.photoObjects[0],
								);

								/**
								 * This will be changed in an upcoming PR where the logo colors
								 * for the foreground and background will be temporary stored
								 * in memory in the object model
								 */
								if (
									logoColorsCheckResult.logoColors === 0
									&& currentOfferingModel.previewColorSpectrum
									&& !PRINT_COLOR_SPECTRUM_FULL.includes(currentOfferingModel.previewColorSpectrum)
								) {
									const photoObjectsUnwatch = this.$watch(
										'photoObjects',
										() => {
											trackImageAddition();
											photoObjectsUnwatch();
										},
										{
											deep: true,
										},
									);
									return;
								}

								eventProperties.colorsAvailable = logoColorsCheckResult.offeringColors;
								eventProperties.colorsInLogo = logoColorsCheckResult.logoColors;
								eventProperties.colorsExceeded = !logoColorsCheckResult.valid;
							}

							analytics.trackEvent(
								'Add image',
								eventProperties,
							);
						};

						if (templatePhotoPosition) {
							return TemplatePosition
								.fillPhotoPosition(
									this.pageModel,
									templatePhotoPosition,
									photoModel,
									{
										addProcessing: true,
									},
								)
								.then(() => {
									trackImageAddition();
									ProductStateModule.pushHistory();
								});
						}

						if (this.pageTemplateModel?.transformable) {
							const scale = Math.max(
								1,
								photoModel.full_width / (this.pageModel.width / 2),
								photoModel.full_height / (this.pageModel.height / 2),
							);
							const objectWidth = photoModel.full_width / scale;
							const objectHeight = photoModel.full_height / scale;
							const maxz = ProductStateModule.getPageMaxZ(this.pageModel);

							return ProductStateModule
								.addPageObject({
									pageId: this.pageModel.id,
									data: {
										_processing: true,
										x_axis: (this.pageModel.width - objectWidth) / 2,
										y_axis: (this.pageModel.height - objectHeight) / 2,
										z_axis: maxz + 1,
										width: objectWidth,
										height: objectHeight,
										maxwidth: photoModel.full_width,
										maxheight: photoModel.full_height,
										type: 'photo',
										cropwidth: photoModel.full_width,
										cropheight: photoModel.full_height,
										cropx: 0,
										cropy: 0,
										rotate: 0,
										photoid: photoModel.id,
									},
								})
								.then(() => {
									trackImageAddition();
									ProductStateModule.pushHistory();
								});
						}

						throw new Error('No room for photo');
					})
					.then(async () => {
						const pageObject = this.pageObjects.find((object) => (
							object.photoid === photoModelToAdd.id
							&& object._processing
						));

						if (
							this.isLogoProduct
							&& this.offeringFrameModel
							&& pageObject?.ext === 'svg'
						) {
							await this.processVectorLogo();
							this.convertLogoColors();
							await this.checkLogoBackground();
							this.checkLogoColors('exceeded-color-selection');
						}

						if (pageObject?.id) {
							ProductStateModule.changePageObject({
								id: pageObject.id,
								_processing: false,
							});
						}
					})
					.catch((error: Error) => {
						if (error.message === 'No room for photo') {
							// Swallow error: no action required
							if (typeof window.glBugsnagClient !== 'undefined') {
								window.glBugsnagClient.notify(
									error,
									(event) => {
										event.severity = 'warning';
									},
								);
							}
						} else {
							throw error;
						}
					})
					.finally(() => {
						AppStateModule.unsetHeavyLoad();
						this.$closeLoaderDialog();
						resolve();
					});
			});
		});
	}

	private checkFreeformLayout(partialObjectModel: OptionalExceptFor<EditorInteractivePageObjectModel, 'id'>): void {
		if (
			(
				'x_axis' in partialObjectModel
				|| 'y_axis' in partialObjectModel
			)
			&& this.pageModel
			&& !this.pageModel.customLayout
		) {
			/**
			 * The user is moving the photos on the page, thereby indicating it wants to freeform
			 * and we disable the automatic template switch when new photos are added to the page
			 */
			ProductStateModule.changePage({
				id: this.pageModel.id,
				customLayout: true,
			});
		}
	}

	protected onCanvasReady(canvasElement: HTMLCanvasElement): void {
		this.canvasElement = canvasElement;
	}

	protected onCropGridMouseDown(event: MouseEvent | TouchEvent): void {
		const { photoObjectSelected } = this;

		if (
			this.isCropModeActive
			&& photoObjectSelected
		) {
			const editorModuleCropGridElementRect = this.editorModuleCropGridElement.getBoundingClientRect();
			const cropGridHandle = (
				this.isMobile
					? 16
					: 4
			);
			const cornerHandle = 28;
			let finalEvent: MouseEvent | Touch | Touch[];

			if ('touches' in event) {
				if (event.touches.length == 2) {
					finalEvent = Array.from(event.touches);
				} else {
					// eslint-disable-next-line prefer-destructuring
					finalEvent = event.touches[0];
				}
			} else {
				finalEvent = event;
			}

			const isLeftHandle = (
				handle = cropGridHandle,
				skipRotation = false,
			): boolean => {
				if (!skipRotation) {
					if (photoObjectSelected.rotate === 90) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isTopHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 180) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isRightHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 270) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isBottomHandle(
							handle,
							true,
						);
					}
				}

				return (
					!Array.isArray(finalEvent)
					&& finalEvent.clientX <= editorModuleCropGridElementRect.left + handle
				);
			};
			const isRightHandle = (
				handle = cropGridHandle,
				skipRotation = false,
			): boolean => {
				if (!skipRotation) {
					if (photoObjectSelected.rotate === 90) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isBottomHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 180) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isLeftHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 270) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isTopHandle(
							handle,
							true,
						);
					}
				}

				return (
					!Array.isArray(finalEvent)
					&& finalEvent.clientX >= editorModuleCropGridElementRect.right - handle
				);
			};
			const isTopHandle = (
				handle = cropGridHandle,
				skipRotation = false,
			): boolean => {
				if (!skipRotation) {
					if (photoObjectSelected.rotate === 90) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isRightHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 180) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isBottomHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 270) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isLeftHandle(
							handle,
							true,
						);
					}
				}

				return (
					!Array.isArray(finalEvent)
					&& finalEvent.clientY <= editorModuleCropGridElementRect.top + handle
				);
			};
			const isBottomHandle = (
				handle = cropGridHandle,
				skipRotation = false,
			): boolean => {
				if (!skipRotation) {
					if (photoObjectSelected.rotate === 90) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isLeftHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 180) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isTopHandle(
							handle,
							true,
						);
					}
					if (photoObjectSelected.rotate === 270) {
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						return isRightHandle(
							handle,
							true,
						);
					}
				}

				return (
					!Array.isArray(finalEvent)
					&& finalEvent.clientY >= editorModuleCropGridElementRect.bottom - handle
				);
			};
			const isCornerHandle = (
				(
					isLeftHandle(cornerHandle)
					|| isRightHandle(cornerHandle)
				)
				&& (
					isTopHandle(cornerHandle)
					|| isBottomHandle(cornerHandle)
				)
			);
			const isPinch = Array.isArray(finalEvent);
			event.preventDefault();

			if (!Array.isArray(finalEvent)) {
				this.cropGridX = finalEvent.clientX;
				this.cropGridY = finalEvent.clientY;
			} else {
				this.cropGridX = finalEvent[1].clientX - finalEvent[0].clientX;
				this.cropGridY = finalEvent[1].clientY - finalEvent[0].clientY;
			}

			if (
				isLeftHandle()
				|| isRightHandle()
				|| isTopHandle()
				|| isBottomHandle()
				|| isPinch
			) {
				this.cropGridCroppingMouseDown = true;

				if (
					isRightHandle(cornerHandle)
					&& isBottomHandle(cornerHandle)
					&& isCornerHandle
				) {
					this.cropGridMode = 'bottomright';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'nesw-resize';
					} else {
						document.body.style.cursor = 'nwse-resize';
					}
				} else if (
					isLeftHandle(cornerHandle)
					&& isBottomHandle(cornerHandle)
					&& isCornerHandle
				) {
					this.cropGridMode = 'bottomleft';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'nwse-resize';
					} else {
						document.body.style.cursor = 'nesw-resize';
					}
				} else if (
					isRightHandle(cornerHandle)
					&& isTopHandle(cornerHandle)
					&& isCornerHandle
				) {
					this.cropGridMode = 'topright';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'nwse-resize';
					} else {
						document.body.style.cursor = 'nesw-resize';
					}
				} else if (
					isLeftHandle(cornerHandle)
					&& isTopHandle(cornerHandle)
					&& isCornerHandle
				) {
					this.cropGridMode = 'topleft';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'nesw-resize';
					} else {
						document.body.style.cursor = 'nwse-resize';
					}
				} else if (isTopHandle()) {
					this.cropGridMode = 'top';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'ew-resize';
					} else {
						document.body.style.cursor = 'ns-resize';
					}
				} else if (isRightHandle()) {
					this.cropGridMode = 'right';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'ns-resize';
					} else {
						document.body.style.cursor = 'ew-resize';
					}
				} else if (isBottomHandle()) {
					this.cropGridMode = 'bottom';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'ew-resize';
					} else {
						document.body.style.cursor = 'ns-resize';
					}
				} else if (isLeftHandle()) {
					this.cropGridMode = 'left';

					if (
						photoObjectSelected.rotate === 90
						|| photoObjectSelected.rotate === 270
					) {
						document.body.style.cursor = 'ns-resize';
					} else {
						document.body.style.cursor = 'ew-resize';
					}
				} else if (isPinch) {
					this.cropGridMode = 'pinch';
				}
			} else {
				this.cropGridMovingMouseDown = true;
				document.body.style.cursor = 'move';
			}

			window.addEventListener(
				'mouseup',
				this.onWindowMouseUp,
			);
			window.addEventListener(
				'touchend',
				this.onWindowMouseUp,
			);
			window.addEventListener(
				'mousemove',
				this.onCropGridMouseMove,
			);
			window.addEventListener(
				'touchmove',
				this.onCropGridMouseMove,
				{
					passive: false,
				},
			);
			this.removeWindowClickHandler('toolbar');
		}
	}

	protected onCropGridMouseMove(event: MouseEvent | TouchEvent): void {
		let finalEvent: MouseEvent | Touch | Touch[];
		const { photoObjectSelected } = this;

		if ('touches' in event) {
			if (event.touches.length === 2) {
				finalEvent = Array.from(event.touches);

				if (this.cropGridMode !== 'pinch') {
					this.cropGridMode = 'pinch';
					this.cropGridMovingMouseDown = false;
					this.cropGridCroppingMouseDown = true;
					this.cropGridX = finalEvent[1].clientX - finalEvent[0].clientX;
					this.cropGridY = finalEvent[1].clientY - finalEvent[0].clientY;
				}
			} else {
				// eslint-disable-next-line prefer-destructuring
				finalEvent = event.touches[0];

				if (this.cropGridMode === 'pinch') {
					this.cropGridMode = undefined;
					this.cropGridMovingMouseDown = true;
					this.cropGridCroppingMouseDown = false;
					this.cropGridX = finalEvent.clientX;
					this.cropGridY = finalEvent.clientY;
				}
			}
		} else {
			finalEvent = event;
		}

		if (
			this.cropGridCroppingMouseDown
			&& photoObjectSelected
		) {
			event.preventDefault();
			let xDifference: number | undefined;
			let yDifference: number | undefined;
			let pinchDifference: number | undefined;

			if (
				!Array.isArray(finalEvent)
				&& typeof this.cropGridX === 'number'
			) {
				if (photoObjectSelected.rotate === 90) {
					if (
						this.cropGridMode === 'bottomright'
						|| this.cropGridMode === 'bottom'
						|| this.cropGridMode === 'bottomleft'
					) {
						xDifference = finalEvent.clientX - this.cropGridX;
					} else if (
						this.cropGridMode === 'topright'
						|| this.cropGridMode === 'top'
						|| this.cropGridMode === 'topleft'
					) {
						xDifference = this.cropGridX - finalEvent.clientX;
					}
				} else if (photoObjectSelected.rotate === 270) {
					if (
						this.cropGridMode === 'bottomright'
						|| this.cropGridMode === 'bottom'
						|| this.cropGridMode === 'bottomleft'
					) {
						xDifference = finalEvent.clientX - this.cropGridX;
					} else if (
						this.cropGridMode === 'topright'
						|| this.cropGridMode === 'top'
						|| this.cropGridMode === 'topleft'
					) {
						xDifference = this.cropGridX - finalEvent.clientX;
					}
				} else if (
					this.cropGridMode === 'bottomright'
					|| this.cropGridMode === 'right'
					|| this.cropGridMode === 'topright'
				) {
					xDifference = finalEvent.clientX - this.cropGridX;
				} else if (
					this.cropGridMode === 'bottomleft'
					|| this.cropGridMode === 'left'
					|| this.cropGridMode === 'topleft'
				) {
					xDifference = this.cropGridX - finalEvent.clientX;
				}
			}

			if (
				!Array.isArray(finalEvent)
				&& typeof this.cropGridY === 'number'
			) {
				if (photoObjectSelected.rotate === 90) {
					if (
						this.cropGridMode === 'topleft'
						|| this.cropGridMode === 'left'
						|| this.cropGridMode === 'bottomleft'
					) {
						yDifference = this.cropGridY - finalEvent.clientY;
					} else if (
						this.cropGridMode === 'topright'
						|| this.cropGridMode === 'right'
						|| this.cropGridMode === 'bottomright'
					) {
						yDifference = finalEvent.clientY - this.cropGridY;
					}
				} else if (photoObjectSelected.rotate === 270) {
					if (
						this.cropGridMode === 'topleft'
						|| this.cropGridMode === 'left'
						|| this.cropGridMode === 'bottomleft'
					) {
						yDifference = this.cropGridY - finalEvent.clientY;
					} else if (
						this.cropGridMode === 'topright'
						|| this.cropGridMode === 'right'
						|| this.cropGridMode === 'bottomright'
					) {
						yDifference = finalEvent.clientY - this.cropGridY;
					}
				} else if (
					this.cropGridMode === 'topright'
					|| this.cropGridMode === 'top'
					|| this.cropGridMode === 'topleft'
				) {
					yDifference = this.cropGridY - finalEvent.clientY;
				} else if (
					this.cropGridMode === 'bottomright'
					|| this.cropGridMode === 'bottom'
					|| this.cropGridMode === 'bottomleft'
				) {
					yDifference = finalEvent.clientY - this.cropGridY;
				}
			}

			if (
				this.cropGridMode === 'pinch'
				&& Array.isArray(finalEvent)
			) {
				xDifference = finalEvent[1].clientX - finalEvent[0].clientX;
				yDifference = finalEvent[1].clientY - finalEvent[0].clientY;
				pinchDifference = Math.hypot(
					xDifference,
					yDifference,
				);
			}

			if (
				!xDifference
				&& !yDifference
				&& !pinchDifference
			) {
				return;
			}

			const newObjectModel: OptionalExceptFor<PageObjectModel, 'id'> = {
				id: photoObjectSelected.id,
			};
			let objectXDifference: number | undefined;
			let objectYDifference: number | undefined;

			if (photoObjectSelected.rotate === 90) {
				objectXDifference = yDifference;

				if (typeof xDifference !== 'undefined') {
					objectYDifference = -xDifference;
				}
			} else if (photoObjectSelected.rotate === 180) {
				if (typeof xDifference !== 'undefined') {
					objectXDifference = -xDifference;
				}
				if (typeof yDifference !== 'undefined') {
					objectYDifference = -yDifference;
				}
			} else if (photoObjectSelected.rotate === 270) {
				if (typeof yDifference !== 'undefined') {
					objectXDifference = -yDifference;
				}

				objectYDifference = xDifference;
			} else {
				objectXDifference = xDifference;
				objectYDifference = yDifference;
			}

			if (typeof pinchDifference === 'undefined') {
				let difference: number;

				if (
					typeof objectXDifference !== 'undefined'
					&& typeof objectYDifference !== 'undefined'
					&& Math.abs(objectXDifference) > Math.abs(objectYDifference)
				) {
					difference = objectXDifference;
				} else if (
					typeof objectXDifference !== 'undefined'
					&& typeof objectYDifference !== 'undefined'
					&& Math.abs(objectXDifference) < Math.abs(objectYDifference)
				) {
					difference = objectYDifference;
				} else {
					difference = objectXDifference || objectYDifference || 0;
				}

				if (difference === objectXDifference) {
					const objectRatio = photoObjectSelected.width / photoObjectSelected.height;
					objectYDifference = objectXDifference / objectRatio;
				} else if (difference === objectYDifference) {
					const objectRatio = photoObjectSelected.height / photoObjectSelected.width;
					objectXDifference = objectYDifference / objectRatio;
				}
			} else if (
				typeof pinchDifference !== 'undefined'
				&& typeof objectXDifference !== 'undefined'
				&& typeof objectYDifference !== 'undefined'
			) {
				objectXDifference = Math.abs(objectXDifference);
				objectYDifference = Math.abs(objectYDifference);
				const difference = Math.max(
					objectXDifference,
					objectYDifference,
				);

				if (difference === objectXDifference) {
					const objectRatio = photoObjectSelected.width / photoObjectSelected.height;
					objectYDifference = objectXDifference / objectRatio;
				} else if (difference === objectYDifference) {
					const objectRatio = photoObjectSelected.height / photoObjectSelected.width;
					objectXDifference = objectYDifference / objectRatio;
				}
			}

			let differenceXScaled: number;
			let differenceYScaled: number;

			if (this.cropGridMode !== 'pinch') {
				differenceXScaled = ((objectXDifference || objectYDifference || 0) * 2) / this.scaling;
				differenceYScaled = ((objectYDifference || objectXDifference || 0) * 2) / this.scaling;
			} else {
				differenceXScaled = ((objectXDifference || objectYDifference || 0) / 2) * this.scaling;
				differenceYScaled = ((objectYDifference || objectXDifference || 0) / 2) * this.scaling;
			}

			if (this.cropGridMode === 'bottomright') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
			} else if (this.cropGridMode === 'bottomleft') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
				newObjectModel.cropx = photoObjectSelected.cropx - differenceXScaled;
			} else if (this.cropGridMode === 'topright') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
				newObjectModel.cropy = photoObjectSelected.cropy - differenceYScaled;
			} else if (this.cropGridMode === 'topleft') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
				newObjectModel.cropx = photoObjectSelected.cropx - differenceXScaled;
				newObjectModel.cropy = photoObjectSelected.cropy - differenceYScaled;
			} else if (this.cropGridMode === 'right') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
				newObjectModel.cropy = photoObjectSelected.cropy - (differenceYScaled / 2);
			} else if (this.cropGridMode === 'left') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
				newObjectModel.cropx = photoObjectSelected.cropx - differenceXScaled;
				newObjectModel.cropy = photoObjectSelected.cropy - (differenceYScaled / 2);
			} else if (this.cropGridMode === 'bottom') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
				newObjectModel.cropx = photoObjectSelected.cropx - (differenceXScaled / 2);
			} else if (this.cropGridMode === 'top') {
				newObjectModel.cropheight = photoObjectSelected.cropheight + differenceYScaled;
				newObjectModel.cropwidth = photoObjectSelected.cropwidth + differenceXScaled;
				newObjectModel.cropx = photoObjectSelected.cropx - (differenceXScaled / 2);
				newObjectModel.cropy = photoObjectSelected.cropy - differenceYScaled;
			} else if (
				this.cropGridMode === 'pinch'
				&& typeof pinchDifference !== 'undefined'
			) {
				const previousPinchDifference = Math.hypot(
					this.cropGridX || 0,
					this.cropGridY || 0,
				);

				if (pinchDifference > previousPinchDifference) {
					// Must be zooming in from the center of the image
					newObjectModel.cropheight = photoObjectSelected.cropheight - (differenceYScaled * 2);
					newObjectModel.cropwidth = photoObjectSelected.cropwidth - (differenceXScaled * 2);
					newObjectModel.cropx = photoObjectSelected.cropx + differenceXScaled;
					newObjectModel.cropy = photoObjectSelected.cropy + differenceYScaled;
				} else if (pinchDifference < previousPinchDifference) {
					// Must be zooming out from the center of the image
					newObjectModel.cropheight = photoObjectSelected.cropheight + (differenceYScaled * 2);
					newObjectModel.cropwidth = photoObjectSelected.cropwidth + (differenceXScaled * 2);
					newObjectModel.cropx = photoObjectSelected.cropx - differenceXScaled;
					newObjectModel.cropy = photoObjectSelected.cropy - differenceYScaled;
				}
			}

			let isCropValid = true;
			let cropHeight = newObjectModel.cropheight || photoObjectSelected.cropheight;
			let cropWidth = newObjectModel.cropwidth || photoObjectSelected.cropwidth;
			let cropX = newObjectModel.cropx || photoObjectSelected.cropx;
			let cropY = newObjectModel.cropy || photoObjectSelected.cropy;

			/**
			 * Fail safe to prevent infinite loop
			 */
			const maxIteration = 1000;
			let iterationNumber = 0;

			while (
				(
					cropX < 0
					|| cropY < 0
					|| cropX + cropWidth > photoObjectSelected.maxwidth
					|| cropY + cropHeight > photoObjectSelected.maxheight
				)
				&& iterationNumber < maxIteration
			) {
				if (cropX < 0) {
					cropWidth -= cropX;
					cropHeight = projectTools.getCropFromRatio({
						object: photoObjectSelected,
						type: 'height',
						value: cropWidth,
					});
					cropX = 0;
					cropY = photoObjectSelected.cropy + ((photoObjectSelected.cropheight - cropHeight) / 2);

					if (cropY < 0) {
						cropY = 0;
					}
				}
				if (cropWidth > photoObjectSelected.maxwidth) {
					cropWidth = photoObjectSelected.maxwidth;
					cropHeight = projectTools.getCropFromRatio({
						object: photoObjectSelected,
						type: 'height',
						value: cropWidth,
					});
					cropX = 0;
					cropY = photoObjectSelected.cropy + ((photoObjectSelected.cropheight - cropHeight) / 2);

					if (cropY < 0) {
						cropY = 0;
					}
				}
				if (cropX + cropWidth > photoObjectSelected.maxwidth) {
					cropX -= (cropX + cropWidth) - photoObjectSelected.maxwidth;
				}
				if (cropY < 0) {
					cropHeight -= cropY;
					cropWidth = projectTools.getCropFromRatio({
						object: photoObjectSelected,
						type: 'width',
						value: cropHeight,
					});
					cropY = 0;
					cropX = photoObjectSelected.cropx + ((photoObjectSelected.cropwidth - cropWidth) / 2);

					if (cropX < 0) {
						cropX = 0;
					}
				}
				if (cropHeight > photoObjectSelected.maxheight) {
					cropHeight = photoObjectSelected.maxheight;
					cropWidth = projectTools.getCropFromRatio({
						object: photoObjectSelected,
						type: 'width',
						value: cropHeight,
					});
					cropY = 0;
					cropX = photoObjectSelected.cropx + ((photoObjectSelected.cropwidth - cropWidth) / 2);

					if (cropX < 0) {
						cropX = 0;
					}
				}
				if (cropY + cropHeight > photoObjectSelected.maxheight) {
					cropY -= (cropY + cropHeight) - photoObjectSelected.maxheight;
				}

				iterationNumber += 1;
			}

			if (
				typeof newObjectModel.cropheight === 'undefined'
				&& typeof newObjectModel.cropwidth === 'undefined'
				&& typeof newObjectModel.cropx === 'undefined'
				&& typeof newObjectModel.cropy === 'undefined'
			) {
				isCropValid = false;
			} else if (
				cropHeight < 0
				|| cropHeight > photoObjectSelected.maxheight
			) {
				isCropValid = false;
			} else if (
				cropWidth < 0
				|| cropWidth > photoObjectSelected.maxwidth
			) {
				isCropValid = false;
			} else if (
				cropX < 0
				|| cropX > photoObjectSelected.maxwidth
			) {
				isCropValid = false;
			} else if (
				cropY < 0
				|| cropY > photoObjectSelected.maxheight
			) {
				isCropValid = false;
			} else if ((cropX + cropWidth) > photoObjectSelected.maxwidth) {
				isCropValid = false;
			} else if ((cropY + cropHeight) > photoObjectSelected.maxheight) {
				isCropValid = false;
			} else if (
				Number.isNaN(newObjectModel.cropheight)
				|| Number.isNaN(newObjectModel.cropwidth)
				|| Number.isNaN(newObjectModel.cropx)
				|| Number.isNaN(newObjectModel.cropy)
			) {
				isCropValid = false;
			}

			if (isCropValid) {
				if (
					cropX !== photoObjectSelected.cropx
					|| (
						typeof newObjectModel.cropx !== 'undefined'
						&& newObjectModel.cropx !== cropX
					)
				) {
					newObjectModel.cropx = cropX;
				}
				if (
					cropY !== photoObjectSelected.cropy
					|| (
						typeof newObjectModel.cropy !== 'undefined'
						&& newObjectModel.cropy !== cropY
					)
				) {
					newObjectModel.cropy = cropY;
				}
				if (
					cropWidth !== photoObjectSelected.cropwidth
					|| (
						typeof newObjectModel.cropwidth !== 'undefined'
						&& newObjectModel.cropwidth !== cropWidth
					)
				) {
					newObjectModel.cropwidth = cropWidth;
				}
				if (
					cropHeight !== photoObjectSelected.cropheight
					|| (
						typeof newObjectModel.cropheight !== 'undefined'
						&& newObjectModel.cropheight !== cropHeight
					)
				) {
					newObjectModel.cropheight = cropHeight;
				}

				let cropChanged = false;

				if (
					typeof newObjectModel.cropx !== 'undefined'
					&& newObjectModel.cropx !== photoObjectSelected.cropx
				) {
					cropChanged = true;
				} else if (
					typeof newObjectModel.cropy !== 'undefined'
					&& newObjectModel.cropy !== photoObjectSelected.cropy
				) {
					cropChanged = true;
				} else if (
					typeof newObjectModel.cropwidth !== 'undefined'
					&& newObjectModel.cropwidth !== photoObjectSelected.cropwidth
				) {
					cropChanged = true;
				} else if (
					typeof newObjectModel.cropheight !== 'undefined'
					&& newObjectModel.cropheight !== photoObjectSelected.cropheight
				) {
					cropChanged = true;
				}

				if (cropChanged) {
					ProductStateModule.changePageObject(newObjectModel);
				}

				if (!Array.isArray(finalEvent)) {
					this.cropGridX = finalEvent.clientX;
					this.cropGridY = finalEvent.clientY;
				} else {
					this.cropGridX = finalEvent[1].clientX - finalEvent[0].clientX;
					this.cropGridY = finalEvent[1].clientY - finalEvent[0].clientY;
				}

				this.cropPendingChanges = true;
			}
		} else if (
			this.cropGridMovingMouseDown
			&& photoObjectSelected
		) {
			event.preventDefault();

			if (Array.isArray(finalEvent)) {
				[finalEvent] = finalEvent;
			}

			let xDifference = (finalEvent.clientX - this.cropGridX);
			let yDifference = (finalEvent.clientY - this.cropGridY);

			if ('touches' in event) {
				xDifference /= this.scaling * 2;
				yDifference /= this.scaling * 2;
			} else {
				xDifference /= this.scaling;
				yDifference /= this.scaling;
			}

			const newObjectModel: OptionalExceptFor<PageObjectModel, 'id'> = {
				id: photoObjectSelected.id,
			};
			let objectXDifference: number;
			let objectYDifference: number;

			if (photoObjectSelected.rotate === 90) {
				objectXDifference = yDifference;
				objectYDifference = -xDifference;
			} else if (photoObjectSelected.rotate === 180) {
				objectXDifference = -xDifference;
				objectYDifference = -yDifference;
			} else if (photoObjectSelected.rotate === 270) {
				objectXDifference = -yDifference;
				objectYDifference = xDifference;
			} else {
				objectXDifference = xDifference;
				objectYDifference = yDifference;
			}

			const newCropX = photoObjectSelected.cropx - objectXDifference;
			const newCropY = photoObjectSelected.cropy - objectYDifference;
			let isCropValid = true;

			if (
				newCropX >= 0
				&& newCropX <= photoObjectSelected.maxwidth
				&& (newCropX + photoObjectSelected.cropwidth) <= photoObjectSelected.maxwidth
			) {
				newObjectModel.cropx = newCropX;
			}

			if (
				newCropY >= 0
				&& newCropY <= photoObjectSelected.maxheight
				&& (newCropY + photoObjectSelected.cropheight) <= photoObjectSelected.maxheight
			) {
				newObjectModel.cropy = newCropY;
			}

			isCropValid = (
				typeof newObjectModel.cropx !== 'undefined'
				|| typeof newObjectModel.cropy !== 'undefined'
			);

			if (isCropValid) {
				ProductStateModule.changePageObject(newObjectModel);
				this.cropGridX = (finalEvent as MouseEvent | Touch).clientX;
				this.cropGridY = (finalEvent as MouseEvent | Touch).clientY;
				this.cropPendingChanges = true;
			}
		}
	}

	private checkLogoBackground(): Promise<void> {
		if (!this.currentOfferingModel) {
			return Promise.resolve();
		}

		const [logoObject] = this.photoObjects;

		if (
			!logoObject._vectorSVG
			|| !logoObject._vectorColors
		) {
			let promiseResolver!: () => void;
			let promiseRejecter!: () => void;
			const promise = new Promise<void>((resolve, reject) => {
				promiseResolver = resolve;
				promiseRejecter = reject;
			});
			const photoObjectUnwatch = this.$watch(
				'photoObjects',
				() => {
					if (
						logoObject._vectorSVG
						&& logoObject._vectorColors
					) {
						photoObjectUnwatch();
						this
							.checkLogoBackground()
							.then(promiseResolver)
							.catch(promiseRejecter);
					}
				},
				{
					deep: true,
				},
			);

			return promise;
		}

		return new Promise((resolve, reject) => {
			try {
				if (
					!logoObject._vectorColors?.background
					|| logoObject._vectorColors.background.color === 'transparent'
				) {
					resolve();
				} else {
					const { background: vectorColorBackground } = logoObject._vectorColors;
					this.closeLogoBackgroundDetectedDialog = this.$openDialogNew({
						header: {
							hasCloseButton: false,
							title: this.$t('views.logoBackgroundDetected.dialog.title'),
						},
						body: {
							component: LogoBackgroundDetectedView,
							props: {
								objectModel: logoObject,
							},
						},
						footer: {
							buttons: [
								{
									id: 'keep',
									text: this.$t('views.logoBackgroundDetected.dialog.buttons.keep'),
									color: 'secondary',
									fontSize: (
										!this.isMobile
											? undefined
											: '--font-size-xs'
									),
									height: (
										!this.isMobile
											? 48
											: 42
									),
									click: () => {
										analytics.trackEvent(
											'Logo background detected',
											{
												selectedChoice: 'keep',
											},
										);
										this.closeLogoBackgroundDetectedDialog?.();
									},
								},
								{
									id: 'remove',
									text: this.$t('views.logoBackgroundDetected.dialog.buttons.remove'),
									fontSize: (
										!this.isMobile
											? undefined
											: '--font-size-xs'
									),
									height: (
										!this.isMobile
											? 48
											: 42
									),
									click: () => {
										analytics.trackEvent(
											'Logo background detected',
											{
												selectedChoice: 'remove',
											},
										);

										let colorReplacement: PageObjectColorReplacementModels = [];

										if (logoObject.colorReplacement) {
											colorReplacement = [...logoObject.colorReplacement];
										}

										const backgroundColorReplacementFound = colorReplacement.find((replacement) => replacement.color === vectorColorBackground.color);

										if (backgroundColorReplacementFound) {
											backgroundColorReplacementFound.replace.real = 'transparent';
											backgroundColorReplacementFound.replace.visual = undefined;
										} else {
											colorReplacement.push({
												color: vectorColorBackground.color,
												replace: {
													real: 'transparent',
												},
											});
										}

										ProductStateModule
											.changePageObject({
												id: logoObject.id,
												colorReplacement,
												_resetImage: true,
											})
											.then(() => this.closeLogoBackgroundDetectedDialog?.());
									},
								},
							],
						},
						listeners: {
							close: () => {
								this.closeLogoBackgroundDetectedDialog = undefined;
								resolve();
							},
						},
						padding: (
							!this.isMobile
								? [24, 32]
								: [16, 20]
						),
						theme: 'light',
						width: (
							!this.isMobile
								? 624
								: undefined
						),
					}).close;
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	private checkLogoColors(
		dialogType: 'logo-color-limit-exceeded' | 'exceeded-color-selection',
		checkTextColors = false,
	): boolean {
		const [logoObject] = this.photoObjects;

		if (
			!this.currentOfferingModel
			|| !this.currentOfferingModel.previewColorSpectrum
			|| PRINT_COLOR_SPECTRUM_FULL.includes(this.currentOfferingModel.previewColorSpectrum)
			|| !logoObject
		) {
			return true;
		}

		if (
			!logoObject.colorReplacement
			|| !logoObject._vectorSVG
			|| !logoObject._vectorColors
		) {
			const photoObjectUnwatch = this.$watch(
				'photoObjects',
				() => {
					if (
						logoObject.colorReplacement
						&& logoObject._vectorSVG
						&& logoObject._vectorColors
					) {
						photoObjectUnwatch();
						this.checkLogoColors(dialogType);
					}
				},
				{
					deep: true,
				},
			);

			return true;
		}

		const { _vectorColors } = logoObject;
		const { currentOfferingModel } = this;
		const vectorColors = logoColorsTools.getColors(logoObject);
		const logoColors = [
			...vectorColors.foreground,
		];

		if (
			vectorColors.background
			&& dialogType === 'logo-color-limit-exceeded'
		) {
			logoColors.push(vectorColors.background);
		}

		let logoColorsReplacement = logoColors.map((vectorColor) => ({
			color: vectorColor.color,
			replace: (
				vectorColor.replace
					? vectorColor.replace
					: {
						real: vectorColor.color,
					}
			),
		}));
		const logoColorsCheckResult = logoColorsTools.check(
			currentOfferingModel,
			{
				colorReplacement: logoColorsReplacement,
			},
		);

		if (!logoColorsCheckResult.valid) {
			if (dialogType === 'logo-color-limit-exceeded') {
				analytics.trackEvent(
					'Logo color limit exceeded',
					{
						colorsAvailable: logoColorsCheckResult.offeringColors,
						colorsInLogo: logoColorsCheckResult.logoColors,
					},
				);

				this.closeLogoColorExceededDialog = this.$openDialogNew({
					header: {
						component: LogoColorLimitExceededHeaderView,
						props: {
							value: logoColorsCheckResult.offeringColors,
						},
						listeners: {
							close: () => {
								this.closeLogoColorExceededDialog?.();
							},
						},
					},
					body: {
						component: LogoColorLimitExceededBodyView,
						props: {
							limit: logoColorsCheckResult.offeringColors,
							value: logoColorsCheckResult.logoColors,
						},
						listeners: {
							'edit-colors': () => {
								this.closeLogoColorExceededDialog?.();
							},
						},
					},
					listeners: {
						close: () => {
							if (!this.photoObjectSelected) {
								ProductStateModule.changePageObject({
									id: logoObject.id,
									_selected: true,
								});
							}
							if (this.editorToolbarActiveMode !== 'edit-colors') {
								this.editorToolbarActiveMode = 'edit-colors';
							}

							this.closeLogoColorExceededDialog = undefined;
						},
					},
					padding: '0px',
					styles: {
						overflow: 'hidden',
						rowGap: '0px',
					},
					theme: 'light',
					width: (
						!this.isMobile
							? 624
							: undefined
					),
				}).close;
			} else {
				this.closeExceededColorSelectionDialog = this.$openDialogNew({
					header: {
						hasCloseButton: false,
						title: this.$t('views.exceededColorSelection.dialog.title'),
					},
					body: {
						component: ExceededColorSelectionView,
						props: {
							objectModel: logoObject,
							value: this.currentOfferingModelColor,
						},
					},
					footer: {
						buttons: [
							{
								id: 'choose-new-logo',
								text: this.$t('views.exceededColorSelection.dialog.buttons.choose-new-logo'),
								color: 'secondary',
								fontSize: (
									!this.isMobile
										? undefined
										: '--font-size-xs'
								),
								height: (
									!this.isMobile
										? 48
										: 42
								),
								click: () => {
									analytics.trackEvent(
										'Color limitation option selected',
										{
											selectedChoice: 'Choose new logo',
											colorsAvailable: logoColorsCheckResult.offeringColors,
											colorsInLogo: logoColorsCheckResult.logoColors,
										},
									);

									ProductStateModule.deselectPageObjects();
									deleteObject(logoObject);
									this.onAddPhoto();
									this.closeExceededColorSelectionDialog?.();
								},
							},
							{
								id: 'convert-to-n-colors',
								text: this.$t(
									'views.exceededColorSelection.dialog.buttons.convert-to-n-colors',
									{
										count: this.currentOfferingModelColor,
									},
								),
								fontSize: (
									!this.isMobile
										? undefined
										: '--font-size-xs'
								),
								height: (
									!this.isMobile
										? 48
										: 42
								),
								click: async () => {
									analytics.trackEvent(
										'Color limitation option selected',
										{
											selectedChoice: 'Convert to N colors',
											colorsAvailable: logoColorsCheckResult.offeringColors,
											colorsInLogo: logoColorsCheckResult.logoColors,
										},
									);

									const svgMergedColorGroups = await svgUtils.limitColorsToTopN(
										logoObject._vectorSVG as string,
										_vectorColors,
										{
											topN: this.currentOfferingModelColor,
											threshold: ConfigModule['logoColors.convertToNColorsThreshold'],
										},
									);
									const backgroundColorReplacementFound = logoObject.colorReplacement?.find((replacement) => replacement.color === _vectorColors.background?.color);
									const colorReplacement: PageObjectColorReplacementModels = (
										backgroundColorReplacementFound
											? [{
												color: backgroundColorReplacementFound.color,
												replace: {
													...backgroundColorReplacementFound.replace,
												},
											}]
											: []
									);
									const colorConverter = new ColorConverter();

									// eslint-disable-next-line no-restricted-syntax
									for (const mergedColorGroup of svgMergedColorGroups.foreground) {
										// eslint-disable-next-line no-restricted-syntax
										for (const colorToBeReplaced of mergedColorGroup.colors) {
											colorConverter.hex6 = {
												value: mergedColorGroup.color.slice(1),
											};

											try {
												colorConverter.pantone = {
													name: colorConverter.pantone.name,
												};
											} catch {
												// Swallow error: no action required
											}

											const objectModelColorReplacement: PageObjectColorReplacementModel = {
												color: colorToBeReplaced,
												replace: {
													real: `#${colorConverter.hex6.value}`,
												},
											};

											if (colorConverter.hex6.showAs) {
												objectModelColorReplacement.replace.visual = `#${colorConverter.hex6.showAs}`;
											}

											colorReplacement.push(objectModelColorReplacement);
										}
									}

									ProductStateModule.changePageObject({
										id: logoObject.id,
										colorReplacement,
										_resetImage: true,
										_selected: true,
									});
									this.editorToolbarActiveMode = 'edit-colors';
									this.closeExceededColorSelectionDialog?.();
								},
							},
						],
					},
					listeners: {
						close: () => {
							this.closeExceededColorSelectionDialog = undefined;
						},
					},
					padding: (
						!this.isMobile
							? [24, 32]
							: [16, 20]
					),
					styles: {
						'--dialog-component-row-gap': '24px',
					},
					theme: 'light',
					width: (
						!this.isMobile
							? 624
							: undefined
					),
				}).close;
			}
		}

		if (
			checkTextColors
			&& logoColorsCheckResult.valid
			&& this.textObjects.length
		) {
			const textObjectsUniqueColors = new Set(
				this.textObjects
					.filter((textObject) => textObject.fontcolor)
					.map((textObject) => {
						if (textObject.fontcolor?.length === 7) {
							return textObject.fontcolor.toLowerCase();
						}

						const colorConverter = new ColorConverter();
						colorConverter.hex3 = {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							value: textObject.fontcolor!.slice(1),
						};

						return `#${colorConverter.hex6.value}`;
					}),
			);

			if (
				vectorColors.background
				&& dialogType === 'exceeded-color-selection'
			) {
				logoColors.push(vectorColors.background);
				logoColorsReplacement = logoColors.map((vectorColor) => ({
					color: vectorColor.color,
					replace: (
						vectorColor.replace
							? vectorColor.replace
							: {
								real: vectorColor.color,
							}
					),
				}));
				logoColorsCheckResult.logoColors += 1;
			}

			// eslint-disable-next-line no-restricted-syntax
			for (const logoColorReplacement of logoColorsReplacement) {
				textObjectsUniqueColors.delete(logoColorReplacement.replace.real);
			}

			if ((logoColorsCheckResult.logoColors + textObjectsUniqueColors.size) > logoColorsCheckResult.offeringColors) {
				analytics.trackEvent(
					'Logo color limit exceeded',
					{
						colorsAvailable: logoColorsCheckResult.offeringColors,
						colorsInLogo: logoColorsCheckResult.logoColors,
						colorsInText: textObjectsUniqueColors.size,
					},
				);

				logoColorsCheckResult.valid = false;
				this.closeLogoColorExceededDialog = this.$openDialogNew({
					header: {
						component: LogoColorLimitExceededHeaderView,
						props: {
							value: logoColorsCheckResult.offeringColors,
						},
						listeners: {
							close: () => {
								this.closeLogoColorExceededDialog?.();
							},
						},
					},
					body: {
						component: LogoColorLimitExceededBodyView,
						props: {
							includesText: true,
							limit: logoColorsCheckResult.offeringColors,
							value: logoColorsCheckResult.logoColors + textObjectsUniqueColors.size,
						},
						listeners: {
							'change-text-color': () => {
								const [textObject] = this.textObjects;
								ProductStateModule.changePageObject({
									id: textObject.id,
									_selected: true,
								});
								this.closeLogoColorExceededDialog?.();
							},
							'edit-colors': () => {
								this.closeLogoColorExceededDialog?.();
							},
						},
					},
					listeners: {
						close: () => {
							if (!this.textObjectSelected) {
								if (!this.photoObjectSelected) {
									ProductStateModule.changePageObject({
										id: logoObject.id,
										_selected: true,
									});
								}
								if (this.editorToolbarActiveMode !== 'edit-colors') {
									this.editorToolbarActiveMode = 'edit-colors';
								}
							}

							this.closeLogoColorExceededDialog = undefined;
						},
					},
					padding: '0px',
					styles: {
						overflow: 'hidden',
						rowGap: '0px',
					},
					theme: 'light',
					width: (
						!this.isMobile
							? 624
							: undefined
					),
				}).close;
			}
		}

		return logoColorsCheckResult.valid;
	}

	private convertLogoColors(): void {
		const photoObject = this.photoObjects[0];

		if (
			this.fullOfferingModel
			&& photoObject?._vectorColors
			&& this.fullOfferingModel.previewColorSpectrum
			&& !PRINT_COLOR_SPECTRUM_FULL.includes(this.fullOfferingModel.previewColorSpectrum)
		) {
			const colorReplacement: PageObjectColorReplacementModels = [];
			const colorConverter = new ColorConverter();
			const vectorColorsKeys = Array.from(photoObject._vectorColors.foreground.keys());

			if (photoObject._vectorColors.background) {
				vectorColorsKeys.push(photoObject._vectorColors.background.color);
			}

			// eslint-disable-next-line no-restricted-syntax
			for (const vectorColorsKey of vectorColorsKeys) {
				colorConverter.hex6 = {
					value: vectorColorsKey.slice(1),
				};

				try {
					colorConverter.pantone = {
						name: colorConverter.pantone.name,
					};
					const objectModelColorReplacement: PageObjectColorReplacementModel = {
						color: vectorColorsKey,
						replace: {
							real: `#${colorConverter.hex6.value}`,
						},
					};

					if (colorConverter.hex6.showAs) {
						objectModelColorReplacement.replace.visual = `#${colorConverter.hex6.showAs}`;
					}

					colorReplacement.push(objectModelColorReplacement);
				} catch {
					// Shallow error: no action required
				}
			}

			ProductStateModule.changePageObject({
				id: photoObject.id,
				colorReplacement,
			});
		}
	}

	protected onDoneAdjustingClick(): void {
		if (
			!this.isLogoProduct
			|| this.checkLogoColors(
				'logo-color-limit-exceeded',
				true,
			)
		) {
			ProductStateModule.deselectPageObjects();
			ProductStateModule.resetHistory();

			if (this.$router) {
				navigate.goForward('editor');
			}
		}
	}

	protected onEditorCanvasDeleteObject(objectModel: EditorInteractivePageObjectModel): void {
		ProductStateModule.deselectPageObjects();
		deleteObject(objectModel);
		ProductStateModule.pushHistory();
	}

	protected onEditorCanvasTextOptionsActiveModeChange(): void {
		this.forceComputeScaling();
	}

	protected onEditorCanvasEyeDropperShownChange(isEyeDropperShown: boolean): void {
		this.isEyeDropperShown = isEyeDropperShown;
	}

	protected onEditorCanvasPageObjectChange(partialObjectModel: OptionalExceptFor<EditorInteractivePageObjectModel, 'id'>): void {
		ProductStateModule.changePageObject(partialObjectModel);
		this.checkFreeformLayout(partialObjectModel);
	}

	protected onEditorCanvasPageObjectsChange(
		pageObjects: EditorCanvasPageObjectsModel,
		objectsDifferences: Record<EditorCanvasPageObjectModel['id'], Array<keyof EditorCanvasPageObjectModel>>,
	): void {
		const objectsChangedIds = Object.keys(objectsDifferences) as Array<EditorCanvasPageObjectModel['id']>;

		// eslint-disable-next-line no-restricted-syntax
		for (const objectChangedId of objectsChangedIds) {
			const objectDifferences = objectsDifferences[objectChangedId];
			const pageObjectFound = pageObjects.find((pageObject) => pageObject.id === objectChangedId);

			if (pageObjectFound) {
				const partialObjectToChange = objectDifferences.reduce(
					(partialObject, keyDifference) => {
						partialObject[keyDifference] = pageObjectFound[keyDifference];

						return partialObject;
					},
					{
						id: objectChangedId,
					} as OptionalExceptFor<PageObjectModel, 'id'>,
				);

				if (pageObjectFound.type === 'text') {
					ProductStateModule.changePageObject({
						...partialObjectToChange,
						ignorePointerSizeResize: true,
					});
				} else {
					ProductStateModule.changePageObject(partialObjectToChange);
					this.checkFreeformLayout(partialObjectToChange);
				}
			}
		}
	}

	protected onEditorPushChanges(): void {
		ProductStateModule.pushHistory();
	}

	protected onEditorTextDone(): void {
		if (this.textObjectSelected) {
			/**
			 * TODO: Check for pending changes before pushing to the history
			 */
			ProductStateModule.changePageObject({
				id: this.textObjectSelected.id,
				_selected: false,
				_selectedForEdition: true,
			});
			ProductStateModule.pushHistory();
		}
	}

	protected onEyeDropperEnd(): void {
		this.isEyeDropperShown = false;
	}

	protected onEyeDropperStart(): void {
		this.isEyeDropperShown = true;
	}

	private onEditorToolbarResize(): void {
		if (this.editorToolbarComponent?.$el instanceof Element) {
			const editorToolbarElementRect = this.editorToolbarComponent.$el.getBoundingClientRect();

			if (
				editorToolbarElementRect
				&& Math.floor(this.previousEditorToolbarWidth) !== Math.floor(editorToolbarElementRect.width)
			) {
				this.previousEditorToolbarWidth = editorToolbarElementRect.width;
				requestAnimationFrame(this.forceComputeScaling);
			}
		}
	}

	protected onEditorTopToolbarBack(): void {
		if (this.isPreviewModeActive) {
			this.onPreviewModeToggle();
		} else if (
			this.hasProjectOnePage
			&& !this.projectModelHasOverview
			&& this.$router
		) {
			navigate.goBack('editProduct');
		} else if (this.$router) {
			this.closeEditor();
		}
	}

	protected onEditorTopToolbarCancel(): void {
		if (
			this.previousPhotoObjectState
			&& this.photoObjectSelected
		) {
			const objectDifferences = objectUtils.getObjectDifferences(
				this.previousPhotoObjectState,
				this.photoObjectSelected,
			);
			const partialObjectToChange: OptionalExceptFor<PageObjectModel, 'id'> = {
				id: this.photoObjectSelected.id,
			};

			// eslint-disable-next-line no-restricted-syntax
			for (const objectDifference of objectDifferences) {
				if (
					objectDifference.substring(
						0,
						1,
					) !== '_'
				) {
					partialObjectToChange[objectDifference] = this.previousPhotoObjectState[objectDifference];
				}
			}

			if (Object.keys(partialObjectToChange).length > 1) {
				if (objectDifferences.includes('colorReplacement')) {
					partialObjectToChange._resetImage = true;
				}

				ProductStateModule.changePageObject(partialObjectToChange);
			}
		}

		if (
			!this.isLogoProduct
			|| this.checkLogoColors('logo-color-limit-exceeded')
		) {
			ProductStateModule.deselectPageObjects();
		}
	}

	protected onEditorTopToolbarDoneEditing(): void {
		if (
			!this.isLogoProduct
			|| this.checkLogoColors('logo-color-limit-exceeded')
		) {
			ProductStateModule.deselectPageObjects();
		}
	}

	protected onEditorTopToolbarProductSettingsChange(editorProductSettingsModel: EditorProductSettingsModel): void {
		if (
			'showBleedMargin' in editorProductSettingsModel
			&& editorProductSettingsModel.showBleedMargin !== AppStateModule.showBleed
		) {
			AppStateModule.toggleBleed();
			analytics.trackEvent(
				'Toggle bleed',
				{
					category: 'Button',
					label: (
						this.showBleed
							? 'On'
							: 'Off'
					),
				},
			);
		}
		if (
			'applyEnhancement' in editorProductSettingsModel
			&& (
				(
					typeof ProductStateModule.getProductSettings.applyEnhancement === 'undefined'
					&& !editorProductSettingsModel.applyEnhancement
				)
				|| (
					typeof ProductStateModule.getProductSettings.applyEnhancement !== 'undefined'
					&& editorProductSettingsModel.applyEnhancement !== ProductStateModule.getProductSettings.applyEnhancement
				)
			)
		) {
			const enhancedData: Partial<ProductSettings> = {
				applyEnhancement: editorProductSettingsModel.applyEnhancement,
			};
			ProductStateModule.changeProductSettings(enhancedData);
			analytics.trackEvent(
				'Save enhancement setting',
				{
					enabled: editorProductSettingsModel.applyEnhancement,
				},
			);
		}
	}

	protected onEditorTopToolbarRedo(): void {
		analytics.trackEvent(
			'Redo',
			{
				category: 'Button',
				label: 'Page',
				value: 'Editor',
			},
		);
		ProductStateModule.redoHistory();
	}

	protected onEditorTopToolbarSave(projectModel: ProductModel): void {
		analytics.trackEvent(
			'Save project',
			{
				category: 'Button',
			},
		);

		if (this.projectModel?.id) {
			ProductsModule
				.putModel({
					id: this.projectModel.id,
					data: {
						title: projectModel.title,
					},
				})
				.then(() => {
					if (!AppStateModule.online) {
						return checkConnection()
							.then(() => {
								ProductState
									.save()
									.catch(() => {
										// Swallow error: no action required
									});
							})
							.catch(() => {
								const { close: closeDialog } = this.$openDialogNew({
									header: {
										title: this.$t('offline'),
									},
									body: {
										content: this.$t('offlineStatus'),
									},
									footer: {
										buttons: [
											{
												id: 'accept',
												text: this.$t('dialogButtonOk'),
												click: () => {
													closeDialog();
												},
											},
										],
									},
									theme: 'light',
								});
							});
					}
					if (UserModule.temporary) {
						return auth.showSignup({
							hasclose: true,
						});
					}

					return ProductState
						.finalize(
							true,
							false,
						)
						.then((saved) => {
							if (saved) {
								this.showSaveOptionsDialog();
							}
						})
						.catch((error) => {
							if (
								'message' in error
								&& error.message
							) {
								const { close: closeDialog } = this.$openDialogNew({
									header: {
										title: this.$t('dialogHeaderError'),
									},
									body: {
										content: error.message,
									},
									footer: {
										buttons: [
											{
												id: 'accept',
												text: this.$t('dialogButtonErrorOk'),
												click: () => {
													closeDialog();
												},
											},
										],
									},
									theme: 'light',
								});
							}
						});
				});
		}
	}

	protected onEditorTopToolbarUndo(): void {
		analytics.trackEvent(
			'Undo',
			{
				category: 'Button',
				label: 'Page',
				value: 'Editor',
			},
		);
		ProductStateModule.undoHistory();
	}

	protected onOfferingOptionValueChange(fullOfferingModel: FullOfferingModel): void {
		const fullOfferingModelFound = this.fullOfferingModels.find(
			(fullOfferingModelItem) => fullOfferingModelItem.id === fullOfferingModel.id,
		);

		if (fullOfferingModelFound) {
			if (!this.isVirtualOffering) {
				AppStateModule.setHeavyLoad();

				ProductState
					.changeOffering(fullOfferingModelFound.id)
					.then(() => ProductStateModule.pushHistory())
					.catch((error: Error) => {
						// Swallow error: no action required
						if (typeof window.glBugsnagClient !== 'undefined') {
							window.glBugsnagClient.notify(
								error,
								(event) => {
									event.severity = 'warning';
								},
							);
						}
					})
					.finally(() => {
						AppStateModule.unsetHeavyLoad();
					});
			} else if (this.pageModel) {
				ProductState.changePageOfferingId(
					this.pageModel,
					fullOfferingModelFound.id,
					true,
				);
			}
		}
	}

	protected onPageModelChange(pageModel: PageModel): void {
		if (this.pageModel) {
			const objectDifferences = objectUtils.getObjectDifferences(
				pageModel,
				this.pageModel,
			);

			ProductStateModule.changePage(
				objectDifferences.reduce(
					(partialPage, keyDifference) => {
						// @ts-ignore
						partialPage[keyDifference] = pageModel[keyDifference];

						return partialPage;
					},
					{
						id: pageModel.id,
					} as OptionalExceptFor<PageModel, 'id'>,
				),
			);
			ProductStateModule.pushHistory();
		}
	}

	protected onPaginationChange(newPageId: string): void {
		ProductStateModule.resetHistory();
		const newPageModel = ProductStateModule.getPage(newPageId);

		if (newPageModel) {
			ProductStateModule.deselectPageObjects();
			this.zoomSliderValue = 100;

			if (
				this.$router
				&& ProductStateModule.productId
			) {
				navigate.toEditor(
					ProductStateModule.productId,
					ProductStateModule
						.getPageIndex(newPageModel)
						.toString(),
					{
						trigger: true,
						replace: true,
					},
				);
			} else {
				ProductStateModule.setActivePage(newPageModel.id);
			}
		}
	}

	protected onPreviewModeToggle(): void {
		this.isPreviewModeActive = !this.isPreviewModeActive;
	}

	protected onTextCTAButtonsCancel(): void {
		this.$nextTick(() => {
			if (this.textObjectSelected) {
				if (!this.textObjectSelected._previousState?.text) {
					deleteObject(this.textObjectSelected);
				} else {
					const objectDifferences = objectUtils.getObjectDifferences(
						this.textObjectSelected._previousState,
						this.textObjectSelected,
					);
					const partialObjectToChange: OptionalExceptFor<PageObjectModel, 'id'> = {
						id: this.textObjectSelected.id,
						_previousState: undefined,
						_selected: false,
					};

					// eslint-disable-next-line no-restricted-syntax
					for (const objectDifference of objectDifferences) {
						if (
							objectDifference.substring(
								0,
								1,
							) !== '_'
						) {
							partialObjectToChange[objectDifference] = this.textObjectSelected._previousState[objectDifference];
						}
					}

					if (Object.keys(partialObjectToChange).length > 1) {
						ProductStateModule.changePageObject(partialObjectToChange);
					}
				}
			}
		});
	}

	protected onEditorMainSectionScroll(): void {
		this.editorMainSectionElement.scrollTo({
			top: 0,
		});
	}

	protected onToolbarAddFrame(): void {
		if (this.fullOfferingModel) {
			if (!this.isMobile) {
				let value: FullOfferingModel = {
					...this.fullOfferingModel,
					options: this.fullOfferingModel.options.map((option) => ({
						...option,
						value: {
							...option.value,
						},
					})),
				};
				const {
					close: closeFrameDialog,
					api: apiFrameDialog,
				} = this.$openDialogNew({
					body: {
						component: EditorFrameSelectorView,
						props: {
							value,
							bleedMargin: this.bleedMargin,
							currencyModel: this.currencyModel,
							fullOfferingModels: this.fullOfferingModels,
							pageModel: this.pageModel,
							pageObjects: this.pageObjects,
							previewModel: this.offeringModelModel2D,
						},
						listeners: {
							apply: () => {
								if (this.fullOfferingModel?.id !== value.id) {
									this.onOfferingOptionValueChange(value);
								}
							},
							change: (newValue: FullOfferingModel) => {
								value = newValue;
								const bodyComponent = apiFrameDialog.bodyComponent();

								if (bodyComponent) {
									bodyComponent.value = value;
								}
							},
							close: () => {
								this.closeFrameDialog?.();
							},
						},
					},
					header: {
						title: AppDataModule.getOfferingName(this.fullOfferingModel.id),
						styles: {
							fontSize: 'var(--font-size-l)',
						},
					},
					listeners: {
						close: () => {
							this.closeFrameDialog = undefined;
						},
					},
					padding: '24px',
					width: 773,
				});
				this.closeFrameDialog = closeFrameDialog;
			} else {
				let value: FullOfferingModel = {
					...this.fullOfferingModel,
					options: this.fullOfferingModel.options.map((option) => ({
						...option,
						value: {
							...option.value,
						},
					})),
				};
				const {
					close: closeFrameToolbar,
					api: apiFrameToolbar,
				} = this.$openToolbar({
					body: {
						component: EditorFrameSelectorView,
						props: {
							value,
							bleedMargin: this.bleedMargin,
							currencyModel: this.currencyModel,
							fullOfferingModels: this.fullOfferingModels,
							pageModel: this.pageModel,
							pageObjects: this.pageObjects,
							previewModel: this.offeringModelModel2D,
						},
						listeners: {
							apply: () => {
								if (this.fullOfferingModel?.id !== value.id) {
									this.onOfferingOptionValueChange(value);
								}
							},
							change: (newValue: FullOfferingModel) => {
								value = newValue;
								const bodyComponent = apiFrameToolbar.bodyComponent();

								if (bodyComponent) {
									bodyComponent.value = value;
								}
							},
							close: () => {
								this.closeFrameToolbar?.();
							},
						},
						styles: {
							marginTop: '16px',
						},
					},
					listeners: {
						close: () => {
							this.closeFrameToolbar = undefined;
						},
					},
				});
				this.closeFrameToolbar = closeFrameToolbar;
			}
		}
	}

	protected onToolbarAddObject(objectModel: PageObjectModel): void {
		if (this.pageModel) {
			ProductStateModule
				.addObject({
					data: objectModel,
				})
				.catch(() => {
					// Swallow error: no action required
				});
		}
	}

	protected onToolbarChange(photoObjectModel: EditorCanvasPageObjectModel | Event): void {
		if (photoObjectModel instanceof Event) {
			return;
		}

		this.updateObjectModel(photoObjectModel);
	}

	protected onToolbarDelete(): void {
		if (
			this.currentOfferingModel
			&& OfferingGroups(
				this.currentOfferingModel.groupid,
				'PhotoPrints',
			)
		) {
			this.onToolbarDeletePage();
		} else if (
			this.currentOfferingModel
			&& this.photoObjectSelected
		) {
			const { photoObjectSelected } = this;
			ProductStateModule.deselectPageObjects();
			deleteObject(photoObjectSelected);
			ProductStateModule.pushHistory();
		}
	}

	private onToolbarDeletePage(): void {
		if (!this.isProjectSinglePage) {
			if (this.currentOfferingModel) {
				const minOfferingModel = this.fullOfferingModels
					.sort((a, b) => a.minpages - b.minpages)
					.at(0);

				if (!minOfferingModel) {
					throw new Error(`Could not find required offeringModel with groupid ${this.currentOfferingModel.groupid} and typeid ${this.currentOfferingModel.typeid}`);
				}

				const pageCount = ProductStateModule.getPageCount || 0;

				if (!this.pageModel) {
					throw new Error('Missing required page model');
				} else if (pageCount > minOfferingModel.minpages) {
					const { pageModel } = this;
					const { close: closeConfirm } = this.$openDialogNew({
						header: {
							title: this.$t('dialogHeaderRemovePage'),
						},
						body: {
							content: (
								OfferingGroups(
									this.currentOfferingModel.groupid,
									'PrintTypes',
								)
									? this.$t('dialogTextRemovePrint')
									: this.$t('dialogTextRemovePage')
							),
						},
						footer: {
							buttons: [
								{
									id: 'cancel',
									text: this.$t('dialogButtonCancel'),
									click: () => {
										closeConfirm();
									},
									color: 'secondary',
								},
								{
									id: 'accept',
									text: this.$t('dialogButtonRemovePageOk'),
									click: () => {
										const pageRemovalFunction = () => {
											this.$nextTick(() => {
												ProductStateModule.removePage({
													pageId: pageModel.id,
												});
											});
										};

										if (!this.hasProjectOnePage) {
											const nextPageId = this.editorPaginationComponent.getNextPage();
											const previousPageId = this.editorPaginationComponent.getPreviousPage();

											if (nextPageId) {
												this.onPaginationChange(nextPageId);
												pageRemovalFunction();
											} else if (previousPageId) {
												this.onPaginationChange(previousPageId);
												pageRemovalFunction();
											} else {
												pageRemovalFunction();
												this.closeEditor();
											}
										} else {
											pageRemovalFunction();
										}

										closeConfirm();
									},
								},
							],
						},
						theme: 'light',
					});
				} else {
					// Show refusal dialog
					const { close: closeAlert } = this.$openDialogNew({
						header: {
							title: this.$t('dialogHeaderMinPages'),
						},
						body: {
							content: this.$t('dialogTextMinPages'),
						},
						footer: {
							buttons: [
								{
									id: 'accept',
									text: this.$t('dialogButtonMinPagesOk'),
									click: () => {
										closeAlert();
									},
								},
							],
						},
						theme: 'light',
					});
				}
			} else {
				throw new Error('Missing required offering model');
			}
		}
	}

	protected onToolbarEditorModeChange(mode: EditorToolbarActiveMode): void {
		this.editorToolbarActiveMode = mode;
		this.removeWindowClickHandler('toolbar');

		if (this.cropPendingChanges) {
			ProductStateModule.pushHistory();
			this.cropPendingChanges = false;
		}

		if (
			mode === 'crop'
			&& this.photoObjectSelected
		) {
			if (this.photoObjectSelected.fillMethod === 'contain') {
				ProductState.changePageObjectFill(
					this.photoObjectSelected,
					'cover',
					this.photoObjectSelected.rotate,
				);

				if (AppStateModule.mask) {
					const updatedObject = this.productModel.objects[this.photoObjectSelected.id];
					AppStateModule.setMask(JSON.parse(JSON.stringify(updatedObject)));
				}
			}

			this.$nextTick(() => {
				requestAnimationFrame(() => {
					this.addWindowClickHandler('toolbar');
				});
			});
		}

		if (this.isMobile) {
			requestAnimationFrame(() => this.forceComputeScaling());
		}
	}

	protected onToolbarLayoutChange(layout: TemplateSet): void {
		if (this.pageModel) {
			analytics.trackEvent(
				'Change Template',
				{
					category: 'Button',
				},
			);
			AppStateModule.setHeavyLoad();
			this.$openLoaderDialog();

			if (this.pageModel.customLayout) {
				ProductStateModule.changePage({
					id: this.pageModel.id,
					customLayout: false,
				});
			}

			ProductState
				.changeTemplate(
					this.pageModel,
					layout.id,
					{
						objectTypes: ['photo'],
					},
				)
				.then(() => {
					ProductStateModule.pushHistory();
				})
				.catch(() => {
					// Swallow error: no action required
				})
				.finally(() => {
					AppStateModule.unsetHeavyLoad();
					this.$closeLoaderDialog();
				});
		}
	}

	protected onToolbarObjectsChange(
		objects: PageObjectModel[],
		objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>>,
	): void {
		const objectsChangedIds = Object.keys(objectsDifferences) as Array<EditorCanvasPageObjectModel['id']>;

		// eslint-disable-next-line no-restricted-syntax
		for (const objectChangedId of objectsChangedIds) {
			const objectDifferences = objectsDifferences[objectChangedId];
			const objectFound = objects.find((object) => object.id === objectChangedId);

			if (objectFound) {
				const objectExists = this.pageObjects.find((pageObject) => pageObject.id === objectChangedId);

				if (objectExists) {
					const partialObjectToChange = objectDifferences.reduce(
						(partialObject, keyDifference) => {
							partialObject[keyDifference] = objectFound[keyDifference];

							return partialObject;
						},
						{
							id: objectChangedId,
						} as OptionalExceptFor<PageObjectModel, 'id'>,
					);

					ProductStateModule.changePageObject(partialObjectToChange);
				}
			}
		}

		ProductStateModule.pushHistory();
	}

	protected onToolbarPaginationMouseEnter(): void {
		if (this.isTapToContinueEditingVisible) {
			if (this.tapToContinueEditingTooltipTimeout) {
				clearTimeout(this.tapToContinueEditingTooltipTimeout);
				this.tapToContinueEditingTooltipTimeout = undefined;
			} else {
				this.onIsTapToContinueEditingVisibleChange();
			}
		}
	}

	protected onToolbarPaginationMouseLeave(): void {
		if (this.isTapToContinueEditingVisible) {
			this.editorTooltips['tap-to-continue']?.destroy();
			delete this.editorTooltips['tap-to-continue'];
		}
	}

	protected onToolbarProjectThemeChange(themeModel: ThemeModel): void {
		analytics.trackEvent(
			'Change Theme',
			{
				category: 'Button',
			},
		);

		const closeLoader = this.$openLoaderDialog();
		ProductState
			.changeTheme(
				themeModel.id,
				true,
				true,
			)
			.then(() => {
				ProductStateModule.pushHistory();
			})
			.finally(() => {
				closeLoader();
			})
			.catch(() => {
				// Swallow error: no action required
			});
	}

	protected onToolbarShuffle(): void {
		if (!this.pageModel) {
			throw new Error('Missing required page model');
		}

		analytics.trackEvent(
			'Shuffle photos',
			{
				category: 'Button',
			},
		);
		ProductStateModule.shufflePhotos();

		this.$openLoaderDialog();
		AppStateModule.setHeavyLoad();

		ProductState
			.changeTemplate(
				this.pageModel,
				this.pageModel.templateSetId || undefined,
				{
					objectTypes: ['photo'],
				},
			)
			.then(() => ProductStateModule.pushHistory())
			.catch((error) => {
				this.$openErrorDialog({
					body: {
						content: error.message,
					},
				});
			})
			.finally(() => {
				AppStateModule.unsetHeavyLoad();
				this.$closeLoaderDialog();
			});
	}

	private onToolbarWindowClick(event: MouseEvent): void {
		const target = event.target as HTMLElement;

		if (
			this.isCropModeActive
			&& !domUtils.isOrHasParent(
				target,
				this.editorModuleCropGridElement,
			)
			&& (
				!this.zoomTooltip?.api
				|| !domUtils.isOrHasParent(
					target,
					this.zoomTooltip.api.bodyElement(),
				)
			)
		) {
			if (this.cropPendingChanges) {
				ProductStateModule.pushHistory();
				this.cropPendingChanges = false;
			}

			this.editorToolbarActiveMode = null;
			this.removeWindowClickHandler('toolbar');
		}
	}

	protected onWindowMouseUp(): void {
		if (
			this.cropGridCroppingMouseDown
			|| this.cropGridMovingMouseDown
			|| this.zoomTooltipMouseDown
		) {
			this.cropGridCroppingMouseDown = false;
			this.cropGridMovingMouseDown = false;
			this.zoomTooltipMouseDown = false;
			this.cropGridX = 0;
			this.cropGridY = 0;
			this.cropGridMode = undefined;
			document.body.style.cursor = '';

			if (!this.isCropModeActive) {
				ProductStateModule.pushHistory();
			}

			window.removeEventListener(
				'mouseup',
				this.onWindowMouseUp,
			);
			window.removeEventListener(
				'touchend',
				this.onWindowMouseUp,
			);
			window.removeEventListener(
				'mousemove',
				this.onCropGridMouseMove,
			);
			window.removeEventListener(
				'touchmove',
				this.onCropGridMouseMove,
			);
			this.$nextTick(() => {
				requestAnimationFrame(() => {
					this.addWindowClickHandler('toolbar');
				});
			});
		}
	}

	private onZoomSliderMouseDown(): void {
		this.zoomTooltipMouseDown = true;
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
	}

	protected onZoomSliderValueClick(event: MouseEvent | TouchEvent): void {
		if (this.zoomSliderValue === 100) {
			return;
		}

		let finalEvent: MouseEvent | Touch | undefined;

		if ('touches' in event) {
			if (event.touches.length == 1) {
				// eslint-disable-next-line prefer-destructuring
				finalEvent = event.touches[0];
			}
		} else {
			finalEvent = event;
		}

		if (finalEvent) {
			const targetElements = document.elementsFromPoint(
				finalEvent.clientX,
				finalEvent.clientY,
			) as HTMLElement[];
			const zoomSliderValueElement = targetElements.find((targetElement) => targetElement.closest<HTMLElement>('.input-slider-value'));

			if (zoomSliderValueElement) {
				this.zoomSliderValue = 100;
				this.zoomSliderValueTooltipClose?.();
			}
		}
	}

	protected onZoomSliderValueMouseMove(event: MouseEvent | TouchEvent): void {
		let finalEvent: MouseEvent | Touch | undefined;

		if ('touches' in event) {
			if (event.touches.length == 1) {
				// eslint-disable-next-line prefer-destructuring
				finalEvent = event.touches[0];
			}
		} else {
			finalEvent = event;
		}

		if (finalEvent) {
			const targetElements = document.elementsFromPoint(
				finalEvent.clientX,
				finalEvent.clientY,
			) as HTMLElement[];
			const zoomSliderValueElement = targetElements.find((targetElement) => targetElement.closest<HTMLElement>('.input-slider-value'));

			if (
				zoomSliderValueElement
				&& !this.zoomSliderValueTooltipClose
				&& this.zoomSliderValue !== 100
			) {
				this.zoomSliderValueTooltipClose = this.$openTooltip({
					anchor: zoomSliderValueElement,
					body: {
						content: this.$t(
							'modules.editor.zoomToDefault',
							{
								value: 100,
							},
						),
					},
					bodyStyles: {
						backgroundColor: 'var(--neutral3-background-fill)',
						color: 'var(--black1)',
						fontFamily: 'var(--font-family-regular)',
						fontSize: 'var(--font-size-s)',
						fontWeight: 'var(--font-weight-regular)',
						padding: '8px',
					},
					distance: 15,
					hasCloseButton: false,
					initialPosition: 'top center',
					isModal: false,
					listeners: {
						close: () => {
							this.zoomSliderValueTooltipClose = undefined;
						},
					},
				}).close;
			} else if (
				!zoomSliderValueElement
				&& this.zoomSliderValueTooltipClose
			) {
				this.zoomSliderValueTooltipClose();
			}
		}
	}

	protected onZoomSliderValueMouseLeave(): void {
		this.zoomSliderValueTooltipClose?.();
	}

	private processVectorLogo(): Promise<void> {
		const [logoObject] = this.photoObjects;
		const photoModel = (
			logoObject.photoid
				? PhotosModule.getById(logoObject.photoid)
				: undefined
		);

		if (!photoModel?.url) {
			return Promise.resolve();
		}

		return svgUtils
			.load(photoModel.url)
			.then(async (svgResult) => {
				const svgColors = await svgUtils.getColors(
					svgResult.content,
					ConfigModule['logoColors.mergeSimilarThreshold'],
				);

				return ProductStateModule.changePageObject({
					id: logoObject.id,
					_vectorSVG: svgResult.content,
					_vectorColors: svgColors,
				});
			})
			.then(undefined);
	}

	private removeWindowClickHandler(type?: EditorModuleWindowClickHandlerType): void {
		if (this.windowClickHandlers) {
			const windowClickHandlerTypes: EditorModuleWindowClickHandlerType[] = [];

			if (!type) {
				windowClickHandlerTypes.push(...Object.keys(this.windowClickHandlers) as EditorModuleWindowClickHandlerType[]);
			} else {
				windowClickHandlerTypes.push(type);
			}

			// eslint-disable-next-line no-restricted-syntax
			for (const windowClickHandlerType of windowClickHandlerTypes) {
				const eventHandler = this.windowClickHandlers[windowClickHandlerType];

				if (eventHandler) {
					window.removeEventListener(
						'click',
						eventHandler,
					);
				}
			}

			if (!type) {
				this.windowClickHandlers = undefined;
			} else {
				delete this.windowClickHandlers[type];
			}
		}
	}

	private rotateObjectModel(
		objectInitialPosition: EditorCanvasPageObjectModel,
		centerX: number,
		centerY: number,
	): EditorCanvasPageObjectModel {
		/* eslint-disable camelcase */
		const {
			x_axis,
			y_axis,
			rotate,
		} = objectInitialPosition;
		const radians = (Math.PI / 180) * rotate;
		const cos = Math.cos(radians);
		const sin = Math.sin(radians);
		const newXAxis = cos * (x_axis - centerX) + sin * (y_axis - centerY) + centerX;
		const newYAxis = cos * (y_axis - centerY) - sin * (x_axis - centerX) + centerY;
		/* eslint-enable camelcase */

		return {
			...objectInitialPosition,
			x_axis: newXAxis,
			y_axis: newYAxis,
		};
	}

	private rotatePointInRectangle(
		px: number,
		py: number,
		objectModel: EditorInteractivePageObjectModel,
	) {
		// Calculate the center of the rectangle
		const cx = objectModel.width / 2;
		const cy = objectModel.height / 2;

		// Translate the point to the origin
		const translatedX = px - cx;
		const translatedY = py - cy;

		// Convert the angle to radians
		const radians = objectModel.rotate * (Math.PI / 180);

		// Apply the rotation
		const rotatedX = translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
		const rotatedY = translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

		// Calculate the rotated bounding box
		const objectRotatedBoundingBox = this.calculateRotatedBoundingBox(objectModel);

		// Calculate the new center of the bounding box
		const newCx = objectRotatedBoundingBox.width / 2;
		const newCy = objectRotatedBoundingBox.height / 2;

		// Translate the point back to the new center
		const newX = rotatedX + newCx;
		const newY = rotatedY + newCy;

		return {
			x: newX,
			y: newY,
		};
	}

	private setActivePageByRoute(): void {
		const newPageIndex = parseInt(
			this.$route.params.pagenr,
			10,
		);
		const newPageModel = ProductStateModule.getPageByNumber(newPageIndex);

		if (newPageModel) {
			ProductStateModule.setActivePage(newPageModel.id);
		}
	}

	private showSaveOptionsDialog(): void {
		const { close: closeDialog } = this.$openDialogNew({
			header: {
				title: this.$t('dialogHeaderSaveOptions'),
			},
			body: {
				content: this.$t('dialogTextSaveOptions'),
			},
			footer: {
				buttons: [
					{
						id: 'cancel',
						text: this.$t('dialogButtonSaveOptionsCancel'),
						click: () => {
							analytics.trackEvent(
								'offerProjectLinkDialog',
								{
									category: 'Dialog',
									label: 'Choice',
									value: 'No',
								},
							);
							closeDialog();
						},
						color: 'secondary',
					},
					{
						id: 'save',
						text: this.$t('dialogButtonSaveOptionsOk'),
						click: () => {
							analytics.trackEvent(
								'offerProjectLinkDialog',
								{
									category: 'Dialog',
									label: 'Choice',
									value: 'Yes',
								},
							);
							closeDialog();
							this.showSendEmailDialog();
						},
					},
				],
			},
			theme: 'light',
		});
	}

	private showSendEmailDialog() {
		if (ConfigModule['features.offerProjectLink']) {
			const { email } = UserModule;

			if (
				!email
				|| email.length === 0
				|| email.indexOf('@') === -1
				|| email.indexOf('@') >= email.length - 1
			) {
				const { close: closeError } = this.$openDialogNew({
					header: {
						title: this.$t('dialogHeaderError'),
					},
					body: {
						content: this.$t('dialogTextInvalidEmail'),
					},
					footer: {
						buttons: [
							{
								id: 'accept',
								text: this.$t('dialogButtonOk'),
								click: () => {
									closeError();
								},
							},
						],
					},
					theme: 'light',
				});

				return;
			}

			if (!this.projectModel?.id) {
				throw new Error('Missing required productId');
			}

			if (!this.projectModel) {
				throw new Error('Missing required productModel');
			}

			if (!this.currentOfferingModel) {
				throw new Error('Missing required offeringModel');
			}

			const baseUrl = `${window.location.protocol}//${window.location.host}`;
			const productUrl = `${baseUrl}/app/${this.projectModel.id}/open`;
			const { version } = ProductStateModule;

			const eventProps: Record<string, any> = {
				projectId: this.projectModel.id,
				projectUrl: productUrl,
				imageUrl: `${ConfigModule.projectImageBaseUrl}/${this.projectModel.id}/${this.projectModel.read_token}/${version}`,
				offeringName: AppDataModule.getOfferingName(this.currentOfferingModel.id),
			};

			analytics.trackEvent(
				'Email project link',
				eventProps,
			);
			const { close: closeConfirm } = this.$openDialogNew({
				header: {
					hasCloseButton: false,
				},
				body: {
					content: this.$t(
						'dialogTextProjectEmailSent',
						{
							email,
						},
					),
				},
				footer: {
					buttons: [
						{
							id: 'confirm',
							text: this.$t('dialogButtonOk'),
							click: () => {
								closeConfirm();
							},
						},
					],
				},
				theme: 'light',
			});
		}
	}

	private updateObjectModel(objectModel: PageObjectModel): void {
		const originalObject = this.productModel.objects[objectModel.id];
		const objectDifferences = objectUtils.getObjectDifferences(
			objectModel,
			originalObject,
		);

		if (objectDifferences.length) {
			let partialObjectToChange = objectDifferences.reduce(
				(partialObject, keyDifference) => {
					if (
						keyDifference.substring(
							0,
							1,
						) !== '_'
					) {
						partialObject[keyDifference] = objectModel[keyDifference];
					}

					return partialObject;
				},
				{
					id: objectModel.id,
				} as OptionalExceptFor<PageObjectModel, 'id'>,
			);

			if ('mask' in partialObjectToChange) {
				if (!AppStateModule.mask) {
					AppStateModule.setMask(JSON.parse(JSON.stringify(originalObject)));
				}

				applyMask(
					objectModel,
					objectModel.mask || null,
				);

				if (!objectModel.mask) {
					AppStateModule.setMask(null);
					ProductState.changePageObjectFill(
						objectModel,
						'cover',
						objectModel.rotate,
					);
				}
			} else if ('fillMethod' in partialObjectToChange) {
				this.editorTooltipFillToSizeManuallyClosed = false;
				ProductState.changePageObjectFill(
					originalObject,
					partialObjectToChange.fillMethod,
					originalObject.rotate,
				);

				if (AppStateModule.mask) {
					const updatedObject = this.productModel.objects[objectModel.id];
					AppStateModule.setMask(JSON.parse(JSON.stringify(updatedObject)));
				}
			} else {
				if (
					'rotate' in partialObjectToChange
					&& originalObject.type == 'photo'
					&& originalObject.photoid
				) {
					const photoModel = PhotosModule.getById(originalObject.photoid);

					if (!photoModel) {
						throw new Error('Missing required photo model');
					}

					const { templatestateid } = originalObject;

					if (
						templatestateid
						&& this.pageModel
					) {
						const positionModel = this.pageTemplatePositions.find((position) => position.id == templatestateid) as TemplatePhotoPosition | undefined;

						if (positionModel?.transformable) {
							const photoData: Omit<PhotoData, 'url'> = {
								id: photoModel.id,
								width: photoModel.full_width,
								height: photoModel.full_height,
								caption: photoModel.title || undefined,
							};

							if (
								typeof photoModel.fcx !== 'undefined'
								&& photoModel.fcx !== null
								&& typeof photoModel.fcy !== 'undefined'
								&& photoModel.fcy !== null
								&& typeof photoModel.fcw !== 'undefined'
								&& photoModel.fcw !== null
								&& typeof photoModel.fch !== 'undefined'
								&& photoModel.fch !== null
							) {
								photoData.facebox = {
									x: photoModel.fcx,
									y: photoModel.fcy,
									width: photoModel.fcw,
									height: photoModel.fch,
								};
							}
							const props = TemplateClass.fitPhotoInRectangle(
								{
									x: positionModel.x,
									y: positionModel.y,
									width: positionModel.width,
									height: positionModel.height,
									angle: originalObject.rotate,
									borderwidth: positionModel.borderwidth,
									autoRotate: Boolean(positionModel.autorotate),
								},
								photoData,
								undefined,
								{
									fit: positionModel.fillMethod === 'contain',
									forceRotate: true,
									resizing: {
										maxScale: this.currentOfferingModel
											? this.currentOfferingModel.configdpi / this.currentOfferingModel.minimumdpi
											: 1000,
										recommendedMaxScale: this.currentOfferingModel
											? this.currentOfferingModel.configdpi / this.currentOfferingModel.qualitydpi
											: 1000,
									},
								},
							);
							partialObjectToChange = {
								id: originalObject.id,
								x_axis: props.x,
								y_axis: props.y,
								width: props.width,
								height: props.height,
								cropwidth: props.cropWidth,
								cropheight: props.cropHeight,
								cropx: props.cropX,
								cropy: props.cropY,
								rotate: props.rotation,
							};
						}
					}
				}

				ProductStateModule.changePageObject(partialObjectToChange);
				ProductStateModule.pushHistory();
			}
		}
	}

	private waitForCanvasContainerSize(): void {
		if (
			!this.canvasContainerElement
			|| !this.editorMainSectionElement
			|| !this.editorPaginationContainerElement
			|| !this.editorPaginationComponent?.$el
		) {
			requestAnimationFrame(() => this.waitForCanvasContainerSize());
			return;
		}

		const canvasContainerElementRect = this.canvasContainerElement.getBoundingClientRect();
		const editorMainSectionElementRect = this.editorMainSectionElement.getBoundingClientRect();

		if (
			!canvasContainerElementRect.width
			|| !canvasContainerElementRect.height
			|| !editorMainSectionElementRect.width
			|| !editorMainSectionElementRect.height
		) {
			requestAnimationFrame(() => this.waitForCanvasContainerSize());
			return;
		}

		requestAnimationFrame(() => this.forceComputeScaling());
	}
}
