import './defines';
import ButtonComponent from 'components/button';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import ColorPickerComponent from 'components/color-picker';
import InputSliderComponent from 'components/input-slider';
import SelectComponent from 'components/select';
import {
	ColorPickerFromColors,
	EditorTextOptionsActiveMode,
	EditorTextOptionsCloseTooltipToolbarKeys,
	EditorTextOptionsFontModels,
	EditorTextOptionsIsMobileUnwatchersKeys,
	EditorTextOptionsOfferingModel,
} from 'interfaces/app';
import {
	FontModel,
	PageObjectTextModel,
} from 'interfaces/database';
import { PageObjectModel } from 'interfaces/project';
import { PRINT_COLOR_SPECTRUM_FULL } from 'settings/offerings';
import { FontModule } from 'store';
import { mobile as mobileTools } from 'tools';
import ColorPickerTabsComponent from 'views/editor-text-options/color-picker-tabs';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorTextOptionsView',
	components: {
		ButtonComponent,
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
		ColorPickerComponent,
		ColorPickerTabsComponent,
		InputSliderComponent,
		SelectComponent,
	},
})
export default class EditorTextOptionsView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the active page object model',
			required: true,
			schema: 'PageObjectModel',
			type: Object,
		},
	)
	public readonly objectModel!: PageObjectModel;

	@Prop({
		default: false,
		description: 'Indicates whether the user is limited to the choose from colors',
		type: Boolean,
	})
	public readonly areColorsLimitedToChooseFrom?: boolean;

	@Prop({
		description: 'Defines the canvas element that is going to be passed to the color-picker',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	@Prop({
		default: undefined,
		description: 'Defines the list of colors that the user can choose from',
		schema: 'ColorPickerFromColors',
		type: Array,
	})
	public readonly chooseFrom?: ColorPickerFromColors;

	@Prop({
		description: 'Defines the list of font families that is going to be passed to the select',
		required: true,
		schema: 'EditorTextOptionsFontModels',
		type: Array,
	})
	public readonly fontModels!: EditorTextOptionsFontModels;

	@Prop({
		description: 'Defines the offering model from which the minimum font size is going to be extracted',
		required: true,
		schema: 'EditorTextOptionsOfferingModel',
		type: Object,
	})
	public readonly offeringModel!: EditorTextOptionsOfferingModel;

	protected get canChangeColor(): boolean {
		return (
			!this.offeringModel.previewColorSpectrum
			|| PRINT_COLOR_SPECTRUM_FULL.includes(this.offeringModel.previewColorSpectrum)
		);
	}

	protected get colorPickerCircleStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

		if (this.internalObjectModel.fontcolor) {
			styles['--circle-background-color'] = this.internalObjectModel.fontcolor;
		}
		if (
			this.internalObjectModel.bgcolor
			&& this.internalObjectModel.bgcolor !== 'transparent'
		) {
			styles['--circle-box-shadow-color'] = this.internalObjectModel.bgcolor;
		}

		return styles;
	}

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get fontModelSelected(): FontModel {
		return this.fontModels.find((fontModel: FontModel) => fontModel.id === this.internalObjectModel.fontface) as FontModel;
	}

	protected get isAlignActive(): boolean {
		return this.internalActiveMode === 'align';
	}

	protected get isColorPickerActive(): boolean {
		return this.internalActiveMode === 'color-picker';
	}

	private get isColorPickerBackgroundColorAvailable(): boolean {
		return this.offeringModel.type !== 'logo';
	}

	private get isColorPickerPMSMode(): boolean {
		return !!(
			this.offeringModel.previewColorSpectrum
			&& !PRINT_COLOR_SPECTRUM_FULL.includes(this.offeringModel.previewColorSpectrum)
		);
	}

	protected get isFontFamilyActive(): boolean {
		return this.internalActiveMode === 'font-family';
	}

	protected get isFontSizeActive(): boolean {
		return this.internalActiveMode === 'font-size';
	}

	protected get isSingleAlignmentButtonShow(): boolean {
		return (
			this.isMobile
			&& (
				!!this.fontModelSelected.bold
				|| !!this.fontModelSelected.italic
				|| !!this.fontModelSelected.underline
			)
		);
	}

	@Ref('colorPickerReference')
	private readonly colorPickerElement!: HTMLDivElement;

	@Ref('fontSizeReference')
	private readonly fontSizeElement!: HTMLDivElement;

	@Ref('subOptionsReference')
	protected readonly subOptionsElement!: HTMLDivElement;

	protected fontFamilyOptionsAnchor: HTMLDivElement | null = null;

	private internalObjectModel: PageObjectModel = {} as PageObjectModel;

	private internalActiveMode: EditorTextOptionsActiveMode | null = null;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatchers!: Partial<Record<EditorTextOptionsIsMobileUnwatchersKeys, () => void>>;

	private isUsingEyeDropper?: boolean;

	private closeTooltip!: Partial<Record<EditorTextOptionsCloseTooltipToolbarKeys, (() => void)>>;

	protected beforeDestroy(): void {
		this.isMobileUnwatchers.main?.();
		delete this.isMobileUnwatchers.main;

		const tooltipKeys = Object.keys(this.closeTooltip) as EditorTextOptionsCloseTooltipToolbarKeys[];
		const isMobileKeys = Object.keys(this.isMobileUnwatchers) as EditorTextOptionsIsMobileUnwatchersKeys[];

		// eslint-disable-next-line no-restricted-syntax
		for (const toolitpKey of tooltipKeys) {
			this.closeTooltip[toolitpKey]?.();
			delete this.closeTooltip[toolitpKey];
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const isMobileKey of isMobileKeys) {
			this.isMobileUnwatchers[isMobileKey]?.();
			delete this.isMobileUnwatchers[isMobileKey];
		}
	}

	protected created(): void {
		this.isMobileUnwatchers = {
			main: mobileTools.watch(() => {
				this.isMobile = mobileTools.isMobile;
			}),
		};
		this.closeTooltip = {};
	}

	protected mounted(): void {
		this.fontFamilyOptionsAnchor = this.subOptionsElement;
	}

	@Watch('internalActiveMode')
	protected onInternalActiveModeChanged(): void {
		this.$emit(
			'active-mode-change',
			this.internalActiveMode,
		);
	}

	@Watch(
		'objectModel',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onObjectModelChanged(): void {
		this.internalObjectModel = {
			...this.objectModel,
		};
	}

	private convertToMysqlBoolean(value: boolean) {
		return (
			value
				? 1
				: 0
		);
	}

	protected fontFamilyOptionStyles(option: FontModel): Partial<CSSStyleDeclaration> {
		FontModule.loadModel(option.id);

		return {
			fontFamily: option.id,
			textTransform: 'none',
		};
	}

	private handleActiveModeChange(activeMode: EditorTextOptionsCloseTooltipToolbarKeys): void {
		if (this.internalActiveMode === activeMode) {
			this.internalActiveMode = null;
			this.isMobileUnwatchers[activeMode]?.();
			this.closeTooltip[activeMode]?.();
			delete this.isMobileUnwatchers[activeMode];
			delete this.closeTooltip[activeMode];
			window.removeEventListener(
				'click',
				this.onWindowClick,
			);
			return;
		}

		if (mobileTools.isMobile) {
			window.removeEventListener(
				'click',
				this.onWindowClick,
			);
			window.addEventListener(
				'click',
				this.onWindowClick,
			);
		}

		this.internalActiveMode = activeMode;
		this.isMobileUnwatchers[activeMode] = mobileTools.watch(() => {
			this.closeTooltip[activeMode]?.();
			this.isMobileUnwatchers[activeMode]?.();

			if (mobileTools.isMobile) {
				window.addEventListener(
					'click',
					this.onWindowClick,
				);
			} else {
				window.removeEventListener(
					'click',
					this.onWindowClick,
				);
			}

			this.$nextTick(() => {
				this.internalActiveMode = null;
				delete this.closeTooltip[activeMode];
				delete this.isMobileUnwatchers[activeMode];

				if (activeMode === 'color-picker') {
					this.onColorPickerClick();
				} else if (activeMode === 'font-size') {
					this.onFontSizeClick();
				}
			});
		});
		const internalActiveModeWatcher = this.$watch(
			'internalActiveMode',
			() => {
				internalActiveModeWatcher();
				this.isMobileUnwatchers[activeMode]?.();
				this.closeTooltip[activeMode]?.();
				delete this.isMobileUnwatchers[activeMode];
				delete this.closeTooltip[activeMode];

				if (this.internalActiveMode === null) {
					window.removeEventListener(
						'click',
						this.onWindowClick,
					);
				}
			},
		);
	}

	private handleTooltipClose(tooltip: EditorTextOptionsCloseTooltipToolbarKeys): void {
		if (this.internalActiveMode === tooltip) {
			this.internalActiveMode = null;
			delete this.closeTooltip[tooltip];
		}

		this.isMobileUnwatchers[tooltip]?.();
		delete this.isMobileUnwatchers[tooltip];
	}

	protected onAlignChange(align: PageObjectTextModel['align']): void {
		if (align === 'Left') {
			this.internalObjectModel.align = 'Left';
		} else if (align === 'Right') {
			this.internalObjectModel.align = 'Right';
		} else {
			this.internalObjectModel.align = 'Center';
		}

		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
			[this.internalObjectModel.id]: ['align'],
		};
		this.$emit(
			'change',
			this.internalObjectModel,
			objectsDifferences,
		);
	}

	protected onAlignClick(): void {
		if (mobileTools.isMobile) {
			this.handleActiveModeChange('align');
		}
	}

	protected onBoldClick(): void {
		this.internalObjectModel.fontbold = this.convertToMysqlBoolean(!this.internalObjectModel.fontbold);
		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
			[this.internalObjectModel.id]: ['fontbold'],
		};
		this.$emit(
			'change',
			this.internalObjectModel,
			objectsDifferences,
		);
	}

	protected onColorChange(objectModel: PageObjectModel): void {
		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
			[this.internalObjectModel.id]: [],
		};

		if (objectModel.bgcolor !== this.internalObjectModel.bgcolor) {
			this.internalObjectModel.bgcolor = objectModel.bgcolor;
			objectsDifferences[this.internalObjectModel.id].push('bgcolor');
		} else if (objectModel.fontcolor !== this.internalObjectModel.fontcolor) {
			this.internalObjectModel.fontcolor = objectModel.fontcolor;
			objectsDifferences[this.internalObjectModel.id].push('fontcolor');
		}

		if (objectModel.fontcolor_visual !== this.internalObjectModel.fontcolor_visual) {
			this.internalObjectModel.fontcolor_visual = (
				objectModel.fontcolor_visual
				?? null
			);
			objectsDifferences[this.internalObjectModel.id].push('fontcolor_visual');
		}

		this.$emit(
			'change',
			this.internalObjectModel,
			objectsDifferences,
		);
	}

	protected onColorPickerClick(): void {
		if (
			!mobileTools.isMobile
			&& this.internalActiveMode !== 'color-picker'
		) {
			const { close: closeTooltip } = this.$openTooltip({
				anchor: this.colorPickerElement,
				beforeClose: (event: ServiceEvent<Event | undefined>) => {
					if (event.payload?.target) {
						const target = event.payload.target as HTMLElement;

						if (
							this.isUsingEyeDropper
							&& this.computedCanvas
							&& (
								target === this.computedCanvas
								|| this.computedCanvas.contains(target)
							)
						) {
							event.preventDefault();
						}
					}
				},
				body: {
					component: ColorPickerTabsComponent,
					props: {
						canvas: this.computedCanvas,
						chooseFrom: this.chooseFrom,
						isBackgroundColorAvailable: this.isColorPickerBackgroundColorAvailable,
						isPmsMode: this.isColorPickerPMSMode,
						onlyFromColors: this.areColorsLimitedToChooseFrom,
						value: this.internalObjectModel,
					},
					listeners: {
						change: this.onColorChange,
						'eye-dropper-end': this.onEyeDropperEnd,
						'eye-dropper-start': this.onEyeDropperStart,
					},
				},
				bodyStyles: {
					padding: '16px 8px',
					width: '350px',
				},
				borderRadius: 12,
				distance: 24,
				hasCloseButton: false,
				isModal: false,
				listeners: {
					close: () => {
						this.handleTooltipClose('color-picker');
					},
				},
				theme: this.internalTheme,
			});
			this.closeTooltip['color-picker'] = closeTooltip;
		}

		this.handleActiveModeChange('color-picker');
	}

	protected onEyeDropperEnd(): void {
		this.isUsingEyeDropper = false;
		this.$emit('eye-dropper-end');
	}

	protected onEyeDropperStart(): void {
		this.isUsingEyeDropper = true;
		this.$emit('eye-dropper-start');
	}

	protected onFontFamilyChange(fontFamily: FontModel['id']): void {
		this.internalObjectModel.fontface = fontFamily;

		if (
			!mobileTools.isMobile
			&& this.internalActiveMode === 'font-family'
		) {
			this.internalActiveMode = null;
		}

		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
			[this.internalObjectModel.id]: ['fontface'],
		};
		this.$emit(
			'change',
			this.internalObjectModel,
			objectsDifferences,
		);
	}

	protected onFontFamilyClick(): void {
		this.handleActiveModeChange('font-family');
	}

	protected onFontSizeChange(value: number): void {
		this.internalObjectModel.pointsize = value;
		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
			[this.internalObjectModel.id]: ['pointsize'],
		};
		this.$emit(
			'change',
			this.internalObjectModel,
			objectsDifferences,
		);
	}

	protected onFontSizeClick(): void {
		if (
			!mobileTools.isMobile
			&& this.internalActiveMode !== 'font-size'
		) {
			const { close: closeTooltip } = this.$openTooltip({
				anchor: this.fontSizeElement,
				body: {
					component: InputSliderComponent,
					props: {
						min: this.offeringModel.minfontsize,
						value: this.internalObjectModel.pointsize,
					},
					listeners: {
						change: (value: number) => {
							this.internalObjectModel.pointsize = value;
							const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
								[this.internalObjectModel.id]: ['pointsize'],
							};
							this.$emit(
								'change',
								this.internalObjectModel,
								objectsDifferences,
							);
						},
					},
				},
				bodyStyles: {
					padding: '8px 12px',
				},
				distance: 24,
				hasCloseButton: false,
				isModal: false,
				listeners: {
					close: () => {
						this.handleTooltipClose('font-size');
					},
				},
				theme: 'light',
			});
			this.closeTooltip['font-size'] = closeTooltip;
		}

		this.handleActiveModeChange('font-size');
	}

	protected onItalicClick(): void {
		this.internalObjectModel.fontitalic = this.convertToMysqlBoolean(!this.internalObjectModel.fontitalic);
		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
			[this.internalObjectModel.id]: ['fontitalic'],
		};
		this.$emit(
			'change',
			this.internalObjectModel,
			objectsDifferences,
		);
	}

	protected onUnderlineClick(): void {
		this.internalObjectModel.fontunderline = this.convertToMysqlBoolean(!this.internalObjectModel.fontunderline);
		const objectsDifferences: Record<PageObjectModel['id'], Array<keyof PageObjectModel>> = {
			[this.internalObjectModel.id]: ['fontunderline'],
		};
		this.$emit(
			'change',
			this.internalObjectModel,
			objectsDifferences,
		);
	}

	private onWindowClick(event: MouseEvent): void {
		if (
			!this.$el.contains(event.target as HTMLElement)
			&& this.$el !== event.target
			&& !event.defaultPrevented
		) {
			this.internalActiveMode = null;
		}
	}
}
