import './defines';
import ColorConverter from '@sosocio/color-converter';
import * as colorUtils from '@sosocio/frontend-utils/color';
import * as urlTools from '@sosocio/frontend-utils/url';
import HorizontalArrowScrollComponent from 'components/horizontal-arrow-scroll';
import IconComponent from 'components/icon';
import {
	ColorPickerColor,
	ColorPickerColors,
	ColorPickerFromColor,
	ColorPickerFromColors,
	ColorPickerPantonePalette,
	ColorPickerPattern,
	ColorPickerPatterns,
} from 'interfaces/app';
import { mobile as mobileTools } from 'tools';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import { Public } from 'utils/decorators';
import Template from './template.vue';

@Component({
	name: 'ColorPickerComponent',
	components: {
		HorizontalArrowScrollComponent,
	},
})
export default class ColorPickerComponent extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			description: 'Defines the color that is selected',
			type: String,
		},
	)
	public readonly value?: ColorPickerColor['colors']['primary'] | ColorPickerColor['colors']['palette'][number];

	@Prop({
		description: 'Defines the canvas element that is used to get the color from (to use with the eye dropper)',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	@Prop({
		default: undefined,
		description: 'Defines a list of colors that the user can choose from (if this is provided this will be the only source of colors to pick from)',
		schema: 'ColorPickerFromColors',
		type: Array,
	})
	public readonly fromColors?: ColorPickerFromColors;

	@Prop({
		default: false,
		description: 'Indicates if the color picker is in Pantone Matching System (PMS) mode',
		type: Boolean,
	})
	public readonly isPmsMode?: boolean;

	@Prop({
		description: 'Defines the magnification of the eye dropper',
		required: true,
		type: Number,
	})
	public readonly magnification!: number;

	@Prop({
		default: false,
		description: 'Indicates if the color picker would use the large (not thick and compact) variant',
		type: Boolean,
	})
	public readonly large?: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the patterns images available for selection',
		type: Array,
		schema: 'ColorPickerPatterns',
	})
	public readonly patterns?: ColorPickerPatterns;

	@Prop({
		default: false,
		description: 'Indicates if the color picker has transparent color available for selection',
		type: Boolean,
	})
	public readonly transparent?: boolean;

	protected get colorPaletteEyeDropperStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		if (
			this.isColorFromEyeDropperSelected()
			&& this.internalValue
		) {
			return {
				backgroundColor: this.internalValue,
				'--color-palette-eyedropper-border-color': 'var(--white1)',
				'--color-palette-eyedropper-box-shadow-color': this.internalValue,
			};
		}

		return {};
	}

	protected get colorPaletteEyeDropperIconStyles(): Partial<CSSStyleDeclaration> {
		if (
			this.isColorFromEyeDropperSelected()
			&& this.internalValue
			&& this.$el
		) {
			const black1Color = getComputedStyle(this.$el).getPropertyValue('--icon-neutral-black1');
			const white1Color = getComputedStyle(this.$el).getPropertyValue('--icon-neutral-white1');
			const contrastBlack1 = colorUtils.getContrastRatio(
				black1Color,
				this.internalValue,
			);
			const contrastWhite1 = colorUtils.getContrastRatio(
				white1Color,
				this.internalValue,
			);

			if (contrastBlack1 < contrastWhite1) {
				return {
					color: white1Color,
				};
			}
		}

		return {};
	}

	private get colors(): ColorPickerColors {
		const colors = [
			'neutral',
			'red',
			'orange',
			'yellow',
			'green',
			'turquoise',
			'blue',
			'purple',
			'pink',
		];

		return colors.map((color) => ({
			id: color,
			colors: {
				primary: `var(--color-picker-${color}-slider)`,
				palette: [...Array(6).keys()].map((index) => `var(--color-picker-${color}-${index + 1})`),
			},
		}));
	}

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get computedClasses(): Record<string, boolean> {
		return {
			'color-picker-component-large': !!(
				!this.isFromColors
				&& this.large
			),
		};
	}

	protected get fromColorsColorClasses(): (color: ColorPickerFromColor) => Record<string, boolean> {
		return (color) => ({
			'color-picker-component-from-colors-color-selected': this.internalValue?.toLowerCase() === color.color.toLowerCase(),
		});
	}

	protected get fromColorsColorStyles(): (color: ColorPickerFromColor) => Partial<CSSStyleDeclaration> & Record<string, string> {
		return (color) => {
			const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};
			let hexColor = (
				color.visual?.toLowerCase()
				|| color.color.toLowerCase()
			);

			if (hexColor.at(0) !== '#') {
				hexColor = `#${hexColor}`;
			}

			styles.backgroundColor = hexColor;

			if (this.internalValue?.toLowerCase() === color.color.toLowerCase()) {
				styles['--color-picker-component-from-colors-color-box-shadow-color'] = hexColor;
			}

			return styles;
		};
	}

	protected get isFromColors(): boolean {
		return !!this.fromColors?.length;
	}

	protected get selectedColor(): ColorPickerColor {
		return (
			this.colors.find((color) => color.colors.primary === this.activeColor)
			|| this.colors[0]
		);
	}

	protected get selectedColorPalette(): string[] {
		let colorPalettes = [...this.selectedColor.colors.palette];

		if (this.isPmsMode) {
			colorPalettes = colorPalettes
				.reduce(
					(newColorPalettes, colorPalette) => {
						const colorPaletteValue = this.getColorValue(colorPalette).toLowerCase();

						if (!newColorPalettes.find((newColorPalette) => newColorPalette.pantone === colorPaletteValue)) {
							newColorPalettes.push({
								real: colorPalette,
								pantone: colorPaletteValue,
							});
						}

						return newColorPalettes;
					},
					[] as ColorPickerPantonePalette[],
				)
				.map((colorPalette) => colorPalette.real);
		}

		if (this.transparent) {
			colorPalettes.unshift('transparent');
		}
		if (
			this.patterns
			&& this.activePattern
		) {
			colorPalettes.splice(
				(
					this.transparent
						? 1
						: 0
				),
				colorPalettes.length,
				...this.patterns.map((pattern) => pattern.image),
			);
		}

		return colorPalettes;
	}

	@Ref('colorSlider')
	private readonly colorSliderElement!: HTMLDivElement;

	private activeColor: ColorPickerColor['colors']['primary'] | ColorPickerColor['colors']['palette'][number] | null = null;

	private activePattern = false;

	private elementObserver?: MutationObserver;

	private eyeDropperElement?: HTMLDivElement;

	private internalValue: string | null = null;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private isMouseDown!: boolean;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
		this.destroyEyeDropper();
		this.computedCanvas.style.touchAction = '';
		this.computedCanvas.removeEventListener(
			'draw',
			this.onCanvasDraw,
		);

		if (this.elementObserver) {
			this.elementObserver.disconnect();
		}

		this.colorSliderElement?.removeEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.colorSliderElement?.removeEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
	}

	protected created(): void {
		this.isMouseDown = false;

		if (
			!this.internalValue
			|| this.isColorFromEyeDropperSelected()
		) {
			this.activeColor = this.colors[0].colors.primary;
		}

		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected mounted(): void {
		this.elementObserver = new MutationObserver(() => {
			if ((this.$el as HTMLElement).style.display === 'none') {
				this.destroyEyeDropper();
			}
		});
		this.elementObserver.observe(
			this.$el,
			{
				attributes: true,
			},
		);
		this.$forceUpdate();
		this.$forceCompute('selectedColorPalette');
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		let isPattern = false;

		if (this.internalValue) {
			const colorFound = this.getColor(this.internalValue);

			if (colorFound) {
				this.activeColor = colorFound.colors.primary;
				this.activePattern = false;
			} else if (
				this.patterns
				&& this.internalValue !== 'transparent'
				&& !this.isColorFromEyeDropperSelected()
			) {
				this.activePattern = !!this.patterns.find((pattern) => pattern.image === this.internalValue);

				if (this.activePattern) {
					isPattern = true;
					this.activeColor = null;
				}
			}
		}

		if (this.internalValue !== this.value) {
			this.$emit(
				'change',
				this.internalValue,
				(
					isPattern
						? 'pattern'
						: 'color'
				),
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.$nextTick(() => {
			this.internalValue = this.value || null;
		});
	}

	private destroyEyeDropper(): void {
		if (this.eyeDropperElement) {
			window.removeEventListener(
				'click',
				this.onWindowClick,
			);
			this.computedCanvas.removeEventListener(
				'mousemove',
				this.onCanvasMouseMove,
			);
			this.computedCanvas.removeEventListener(
				'touchstart',
				this.onCanvasMouseMove,
			);
			this.computedCanvas.removeEventListener(
				'touchmove',
				this.onCanvasMouseMove,
			);
			this.computedCanvas.removeEventListener(
				'touchend',
				this.onCanvasMouseUp,
			);
			this.computedCanvas.removeEventListener(
				'mouseup',
				this.onCanvasMouseUp,
			);
			this.eyeDropperElement.remove();
			this.eyeDropperElement = undefined;
			this.computedCanvas.style.touchAction = '';
			this.$emit('eye-dropper-end');
		}
	}

	protected getColor(color: string): ColorPickerColor {
		return this.colors.find(
			(currentColor) => {
				const normalizedColor = color.toLowerCase();
				const currentColorValue = this.getColorValue(currentColor.colors.primary).toLowerCase();

				if (currentColorValue === normalizedColor) {
					return true;
				}

				return currentColor.colors.palette
					.find((paletteColor) => (
						this.getColorValue(paletteColor).toLowerCase() === normalizedColor
					));
			},
		) as ColorPickerColor;
	}

	private getColorFromMousePosition(event: MouseEvent | TouchEvent): ColorPickerColor | 'pattern' | undefined {
		let primaryColor: string | undefined;
		let isPattern = false;
		let eventTarget = event.target as HTMLElement;
		const touch = (
			'touches' in event
			&& event.touches[0]
		);

		if (touch) {
			eventTarget = document.elementFromPoint(
				touch.clientX,
				touch.clientY,
			) as HTMLElement;
		}

		if (eventTarget) {
			if (eventTarget.classList.contains('color-slider-item')) {
				if (!eventTarget.classList.contains('color-slider-item-pattern')) {
					primaryColor = eventTarget.getAttribute('data-color') || undefined;
				} else {
					isPattern = true;
				}
			} else {
				const childFound = Array
					.from(eventTarget.children)
					.find((element) => element.classList.contains('color-slider-item'));
				const parentFound = (
					eventTarget.parentElement?.classList.contains('color-slider-item')
						? eventTarget.parentElement
						: undefined
				);
				const elementFound = childFound || parentFound;

				if (elementFound) {
					if (!elementFound.classList.contains('color-slider-item-pattern')) {
						primaryColor = elementFound.getAttribute('data-color') || undefined;
					} else {
						isPattern = true;
					}
				}
			}
		}

		if (primaryColor) {
			return this.colors.find((color) => color.colors.primary === primaryColor);
		}
		if (isPattern) {
			return 'pattern';
		}

		return undefined;
	}

	protected getColorSliderItemClasses(colorIndex: number): Record<string, boolean> {
		return {
			first: colorIndex === 0,
			last: (
				colorIndex === (this.colors.length - 1)
				&& !this.patterns
			),
		};
	}

	protected getColorSliderItemStyles(colorOrPattern: ColorPickerColor | ColorPickerPattern | undefined): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (colorOrPattern) {
			if ('colors' in colorOrPattern) {
				styles.backgroundColor = colorOrPattern.colors.primary;
			} else if (
				this.patterns?.length
				&& 'image' in colorOrPattern
			) {
				styles.backgroundColor = colorOrPattern.color;
				styles.backgroundImage = `url(${urlTools.appendParameter(
					colorOrPattern.image,
					'noCorsHeader',
				)})`;
			}
		}

		return styles;
	}

	protected getColorPaletteItemClasses(color: string): Record<string, boolean> {
		return {
			'color-palette-item-selected': this.isColorPaletteSelected(color),
			'color-palette-item-transparent': color === 'transparent',
		};
	}

	protected getColorPaletteItemDataColor(color: string): string | undefined {
		if (
			!this.activePattern
			|| color === 'transparent'
		) {
			return this.selectedColor.id;
		}

		return undefined;
	}

	protected getColorPaletteItemIcon(color: string): string | undefined {
		if (color === 'transparent') {
			return 'no_color';
		}
		if (this.isColorPaletteSelected(color)) {
			return 'check';
		}

		return undefined;
	}

	protected getColorPaletteStyles(color: string): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

		if (this.$el) {
			if (color === 'transparent') {
				styles.backgroundColor = getComputedStyle(this.$el).getPropertyValue('--white1');
			} else if (!this.activePattern) {
				if (this.isPmsMode) {
					color = this.getColorValue(
						color,
						true,
					).toLowerCase();
				}

				styles.backgroundColor = color;
				styles['--color-palette-item-box-shadow-color'] = color;
			} else if (this.patterns) {
				const patternFound = this.patterns.find((pattern) => pattern.image === color) as ColorPickerPattern;
				styles.backgroundColor = patternFound.color;
				styles.backgroundImage = `url(${urlTools.appendParameter(
					color,
					'noCorsHeader',
				)})`;
			}
		}

		return styles;
	}

	private getColorValue(
		color: string,
		visual = false,
	): string {
		let colorValue = color;

		if (this.$el) {
			if (
				color.substring(
					0,
					3,
				) === 'var'
			) {
				colorValue = getComputedStyle(this.$el)
					.getPropertyValue(
						color.substring(
							4,
							color.length - 1,
						),
					);
			} else if (
				color.substring(
					0,
					2,
				) === '--'
			) {
				colorValue = getComputedStyle(this.$el).getPropertyValue(color);
			}

			if (this.isPmsMode) {
				if (colorValue[0] !== '#') {
					colorValue = colorUtils.getHexFromAny(colorValue);
				}

				const colorConverter = new ColorConverter();

				if (colorValue.length === 4) {
					colorConverter.hex3 = {
						value: colorValue.slice(1),
					};
					colorValue = `#${colorConverter.hex6.value}`;
				}

				colorConverter.hex6 = {
					value: colorValue.slice(1),
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};
				colorValue = `#${colorConverter.hex6.value}`;

				if (
					colorConverter.hex6.showAs
					&& visual
				) {
					colorValue = `#${colorConverter.hex6.showAs}`;
				}
			}
		}

		return colorValue;
	}

	protected isColorFromEyeDropperSelected(): boolean {
		return !!(
			this.internalValue
			&& !this.colors.find((color) => this.isColorSelected(color))
			&& !this.patterns?.find((pattern) => this.isColorPaletteSelected(pattern.image))
			&& this.internalValue !== 'transparent'
		);
	}

	protected isColorSliderActive(color: ColorPickerColor): boolean {
		return color.colors.primary === this.activeColor;
	}

	private isColorPaletteSelected(color: string): boolean {
		const colorValue = this.getColorValue(color).toLowerCase();

		return (
			colorValue === this.internalValue?.toLowerCase()
			|| (
				colorUtils.validateColor(colorValue)
				&& colorUtils.validateColor(this.internalValue?.toLowerCase())
				&& colorUtils.compareColors(
					colorValue,
					this.internalValue?.toLowerCase(),
				)
			)
			|| (
				(
					!this.internalValue
					|| this.internalValue === 'transparent'
				)
				&& color === 'transparent'
			)
		);
	}

	protected isColorSelected(color: ColorPickerColor): boolean {
		if (
			colorUtils.compareColors(
				this.getColorValue(color.colors.primary).toLowerCase(),
				this.internalValue?.toLowerCase(),
			)
		) {
			return true;
		}

		return !!color.colors.palette
			.find((paletteColor) => (
				colorUtils.compareColors(
					this.getColorValue(paletteColor).toLowerCase(),
					this.internalValue?.toLowerCase(),
				)
			));
	}

	@Public()
	public isEyeDropperActive(): boolean {
		return !!this.eyeDropperElement;
	}

	protected isTransparentSelected(): boolean {
		return (
			!this.internalValue
			|| this.internalValue === 'transparent'
		);
	}

	private onCanvasDraw(): void {
		if (this.eyeDropperElement) {
			this.destroyEyeDropper();
			this.onEyeDropperClick();
		}
	}

	private onCanvasMouseMove(event: MouseEvent | TouchEvent): void {
		if (this.eyeDropperElement) {
			if ('preventDefault' in event) {
				event.preventDefault();
			}

			let finalEvent: MouseEvent | Touch;

			if ('touches' in event) {
				// eslint-disable-next-line prefer-destructuring
				finalEvent = event.touches[0];

				if (
					finalEvent.pageX
					&& finalEvent.pageY
					&& !document
						.elementFromPoint(
							finalEvent.pageX,
							finalEvent.pageY,
						)
						?.isSameNode(this.computedCanvas)
				) {
					return;
				}
			} else {
				finalEvent = event;
			}

			const canvasRect = this.computedCanvas.getBoundingClientRect();
			let canvasClientX = finalEvent.clientX - canvasRect.left;
			let canvasClientY = finalEvent.clientY - canvasRect.top;

			if (canvasClientX < 0) {
				canvasClientX = 0;
			}
			if (canvasClientY < 0) {
				canvasClientY = 0;
			}

			const magnifierCanvas = this.eyeDropperElement.querySelector('.color-picker-eyedropper-canvas') as HTMLCanvasElement;
			const magnifierCanvasContext = magnifierCanvas.getContext('2d') as CanvasRenderingContext2D;
			const magnifierElement = this.eyeDropperElement.querySelector('.color-picker-eyedropper-magnifier') as HTMLDivElement;
			const previewElement = this.eyeDropperElement.querySelector('.color-picker-eyedropper-preview') as HTMLDivElement;

			const previewStyles = getComputedStyle(previewElement);
			const magnifierHeight = magnifierElement.clientHeight;
			const magnifierWidth = magnifierElement.clientWidth;
			const previewHeight = parseInt(
				previewStyles.getPropertyValue('--eyedropper-preview-height'),
				10,
			);
			const previewMarginTop = parseInt(
				previewStyles.getPropertyValue('--eyedropper-preview-margin-top'),
				10,
			);

			magnifierCanvasContext.clearRect(
				0,
				0,
				magnifierWidth,
				magnifierHeight,
			);
			magnifierCanvasContext.drawImage(
				this.computedCanvas,
				canvasClientX - magnifierWidth / (2 * this.magnification),
				canvasClientY - magnifierHeight / (2 * this.magnification),
				magnifierWidth / this.magnification,
				magnifierHeight / this.magnification,
				0,
				0,
				magnifierWidth,
				magnifierHeight,
			);

			const magnifierContainerElement = this.eyeDropperElement.querySelector('.color-picker-eyedropper-magnifier-container') as HTMLDivElement;
			const canvasPixel = magnifierCanvasContext.getImageData(
				(magnifierWidth / 2),
				(magnifierHeight / 2),
				1,
				1,
			).data;
			const pixelColor = colorUtils.getHexFromRGB(
				canvasPixel[0],
				canvasPixel[1],
				canvasPixel[2],
			);
			previewElement.style.backgroundColor = pixelColor;
			magnifierContainerElement.style.backgroundColor = pixelColor;

			this.eyeDropperElement.style.top = `${finalEvent.clientY - ((previewHeight / 2) + previewMarginTop + magnifierHeight)}px`;
			this.eyeDropperElement.style.left = `${finalEvent.clientX - (magnifierWidth / 2)}px`;
		}
	}

	private onCanvasMouseUp(): void {
		if (this.eyeDropperElement) {
			const { eyeDropperElement } = this;
			const previewElement = eyeDropperElement.querySelector('.color-picker-eyedropper-preview') as HTMLDivElement;
			const pixelColor = previewElement.style.backgroundColor;
			this.onColorEyeDropperSelect(pixelColor);
			this.$nextTick(() => {
				requestAnimationFrame(() => {
					this.destroyEyeDropper();
				});
			});
		}
	}

	protected onColorClick(color: ColorPickerColor): void {
		this.activeColor = color.colors.primary;
	}

	private onColorEyeDropperSelect(color: string): void {
		if (color.includes('rgb')) {
			color = colorUtils.getHexFromRGB(...colorUtils.getRGBFromRGBString(color));
		}

		this.internalValue = color;
	}

	protected onColorPaletteClick(color: string): void {
		this.internalValue = this.getColorValue(color).toLowerCase();
	}

	protected onEyeDropperClick(): void {
		if (this.eyeDropperElement) {
			this.destroyEyeDropper();
			return;
		}

		this.computedCanvas.addEventListener(
			'draw',
			this.onCanvasDraw,
		);
		this.computedCanvas.style.touchAction = 'none';
		this.eyeDropperElement = document.createElement('div');
		this.eyeDropperElement.classList.add('color-picker-eyedropper-helper');
		const magnifierContainerElement = document.createElement('div');
		magnifierContainerElement.classList.add('color-picker-eyedropper-magnifier-container');
		const magnifierElement = document.createElement('div');
		magnifierElement.classList.add('color-picker-eyedropper-magnifier');
		const pixelElement = document.createElement('div');
		pixelElement.classList.add('color-picker-eyedropper-pixel');
		const previewElement = document.createElement('div');
		previewElement.classList.add('color-picker-eyedropper-preview');
		const previewIconComponent = new IconComponent({
			parent: this,
		});
		previewIconComponent.$slots.default = ['color_picker'] as any[];
		previewIconComponent.$mount();
		previewElement.appendChild(previewIconComponent.$el);
		magnifierContainerElement.appendChild(magnifierElement);
		this.eyeDropperElement.appendChild(magnifierContainerElement);
		magnifierElement.appendChild(pixelElement);
		this.eyeDropperElement.appendChild(previewElement);
		const parentElement = document.getElementById('webapp') || document.body;
		parentElement.appendChild(this.eyeDropperElement);
		const magnifierCanvas = document.createElement('canvas');
		magnifierCanvas.classList.add('color-picker-eyedropper-canvas');
		magnifierCanvas.height = magnifierElement.clientHeight;
		magnifierCanvas.width = magnifierElement.clientWidth;
		magnifierElement.appendChild(magnifierCanvas);

		this.computedCanvas.addEventListener(
			'mousemove',
			this.onCanvasMouseMove,
		);
		this.computedCanvas.addEventListener(
			'touchstart',
			this.onCanvasMouseMove,
		);
		this.computedCanvas.addEventListener(
			'touchmove',
			this.onCanvasMouseMove,
		);
		this.computedCanvas.addEventListener(
			'touchend',
			this.onCanvasMouseUp,
		);
		this.computedCanvas.addEventListener(
			'mouseup',
			this.onCanvasMouseUp,
		);
		this.$nextTick(() => {
			requestAnimationFrame(() => {
				window.addEventListener(
					'click',
					this.onWindowClick,
				);
			});
		});
		const canvasRect = this.computedCanvas.getBoundingClientRect();
		const canvasClientX = (this.computedCanvas.clientWidth / 2) + canvasRect.left;
		const canvasClientY = (this.computedCanvas.clientHeight / 2) + canvasRect.top;

		if (this.isMobile) {
			const touchEvent = new TouchEvent(
				'touchmove',
				{
					touches: [
						new Touch({
							clientX: canvasClientX,
							clientY: canvasClientY,
							identifier: 0,
							target: this.computedCanvas,
						}),
					],
				},
			);
			this.onCanvasMouseMove(touchEvent);
		} else {
			this.onCanvasMouseMove({
				clientX: canvasClientX,
				clientY: canvasClientY,
			} as MouseEvent);
		}

		this.$emit('eye-dropper-start');
	}

	protected onFromColorClick(color: ColorPickerFromColor): void {
		this.internalValue = color.color;
		this.$emit(
			'change',
			this.internalValue,
		);
	}

	private onWindowClick(event: MouseEvent | TouchEvent): void {
		if (event.target !== this.computedCanvas) {
			this.destroyEyeDropper();
		}
	}

	protected onWindowMouseDown(event: MouseEvent | TouchEvent): void {
		if (
			this.$el === event.target
			|| this.$el.contains(event.target as Node)
		) {
			this.isMouseDown = true;
			this.colorSliderElement.addEventListener(
				'mousemove',
				this.onWindowMouseMove,
			);
			window.addEventListener(
				'mouseup',
				this.onWindowMouseUp,
			);
			window.addEventListener(
				'touchend',
				this.onWindowMouseUp,
			);
			this.colorSliderElement.addEventListener(
				'touchmove',
				this.onWindowMouseMove,
			);
			this.onWindowMouseMove(event);
		}
	}

	private onWindowMouseMove(event: MouseEvent | TouchEvent): void {
		if (this.isMouseDown) {
			const color = this.getColorFromMousePosition(event);

			if (color) {
				if (typeof color !== 'string') {
					this.activeColor = color.colors.primary;
					this.activePattern = false;
				} else {
					this.activeColor = null;
					this.activePattern = true;
				}
			}
		}
	}

	private onWindowMouseUp(): void {
		this.isMouseDown = false;
		this.colorSliderElement.removeEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.colorSliderElement.removeEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
	}
}
